/*
*
* Fonts
*/
/*
*
* Font Awesome Icons 4.7.0
* --------------------------------------------------
*/
/* @font-face {
	font-family: 'FontAwesome';
	src: url("../../../assets/fonts/fontawesome-webfont.eot?v=4.7.0");
	src: url("../../../assets/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("../../../assets/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("../../../assets/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("../../../assets/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("../../../assets/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
	font-weight: normal;
	font-style: normal;
} */

.fa {
	display: inline-block;
	font-family: 'FontAwesome';
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class*='fa-']:before {
	font-weight: 400;
	font-family: 'FontAwesome';
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
	font-size: 1.33333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}

.fa-2x {
	font-size: 2em;
}

.fa-3x {
	font-size: 3em;
}

.fa-4x {
	font-size: 4em;
}

.fa-5x {
	font-size: 5em;
}

.fa-fw {
	width: 1.28571429em;
	text-align: center;
}

.fa-ul {
	padding-left: 0;
	margin-left: 2.14285714em;
	list-style-type: none;
}

.fa-ul > li {
	position: relative;
}

.fa-li {
	position: absolute;
	left: -2.14285714em;
	width: 2.14285714em;
	top: 0.14285714em;
	text-align: center;
}

.fa-li.fa-lg {
	left: -1.85714286em;
}

.fa-border {
	padding: .2em .25em .15em;
	border: solid 0.08em #eeeeee;
	border-radius: .1em;
}

.fa-pull-left {
	float: left;
}

.fa-pull-right {
	float: right;
}

.fa.fa-pull-left {
	margin-right: .3em;
}

.fa.fa-pull-right {
	margin-left: .3em;
}

/* Deprecated as of 4.4.0 */
.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.fa.pull-left {
	margin-right: .3em;
}

.fa.pull-right {
	margin-left: .3em;
}

.fa-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear;
}

.fa-pulse {
	-webkit-animation: fa-spin 1s infinite steps(8);
	animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.fa-rotate-90 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.fa-rotate-180 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.fa-rotate-270 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}

.fa-flip-horizontal {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
	-webkit-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
}

.fa-flip-vertical {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
	-webkit-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	transform: scale(1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
	filter: none;
}

.fa-stack {
	position: relative;
	display: inline-block;
	width: 2em;
	height: 2em;
	line-height: 2em;
	vertical-align: middle;
}

.fa-stack-1x,
.fa-stack-2x {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
}

.fa-stack-1x {
	line-height: inherit;
}

.fa-stack-2x {
	font-size: 2em;
}

.fa-inverse {
	color: #ffffff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
	content: "\f000";
}

.fa-music:before {
	content: "\f001";
}

.fa-search:before {
	content: "\f002";
}

.fa-envelope-o:before {
	content: "\f003";
}

.fa-heart:before {
	content: "\f004";
}

.fa-star:before {
	content: "\f005";
}

.fa-star-o:before {
	content: "\f006";
}

.fa-user:before {
	content: "\f007";
}

.fa-film:before {
	content: "\f008";
}

.fa-th-large:before {
	content: "\f009";
}

.fa-th:before {
	content: "\f00a";
}

.fa-th-list:before {
	content: "\f00b";
}

.fa-check:before {
	content: "\f00c";
}

.fa-remove:before,
.fa-close:before,
.fa-times:before {
	content: "\f00d";
}

.fa-search-plus:before {
	content: "\f00e";
}

.fa-search-minus:before {
	content: "\f010";
}

.fa-power-off:before {
	content: "\f011";
}

.fa-signal:before {
	content: "\f012";
}

.fa-gear:before,
.fa-cog:before {
	content: "\f013";
}

.fa-trash-o:before {
	content: "\f014";
}

.fa-home:before {
	content: "\f015";
}

.fa-file-o:before {
	content: "\f016";
}

.fa-clock-o:before {
	content: "\f017";
}

.fa-road:before {
	content: "\f018";
}

.fa-download:before {
	content: "\f019";
}

.fa-arrow-circle-o-down:before {
	content: "\f01a";
}

.fa-arrow-circle-o-up:before {
	content: "\f01b";
}

.fa-inbox:before {
	content: "\f01c";
}

.fa-play-circle-o:before {
	content: "\f01d";
}

.fa-rotate-right:before,
.fa-repeat:before {
	content: "\f01e";
}

.fa-refresh:before {
	content: "\f021";
}

.fa-list-alt:before {
	content: "\f022";
}

.fa-lock:before {
	content: "\f023";
}

.fa-flag:before {
	content: "\f024";
}

.fa-headphones:before {
	content: "\f025";
}

.fa-volume-off:before {
	content: "\f026";
}

.fa-volume-down:before {
	content: "\f027";
}

.fa-volume-up:before {
	content: "\f028";
}

.fa-qrcode:before {
	content: "\f029";
}

.fa-barcode:before {
	content: "\f02a";
}

.fa-tag:before {
	content: "\f02b";
}

.fa-tags:before {
	content: "\f02c";
}

.fa-book:before {
	content: "\f02d";
}

.fa-bookmark:before {
	content: "\f02e";
}

.fa-print:before {
	content: "\f02f";
}

.fa-camera:before {
	content: "\f030";
}

.fa-font:before {
	content: "\f031";
}

.fa-bold:before {
	content: "\f032";
}

.fa-italic:before {
	content: "\f033";
}

.fa-text-height:before {
	content: "\f034";
}

.fa-text-width:before {
	content: "\f035";
}

.fa-align-left:before {
	content: "\f036";
}

.fa-align-center:before {
	content: "\f037";
}

.fa-align-right:before {
	content: "\f038";
}

.fa-align-justify:before {
	content: "\f039";
}

.fa-list:before {
	content: "\f03a";
}

.fa-dedent:before,
.fa-outdent:before {
	content: "\f03b";
}

.fa-indent:before {
	content: "\f03c";
}

.fa-video-camera:before {
	content: "\f03d";
}

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
	content: "\f03e";
}

.fa-pencil:before {
	content: "\f040";
}

.fa-map-marker:before {
	content: "\f041";
}

.fa-adjust:before {
	content: "\f042";
}

.fa-tint:before {
	content: "\f043";
}

.fa-edit:before,
.fa-pencil-square-o:before {
	content: "\f044";
}

.fa-share-square-o:before {
	content: "\f045";
}

.fa-check-square-o:before {
	content: "\f046";
}

.fa-arrows:before {
	content: "\f047";
}

.fa-step-backward:before {
	content: "\f048";
}

.fa-fast-backward:before {
	content: "\f049";
}

.fa-backward:before {
	content: "\f04a";
}

.fa-play:before {
	content: "\f04b";
}

.fa-pause:before {
	content: "\f04c";
}

.fa-stop:before {
	content: "\f04d";
}

.fa-forward:before {
	content: "\f04e";
}

.fa-fast-forward:before {
	content: "\f050";
}

.fa-step-forward:before {
	content: "\f051";
}

.fa-eject:before {
	content: "\f052";
}

.fa-chevron-left:before {
	content: "\f053";
}

.fa-chevron-right:before {
	content: "\f054";
}

.fa-plus-circle:before {
	content: "\f055";
}

.fa-minus-circle:before {
	content: "\f056";
}

.fa-times-circle:before {
	content: "\f057";
}

.fa-check-circle:before {
	content: "\f058";
}

.fa-question-circle:before {
	content: "\f059";
}

.fa-info-circle:before {
	content: "\f05a";
}

.fa-crosshairs:before {
	content: "\f05b";
}

.fa-times-circle-o:before {
	content: "\f05c";
}

.fa-check-circle-o:before {
	content: "\f05d";
}

.fa-ban:before {
	content: "\f05e";
}

.fa-arrow-left:before {
	content: "\f060";
}

.fa-arrow-right:before {
	content: "\f061";
}

.fa-arrow-up:before {
	content: "\f062";
}

.fa-arrow-down:before {
	content: "\f063";
}

.fa-mail-forward:before,
.fa-share:before {
	content: "\f064";
}

.fa-expand:before {
	content: "\f065";
}

.fa-compress:before {
	content: "\f066";
}

.fa-plus:before {
	content: "\f067";
}

.fa-minus:before {
	content: "\f068";
}

.fa-asterisk:before {
	content: "\f069";
}

.fa-exclamation-circle:before {
	content: "\f06a";
}

.fa-gift:before {
	content: "\f06b";
}

.fa-leaf:before {
	content: "\f06c";
}

.fa-fire:before {
	content: "\f06d";
}

.fa-eye:before {
	content: "\f06e";
}

.fa-eye-slash:before {
	content: "\f070";
}

.fa-warning:before,
.fa-exclamation-triangle:before {
	content: "\f071";
}

.fa-plane:before {
	content: "\f072";
}

.fa-calendar:before {
	content: "\f073";
}

.fa-random:before {
	content: "\f074";
}

.fa-comment:before {
	content: "\f075";
}

.fa-magnet:before {
	content: "\f076";
}

.fa-chevron-up:before {
	content: "\f077";
}

.fa-chevron-down:before {
	content: "\f078";
}

.fa-retweet:before {
	content: "\f079";
}

.fa-shopping-cart:before {
	content: "\f07a";
}

.fa-folder:before {
	content: "\f07b";
}

.fa-folder-open:before {
	content: "\f07c";
}

.fa-arrows-v:before {
	content: "\f07d";
}

.fa-arrows-h:before {
	content: "\f07e";
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
	content: "\f080";
}

.fa-twitter-square:before {
	content: "\f081";
}

.fa-facebook-square:before {
	content: "\f082";
}

.fa-camera-retro:before {
	content: "\f083";
}

.fa-key:before {
	content: "\f084";
}

.fa-gears:before,
.fa-cogs:before {
	content: "\f085";
}

.fa-comments:before {
	content: "\f086";
}

.fa-thumbs-o-up:before {
	content: "\f087";
}

.fa-thumbs-o-down:before {
	content: "\f088";
}

.fa-star-half:before {
	content: "\f089";
}

.fa-heart-o:before {
	content: "\f08a";
}

.fa-sign-out:before {
	content: "\f08b";
}

.fa-linkedin-square:before {
	content: "\f08c";
}

.fa-thumb-tack:before {
	content: "\f08d";
}

.fa-external-link:before {
	content: "\f08e";
}

.fa-sign-in:before {
	content: "\f090";
}

.fa-trophy:before {
	content: "\f091";
}

.fa-github-square:before {
	content: "\f092";
}

.fa-upload:before {
	content: "\f093";
}

.fa-lemon-o:before {
	content: "\f094";
}

.fa-phone:before {
	content: "\f095";
}

.fa-square-o:before {
	content: "\f096";
}

.fa-bookmark-o:before {
	content: "\f097";
}

.fa-phone-square:before {
	content: "\f098";
}

.fa-twitter:before {
	content: "\f099";
}

.fa-facebook-f:before,
.fa-facebook:before {
	content: "\f09a";
}

.fa-github:before {
	content: "\f09b";
}

.fa-unlock:before {
	content: "\f09c";
}

.fa-credit-card:before {
	content: "\f09d";
}

.fa-feed:before,
.fa-rss:before {
	content: "\f09e";
}

.fa-hdd-o:before {
	content: "\f0a0";
}

.fa-bullhorn:before {
	content: "\f0a1";
}

.fa-bell:before {
	content: "\f0f3";
}

.fa-certificate:before {
	content: "\f0a3";
}

.fa-hand-o-right:before {
	content: "\f0a4";
}

.fa-hand-o-left:before {
	content: "\f0a5";
}

.fa-hand-o-up:before {
	content: "\f0a6";
}

.fa-hand-o-down:before {
	content: "\f0a7";
}

.fa-arrow-circle-left:before {
	content: "\f0a8";
}

.fa-arrow-circle-right:before {
	content: "\f0a9";
}

.fa-arrow-circle-up:before {
	content: "\f0aa";
}

.fa-arrow-circle-down:before {
	content: "\f0ab";
}

.fa-globe:before {
	content: "\f0ac";
}

.fa-wrench:before {
	content: "\f0ad";
}

.fa-tasks:before {
	content: "\f0ae";
}

.fa-filter:before {
	content: "\f0b0";
}

.fa-briefcase:before {
	content: "\f0b1";
}

.fa-arrows-alt:before {
	content: "\f0b2";
}

.fa-group:before,
.fa-users:before {
	content: "\f0c0";
}

.fa-chain:before,
.fa-link:before {
	content: "\f0c1";
}

.fa-cloud:before {
	content: "\f0c2";
}

.fa-flask:before {
	content: "\f0c3";
}

.fa-cut:before,
.fa-scissors:before {
	content: "\f0c4";
}

.fa-copy:before,
.fa-files-o:before {
	content: "\f0c5";
}

.fa-paperclip:before {
	content: "\f0c6";
}

.fa-save:before,
.fa-floppy-o:before {
	content: "\f0c7";
}

.fa-square:before {
	content: "\f0c8";
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
	content: "\f0c9";
}

.fa-list-ul:before {
	content: "\f0ca";
}

.fa-list-ol:before {
	content: "\f0cb";
}

.fa-strikethrough:before {
	content: "\f0cc";
}

.fa-underline:before {
	content: "\f0cd";
}

.fa-table:before {
	content: "\f0ce";
}

.fa-magic:before {
	content: "\f0d0";
}

.fa-truck:before {
	content: "\f0d1";
}

.fa-pinterest:before {
	content: "\f0d2";
}

.fa-pinterest-square:before {
	content: "\f0d3";
}

.fa-google-plus-square:before {
	content: "\f0d4";
}

.fa-google-plus:before {
	content: "\f0d5";
}

.fa-money:before {
	content: "\f0d6";
}

.fa-caret-down:before {
	content: "\f0d7";
}

.fa-caret-up:before {
	content: "\f0d8";
}

.fa-caret-left:before {
	content: "\f0d9";
}

.fa-caret-right:before {
	content: "\f0da";
}

.fa-columns:before {
	content: "\f0db";
}

.fa-unsorted:before,
.fa-sort:before {
	content: "\f0dc";
}

.fa-sort-down:before,
.fa-sort-desc:before {
	content: "\f0dd";
}

.fa-sort-up:before,
.fa-sort-asc:before {
	content: "\f0de";
}

.fa-envelope:before {
	content: "\f0e0";
}

.fa-linkedin:before {
	content: "\f0e1";
}

.fa-rotate-left:before,
.fa-undo:before {
	content: "\f0e2";
}

.fa-legal:before,
.fa-gavel:before {
	content: "\f0e3";
}

.fa-dashboard:before,
.fa-tachometer:before {
	content: "\f0e4";
}

.fa-comment-o:before {
	content: "\f0e5";
}

.fa-comments-o:before {
	content: "\f0e6";
}

.fa-flash:before,
.fa-bolt:before {
	content: "\f0e7";
}

.fa-sitemap:before {
	content: "\f0e8";
}

.fa-umbrella:before {
	content: "\f0e9";
}

.fa-paste:before,
.fa-clipboard:before {
	content: "\f0ea";
}

.fa-lightbulb-o:before {
	content: "\f0eb";
}

.fa-exchange:before {
	content: "\f0ec";
}

.fa-cloud-download:before {
	content: "\f0ed";
}

.fa-cloud-upload:before {
	content: "\f0ee";
}

.fa-user-md:before {
	content: "\f0f0";
}

.fa-stethoscope:before {
	content: "\f0f1";
}

.fa-suitcase:before {
	content: "\f0f2";
}

.fa-bell-o:before {
	content: "\f0a2";
}

.fa-coffee:before {
	content: "\f0f4";
}

.fa-cutlery:before {
	content: "\f0f5";
}

.fa-file-text-o:before {
	content: "\f0f6";
}

.fa-building-o:before {
	content: "\f0f7";
}

.fa-hospital-o:before {
	content: "\f0f8";
}

.fa-ambulance:before {
	content: "\f0f9";
}

.fa-medkit:before {
	content: "\f0fa";
}

.fa-fighter-jet:before {
	content: "\f0fb";
}

.fa-beer:before {
	content: "\f0fc";
}

.fa-h-square:before {
	content: "\f0fd";
}

.fa-plus-square:before {
	content: "\f0fe";
}

.fa-angle-double-left:before {
	content: "\f100";
}

.fa-angle-double-right:before {
	content: "\f101";
}

.fa-angle-double-up:before {
	content: "\f102";
}

.fa-angle-double-down:before {
	content: "\f103";
}

.fa-angle-left:before {
	content: "\f104";
}

.fa-angle-right:before {
	content: "\f105";
}

.fa-angle-up:before {
	content: "\f106";
}

.fa-angle-down:before {
	content: "\f107";
}

.fa-desktop:before {
	content: "\f108";
}

.fa-laptop:before {
	content: "\f109";
}

.fa-tablet:before {
	content: "\f10a";
}

.fa-mobile-phone:before,
.fa-mobile:before {
	content: "\f10b";
}

.fa-circle-o:before {
	content: "\f10c";
}

.fa-quote-left:before {
	content: "\f10d";
}

.fa-quote-right:before {
	content: "\f10e";
}

.fa-spinner:before {
	content: "\f110";
}

.fa-circle:before {
	content: "\f111";
}

.fa-mail-reply:before,
.fa-reply:before {
	content: "\f112";
}

.fa-github-alt:before {
	content: "\f113";
}

.fa-folder-o:before {
	content: "\f114";
}

.fa-folder-open-o:before {
	content: "\f115";
}

.fa-smile-o:before {
	content: "\f118";
}

.fa-frown-o:before {
	content: "\f119";
}

.fa-meh-o:before {
	content: "\f11a";
}

.fa-gamepad:before {
	content: "\f11b";
}

.fa-keyboard-o:before {
	content: "\f11c";
}

.fa-flag-o:before {
	content: "\f11d";
}

.fa-flag-checkered:before {
	content: "\f11e";
}

.fa-terminal:before {
	content: "\f120";
}

.fa-code:before {
	content: "\f121";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
	content: "\f122";
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
	content: "\f123";
}

.fa-location-arrow:before {
	content: "\f124";
}

.fa-crop:before {
	content: "\f125";
}

.fa-code-fork:before {
	content: "\f126";
}

.fa-unlink:before,
.fa-chain-broken:before {
	content: "\f127";
}

.fa-question:before {
	content: "\f128";
}

.fa-info:before {
	content: "\f129";
}

.fa-exclamation:before {
	content: "\f12a";
}

.fa-superscript:before {
	content: "\f12b";
}

.fa-subscript:before {
	content: "\f12c";
}

.fa-eraser:before {
	content: "\f12d";
}

.fa-puzzle-piece:before {
	content: "\f12e";
}

.fa-microphone:before {
	content: "\f130";
}

.fa-microphone-slash:before {
	content: "\f131";
}

.fa-shield:before {
	content: "\f132";
}

.fa-calendar-o:before {
	content: "\f133";
}

.fa-fire-extinguisher:before {
	content: "\f134";
}

.fa-rocket:before {
	content: "\f135";
}

.fa-maxcdn:before {
	content: "\f136";
}

.fa-chevron-circle-left:before {
	content: "\f137";
}

.fa-chevron-circle-right:before {
	content: "\f138";
}

.fa-chevron-circle-up:before {
	content: "\f139";
}

.fa-chevron-circle-down:before {
	content: "\f13a";
}

.fa-html5:before {
	content: "\f13b";
}

.fa-css3:before {
	content: "\f13c";
}

.fa-anchor:before {
	content: "\f13d";
}

.fa-unlock-alt:before {
	content: "\f13e";
}

.fa-bullseye:before {
	content: "\f140";
}

.fa-ellipsis-h:before {
	content: "\f141";
}

.fa-ellipsis-v:before {
	content: "\f142";
}

.fa-rss-square:before {
	content: "\f143";
}

.fa-play-circle:before {
	content: "\f144";
}

.fa-ticket:before {
	content: "\f145";
}

.fa-minus-square:before {
	content: "\f146";
}

.fa-minus-square-o:before {
	content: "\f147";
}

.fa-level-up:before {
	content: "\f148";
}

.fa-level-down:before {
	content: "\f149";
}

.fa-check-square:before {
	content: "\f14a";
}

.fa-pencil-square:before {
	content: "\f14b";
}

.fa-external-link-square:before {
	content: "\f14c";
}

.fa-share-square:before {
	content: "\f14d";
}

.fa-compass:before {
	content: "\f14e";
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
	content: "\f150";
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
	content: "\f151";
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
	content: "\f152";
}

.fa-euro:before,
.fa-eur:before {
	content: "\f153";
}

.fa-gbp:before {
	content: "\f154";
}

.fa-dollar:before,
.fa-usd:before {
	content: "\f155";
}

.fa-rupee:before,
.fa-inr:before {
	content: "\f156";
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
	content: "\f157";
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
	content: "\f158";
}

.fa-won:before,
.fa-krw:before {
	content: "\f159";
}

.fa-bitcoin:before,
.fa-btc:before {
	content: "\f15a";
}

.fa-file:before {
	content: "\f15b";
}

.fa-file-text:before {
	content: "\f15c";
}

.fa-sort-alpha-asc:before {
	content: "\f15d";
}

.fa-sort-alpha-desc:before {
	content: "\f15e";
}

.fa-sort-amount-asc:before {
	content: "\f160";
}

.fa-sort-amount-desc:before {
	content: "\f161";
}

.fa-sort-numeric-asc:before {
	content: "\f162";
}

.fa-sort-numeric-desc:before {
	content: "\f163";
}

.fa-thumbs-up:before {
	content: "\f164";
}

.fa-thumbs-down:before {
	content: "\f165";
}

.fa-youtube-square:before {
	content: "\f166";
}

.fa-youtube:before {
	content: "\f167";
}

.fa-xing:before {
	content: "\f168";
}

.fa-xing-square:before {
	content: "\f169";
}

.fa-youtube-play:before {
	content: "\f16a";
}

.fa-dropbox:before {
	content: "\f16b";
}

.fa-stack-overflow:before {
	content: "\f16c";
}

.fa-instagram:before {
	content: "\f16d";
}

.fa-flickr:before {
	content: "\f16e";
}

.fa-adn:before {
	content: "\f170";
}

.fa-bitbucket:before {
	content: "\f171";
}

.fa-bitbucket-square:before {
	content: "\f172";
}

.fa-tumblr:before {
	content: "\f173";
}

.fa-tumblr-square:before {
	content: "\f174";
}

.fa-long-arrow-down:before {
	content: "\f175";
}

.fa-long-arrow-up:before {
	content: "\f176";
}

.fa-long-arrow-left:before {
	content: "\f177";
}

.fa-long-arrow-right:before {
	content: "\f178";
}

.fa-apple:before {
	content: "\f179";
}

.fa-windows:before {
	content: "\f17a";
}

.fa-android:before {
	content: "\f17b";
}

.fa-linux:before {
	content: "\f17c";
}

.fa-dribbble:before {
	content: "\f17d";
}

.fa-skype:before {
	content: "\f17e";
}

.fa-foursquare:before {
	content: "\f180";
}

.fa-trello:before {
	content: "\f181";
}

.fa-female:before {
	content: "\f182";
}

.fa-male:before {
	content: "\f183";
}

.fa-gittip:before,
.fa-gratipay:before {
	content: "\f184";
}

.fa-sun-o:before {
	content: "\f185";
}

.fa-moon-o:before {
	content: "\f186";
}

.fa-archive:before {
	content: "\f187";
}

.fa-bug:before {
	content: "\f188";
}

.fa-vk:before {
	content: "\f189";
}

.fa-weibo:before {
	content: "\f18a";
}

.fa-renren:before {
	content: "\f18b";
}

.fa-pagelines:before {
	content: "\f18c";
}

.fa-stack-exchange:before {
	content: "\f18d";
}

.fa-arrow-circle-o-right:before {
	content: "\f18e";
}

.fa-arrow-circle-o-left:before {
	content: "\f190";
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
	content: "\f191";
}

.fa-dot-circle-o:before {
	content: "\f192";
}

.fa-wheelchair:before {
	content: "\f193";
}

.fa-vimeo-square:before {
	content: "\f194";
}

.fa-turkish-lira:before,
.fa-try:before {
	content: "\f195";
}

.fa-plus-square-o:before {
	content: "\f196";
}

.fa-space-shuttle:before {
	content: "\f197";
}

.fa-slack:before {
	content: "\f198";
}

.fa-envelope-square:before {
	content: "\f199";
}

.fa-wordpress:before {
	content: "\f19a";
}

.fa-openid:before {
	content: "\f19b";
}

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
	content: "\f19c";
}

.fa-mortar-board:before,
.fa-graduation-cap:before {
	content: "\f19d";
}

.fa-yahoo:before {
	content: "\f19e";
}

.fa-google:before {
	content: "\f1a0";
}

.fa-reddit:before {
	content: "\f1a1";
}

.fa-reddit-square:before {
	content: "\f1a2";
}

.fa-stumbleupon-circle:before {
	content: "\f1a3";
}

.fa-stumbleupon:before {
	content: "\f1a4";
}

.fa-delicious:before {
	content: "\f1a5";
}

.fa-digg:before {
	content: "\f1a6";
}

.fa-pied-piper-pp:before {
	content: "\f1a7";
}

.fa-pied-piper-alt:before {
	content: "\f1a8";
}

.fa-drupal:before {
	content: "\f1a9";
}

.fa-joomla:before {
	content: "\f1aa";
}

.fa-language:before {
	content: "\f1ab";
}

.fa-fax:before {
	content: "\f1ac";
}

.fa-building:before {
	content: "\f1ad";
}

.fa-child:before {
	content: "\f1ae";
}

.fa-paw:before {
	content: "\f1b0";
}

.fa-spoon:before {
	content: "\f1b1";
}

.fa-cube:before {
	content: "\f1b2";
}

.fa-cubes:before {
	content: "\f1b3";
}

.fa-behance:before {
	content: "\f1b4";
}

.fa-behance-square:before {
	content: "\f1b5";
}

.fa-steam:before {
	content: "\f1b6";
}

.fa-steam-square:before {
	content: "\f1b7";
}

.fa-recycle:before {
	content: "\f1b8";
}

.fa-automobile:before,
.fa-car:before {
	content: "\f1b9";
}

.fa-cab:before,
.fa-taxi:before {
	content: "\f1ba";
}

.fa-tree:before {
	content: "\f1bb";
}

.fa-spotify:before {
	content: "\f1bc";
}

.fa-deviantart:before {
	content: "\f1bd";
}

.fa-soundcloud:before {
	content: "\f1be";
}

.fa-database:before {
	content: "\f1c0";
}

.fa-file-pdf-o:before {
	content: "\f1c1";
}

.fa-file-word-o:before {
	content: "\f1c2";
}

.fa-file-excel-o:before {
	content: "\f1c3";
}

.fa-file-powerpoint-o:before {
	content: "\f1c4";
}

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
	content: "\f1c5";
}

.fa-file-zip-o:before,
.fa-file-archive-o:before {
	content: "\f1c6";
}

.fa-file-sound-o:before,
.fa-file-audio-o:before {
	content: "\f1c7";
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
	content: "\f1c8";
}

.fa-file-code-o:before {
	content: "\f1c9";
}

.fa-vine:before {
	content: "\f1ca";
}

.fa-codepen:before {
	content: "\f1cb";
}

.fa-jsfiddle:before {
	content: "\f1cc";
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
	content: "\f1cd";
}

.fa-circle-o-notch:before {
	content: "\f1ce";
}

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
	content: "\f1d0";
}

.fa-ge:before,
.fa-empire:before {
	content: "\f1d1";
}

.fa-git-square:before {
	content: "\f1d2";
}

.fa-git:before {
	content: "\f1d3";
}

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
	content: "\f1d4";
}

.fa-tencent-weibo:before {
	content: "\f1d5";
}

.fa-qq:before {
	content: "\f1d6";
}

.fa-wechat:before,
.fa-weixin:before {
	content: "\f1d7";
}

.fa-send:before,
.fa-paper-plane:before {
	content: "\f1d8";
}

.fa-send-o:before,
.fa-paper-plane-o:before {
	content: "\f1d9";
}

.fa-history:before {
	content: "\f1da";
}

.fa-circle-thin:before {
	content: "\f1db";
}

.fa-header:before {
	content: "\f1dc";
}

.fa-paragraph:before {
	content: "\f1dd";
}

.fa-sliders:before {
	content: "\f1de";
}

.fa-share-alt:before {
	content: "\f1e0";
}

.fa-share-alt-square:before {
	content: "\f1e1";
}

.fa-bomb:before {
	content: "\f1e2";
}

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
	content: "\f1e3";
}

.fa-tty:before {
	content: "\f1e4";
}

.fa-binoculars:before {
	content: "\f1e5";
}

.fa-plug:before {
	content: "\f1e6";
}

.fa-slideshare:before {
	content: "\f1e7";
}

.fa-twitch:before {
	content: "\f1e8";
}

.fa-yelp:before {
	content: "\f1e9";
}

.fa-newspaper-o:before {
	content: "\f1ea";
}

.fa-wifi:before {
	content: "\f1eb";
}

.fa-calculator:before {
	content: "\f1ec";
}

.fa-paypal:before {
	content: "\f1ed";
}

.fa-google-wallet:before {
	content: "\f1ee";
}

.fa-cc-visa:before {
	content: "\f1f0";
}

.fa-cc-mastercard:before {
	content: "\f1f1";
}

.fa-cc-discover:before {
	content: "\f1f2";
}

.fa-cc-amex:before {
	content: "\f1f3";
}

.fa-cc-paypal:before {
	content: "\f1f4";
}

.fa-cc-stripe:before {
	content: "\f1f5";
}

.fa-bell-slash:before {
	content: "\f1f6";
}

.fa-bell-slash-o:before {
	content: "\f1f7";
}

.fa-trash:before {
	content: "\f1f8";
}

.fa-copyright:before {
	content: "\f1f9";
}

.fa-at:before {
	content: "\f1fa";
}

.fa-eyedropper:before {
	content: "\f1fb";
}

.fa-paint-brush:before {
	content: "\f1fc";
}

.fa-birthday-cake:before {
	content: "\f1fd";
}

.fa-area-chart:before {
	content: "\f1fe";
}

.fa-pie-chart:before {
	content: "\f200";
}

.fa-line-chart:before {
	content: "\f201";
}

.fa-lastfm:before {
	content: "\f202";
}

.fa-lastfm-square:before {
	content: "\f203";
}

.fa-toggle-off:before {
	content: "\f204";
}

.fa-toggle-on:before {
	content: "\f205";
}

.fa-bicycle:before {
	content: "\f206";
}

.fa-bus:before {
	content: "\f207";
}

.fa-ioxhost:before {
	content: "\f208";
}

.fa-angellist:before {
	content: "\f209";
}

.fa-cc:before {
	content: "\f20a";
}

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
	content: "\f20b";
}

.fa-meanpath:before {
	content: "\f20c";
}

.fa-buysellads:before {
	content: "\f20d";
}

.fa-connectdevelop:before {
	content: "\f20e";
}

.fa-dashcube:before {
	content: "\f210";
}

.fa-forumbee:before {
	content: "\f211";
}

.fa-leanpub:before {
	content: "\f212";
}

.fa-sellsy:before {
	content: "\f213";
}

.fa-shirtsinbulk:before {
	content: "\f214";
}

.fa-simplybuilt:before {
	content: "\f215";
}

.fa-skyatlas:before {
	content: "\f216";
}

.fa-cart-plus:before {
	content: "\f217";
}

.fa-cart-arrow-down:before {
	content: "\f218";
}

.fa-diamond:before {
	content: "\f219";
}

.fa-ship:before {
	content: "\f21a";
}

.fa-user-secret:before {
	content: "\f21b";
}

.fa-motorcycle:before {
	content: "\f21c";
}

.fa-street-view:before {
	content: "\f21d";
}

.fa-heartbeat:before {
	content: "\f21e";
}

.fa-venus:before {
	content: "\f221";
}

.fa-mars:before {
	content: "\f222";
}

.fa-mercury:before {
	content: "\f223";
}

.fa-intersex:before,
.fa-transgender:before {
	content: "\f224";
}

.fa-transgender-alt:before {
	content: "\f225";
}

.fa-venus-double:before {
	content: "\f226";
}

.fa-mars-double:before {
	content: "\f227";
}

.fa-venus-mars:before {
	content: "\f228";
}

.fa-mars-stroke:before {
	content: "\f229";
}

.fa-mars-stroke-v:before {
	content: "\f22a";
}

.fa-mars-stroke-h:before {
	content: "\f22b";
}

.fa-neuter:before {
	content: "\f22c";
}

.fa-genderless:before {
	content: "\f22d";
}

.fa-facebook-official:before {
	content: "\f230";
}

.fa-pinterest-p:before {
	content: "\f231";
}

.fa-whatsapp:before {
	content: "\f232";
}

.fa-server:before {
	content: "\f233";
}

.fa-user-plus:before {
	content: "\f234";
}

.fa-user-times:before {
	content: "\f235";
}

.fa-hotel:before,
.fa-bed:before {
	content: "\f236";
}

.fa-viacoin:before {
	content: "\f237";
}

.fa-train:before {
	content: "\f238";
}

.fa-subway:before {
	content: "\f239";
}

.fa-medium:before {
	content: "\f23a";
}

.fa-yc:before,
.fa-y-combinator:before {
	content: "\f23b";
}

.fa-optin-monster:before {
	content: "\f23c";
}

.fa-opencart:before {
	content: "\f23d";
}

.fa-expeditedssl:before {
	content: "\f23e";
}

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
	content: "\f240";
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
	content: "\f241";
}

.fa-battery-2:before,
.fa-battery-half:before {
	content: "\f242";
}

.fa-battery-1:before,
.fa-battery-quarter:before {
	content: "\f243";
}

.fa-battery-0:before,
.fa-battery-empty:before {
	content: "\f244";
}

.fa-mouse-pointer:before {
	content: "\f245";
}

.fa-i-cursor:before {
	content: "\f246";
}

.fa-object-group:before {
	content: "\f247";
}

.fa-object-ungroup:before {
	content: "\f248";
}

.fa-sticky-note:before {
	content: "\f249";
}

.fa-sticky-note-o:before {
	content: "\f24a";
}

.fa-cc-jcb:before {
	content: "\f24b";
}

.fa-cc-diners-club:before {
	content: "\f24c";
}

.fa-clone:before {
	content: "\f24d";
}

.fa-balance-scale:before {
	content: "\f24e";
}

.fa-hourglass-o:before {
	content: "\f250";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
	content: "\f251";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
	content: "\f252";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
	content: "\f253";
}

.fa-hourglass:before {
	content: "\f254";
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
	content: "\f255";
}

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
	content: "\f256";
}

.fa-hand-scissors-o:before {
	content: "\f257";
}

.fa-hand-lizard-o:before {
	content: "\f258";
}

.fa-hand-spock-o:before {
	content: "\f259";
}

.fa-hand-pointer-o:before {
	content: "\f25a";
}

.fa-hand-peace-o:before {
	content: "\f25b";
}

.fa-trademark:before {
	content: "\f25c";
}

.fa-registered:before {
	content: "\f25d";
}

.fa-creative-commons:before {
	content: "\f25e";
}

.fa-gg:before {
	content: "\f260";
}

.fa-gg-circle:before {
	content: "\f261";
}

.fa-tripadvisor:before {
	content: "\f262";
}

.fa-odnoklassniki:before {
	content: "\f263";
}

.fa-odnoklassniki-square:before {
	content: "\f264";
}

.fa-get-pocket:before {
	content: "\f265";
}

.fa-wikipedia-w:before {
	content: "\f266";
}

.fa-safari:before {
	content: "\f267";
}

.fa-chrome:before {
	content: "\f268";
}

.fa-firefox:before {
	content: "\f269";
}

.fa-opera:before {
	content: "\f26a";
}

.fa-internet-explorer:before {
	content: "\f26b";
}

.fa-tv:before,
.fa-television:before {
	content: "\f26c";
}

.fa-contao:before {
	content: "\f26d";
}

.fa-500px:before {
	content: "\f26e";
}

.fa-amazon:before {
	content: "\f270";
}

.fa-calendar-plus-o:before {
	content: "\f271";
}

.fa-calendar-minus-o:before {
	content: "\f272";
}

.fa-calendar-times-o:before {
	content: "\f273";
}

.fa-calendar-check-o:before {
	content: "\f274";
}

.fa-industry:before {
	content: "\f275";
}

.fa-map-pin:before {
	content: "\f276";
}

.fa-map-signs:before {
	content: "\f277";
}

.fa-map-o:before {
	content: "\f278";
}

.fa-map:before {
	content: "\f279";
}

.fa-commenting:before {
	content: "\f27a";
}

.fa-commenting-o:before {
	content: "\f27b";
}

.fa-houzz:before {
	content: "\f27c";
}

.fa-vimeo:before {
	content: "\f27d";
}

.fa-black-tie:before {
	content: "\f27e";
}

.fa-fonticons:before {
	content: "\f280";
}

.fa-reddit-alien:before {
	content: "\f281";
}

.fa-edge:before {
	content: "\f282";
}

.fa-credit-card-alt:before {
	content: "\f283";
}

.fa-codiepie:before {
	content: "\f284";
}

.fa-modx:before {
	content: "\f285";
}

.fa-fort-awesome:before {
	content: "\f286";
}

.fa-usb:before {
	content: "\f287";
}

.fa-product-hunt:before {
	content: "\f288";
}

.fa-mixcloud:before {
	content: "\f289";
}

.fa-scribd:before {
	content: "\f28a";
}

.fa-pause-circle:before {
	content: "\f28b";
}

.fa-pause-circle-o:before {
	content: "\f28c";
}

.fa-stop-circle:before {
	content: "\f28d";
}

.fa-stop-circle-o:before {
	content: "\f28e";
}

.fa-shopping-bag:before {
	content: "\f290";
}

.fa-shopping-basket:before {
	content: "\f291";
}

.fa-hashtag:before {
	content: "\f292";
}

.fa-bluetooth:before {
	content: "\f293";
}

.fa-bluetooth-b:before {
	content: "\f294";
}

.fa-percent:before {
	content: "\f295";
}

.fa-gitlab:before {
	content: "\f296";
}

.fa-wpbeginner:before {
	content: "\f297";
}

.fa-wpforms:before {
	content: "\f298";
}

.fa-envira:before {
	content: "\f299";
}

.fa-universal-access:before {
	content: "\f29a";
}

.fa-wheelchair-alt:before {
	content: "\f29b";
}

.fa-question-circle-o:before {
	content: "\f29c";
}

.fa-blind:before {
	content: "\f29d";
}

.fa-audio-description:before {
	content: "\f29e";
}

.fa-volume-control-phone:before {
	content: "\f2a0";
}

.fa-braille:before {
	content: "\f2a1";
}

.fa-assistive-listening-systems:before {
	content: "\f2a2";
}

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
	content: "\f2a3";
}

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
	content: "\f2a4";
}

.fa-glide:before {
	content: "\f2a5";
}

.fa-glide-g:before {
	content: "\f2a6";
}

.fa-signing:before,
.fa-sign-language:before {
	content: "\f2a7";
}

.fa-low-vision:before {
	content: "\f2a8";
}

.fa-viadeo:before {
	content: "\f2a9";
}

.fa-viadeo-square:before {
	content: "\f2aa";
}

.fa-snapchat:before {
	content: "\f2ab";
}

.fa-snapchat-ghost:before {
	content: "\f2ac";
}

.fa-snapchat-square:before {
	content: "\f2ad";
}

.fa-pied-piper:before {
	content: "\f2ae";
}

.fa-first-order:before {
	content: "\f2b0";
}

.fa-yoast:before {
	content: "\f2b1";
}

.fa-themeisle:before {
	content: "\f2b2";
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
	content: "\f2b3";
}

.fa-fa:before,
.fa-font-awesome:before {
	content: "\f2b4";
}

.fa-handshake-o:before {
	content: "\f2b5";
}

.fa-envelope-open:before {
	content: "\f2b6";
}

.fa-envelope-open-o:before {
	content: "\f2b7";
}

.fa-linode:before {
	content: "\f2b8";
}

.fa-address-book:before {
	content: "\f2b9";
}

.fa-address-book-o:before {
	content: "\f2ba";
}

.fa-vcard:before,
.fa-address-card:before {
	content: "\f2bb";
}

.fa-vcard-o:before,
.fa-address-card-o:before {
	content: "\f2bc";
}

.fa-user-circle:before {
	content: "\f2bd";
}

.fa-user-circle-o:before {
	content: "\f2be";
}

.fa-user-o:before {
	content: "\f2c0";
}

.fa-id-badge:before {
	content: "\f2c1";
}

.fa-drivers-license:before,
.fa-id-card:before {
	content: "\f2c2";
}

.fa-drivers-license-o:before,
.fa-id-card-o:before {
	content: "\f2c3";
}

.fa-quora:before {
	content: "\f2c4";
}

.fa-free-code-camp:before {
	content: "\f2c5";
}

.fa-telegram:before {
	content: "\f2c6";
}

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
	content: "\f2c7";
}

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
	content: "\f2c8";
}

.fa-thermometer-2:before,
.fa-thermometer-half:before {
	content: "\f2c9";
}

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
	content: "\f2ca";
}

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
	content: "\f2cb";
}

.fa-shower:before {
	content: "\f2cc";
}

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
	content: "\f2cd";
}

.fa-podcast:before {
	content: "\f2ce";
}

.fa-window-maximize:before {
	content: "\f2d0";
}

.fa-window-minimize:before {
	content: "\f2d1";
}

.fa-window-restore:before {
	content: "\f2d2";
}

.fa-times-rectangle:before,
.fa-window-close:before {
	content: "\f2d3";
}

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
	content: "\f2d4";
}

.fa-bandcamp:before {
	content: "\f2d5";
}

.fa-grav:before {
	content: "\f2d6";
}

.fa-etsy:before {
	content: "\f2d7";
}

.fa-imdb:before {
	content: "\f2d8";
}

.fa-ravelry:before {
	content: "\f2d9";
}

.fa-eercast:before {
	content: "\f2da";
}

.fa-microchip:before {
	content: "\f2db";
}

.fa-snowflake-o:before {
	content: "\f2dc";
}

.fa-superpowers:before {
	content: "\f2dd";
}

.fa-wpexplorer:before {
	content: "\f2de";
}

.fa-meetup:before {
	content: "\f2e0";
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}

/*
*
* Material Design Icons
* --------------------------------------------------
*/
/*@font-face {
	font-family: "Material Design Icons";
	src: url("../fonts/materialdesignicons-webfont.eot?v=1.4.57");
	src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=1.4.57") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=1.4.57") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=1.4.57") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=1.4.57") format("truetype"), url("../fonts/materialdesignicons-webfont.svg?v=1.4.57#materialdesigniconsregular") format("svg");
	font-weight: normal;
	font-style: normal;
}*/

.mdi {
	display: inline-block;
	font: normal normal normal 24px/1 "Material Design Icons";
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0);
}

.mdi-access-point:before {
	content: "\f101";
}

.mdi-access-point-network:before {
	content: "\f102";
}

.mdi-account:before {
	content: "\f103";
}

.mdi-account-alert:before {
	content: "\f104";
}

.mdi-account-box:before {
	content: "\f105";
}

.mdi-account-box-outline:before {
	content: "\f106";
}

.mdi-account-check:before {
	content: "\f107";
}

.mdi-account-circle:before {
	content: "\f108";
}

.mdi-account-convert:before {
	content: "\f109";
}

.mdi-account-key:before {
	content: "\f10a";
}

.mdi-account-location:before {
	content: "\f10b";
}

.mdi-account-minus:before {
	content: "\f10c";
}

.mdi-account-multiple:before {
	content: "\f10d";
}

.mdi-account-multiple-outline:before {
	content: "\f10e";
}

.mdi-account-multiple-plus:before {
	content: "\f10f";
}

.mdi-account-network:before {
	content: "\f110";
}

.mdi-account-off:before {
	content: "\f111";
}

.mdi-account-outline:before {
	content: "\f112";
}

.mdi-account-plus:before {
	content: "\f113";
}

.mdi-account-remove:before {
	content: "\f114";
}

.mdi-account-search:before {
	content: "\f115";
}

.mdi-account-star:before {
	content: "\f116";
}

.mdi-account-star-variant:before {
	content: "\f117";
}

.mdi-account-switch:before {
	content: "\f118";
}

.mdi-adjust:before {
	content: "\f119";
}

.mdi-air-conditioner:before {
	content: "\f11a";
}

.mdi-airballoon:before {
	content: "\f11b";
}

.mdi-airplane:before {
	content: "\f11c";
}

.mdi-airplane-off:before {
	content: "\f11d";
}

.mdi-airplay:before {
	content: "\f11e";
}

.mdi-alarm:before {
	content: "\f11f";
}

.mdi-alarm-check:before {
	content: "\f120";
}

.mdi-alarm-multiple:before {
	content: "\f121";
}

.mdi-alarm-off:before {
	content: "\f122";
}

.mdi-alarm-plus:before {
	content: "\f123";
}

.mdi-album:before {
	content: "\f124";
}

.mdi-alert:before {
	content: "\f125";
}

.mdi-alert-box:before {
	content: "\f126";
}

.mdi-alert-circle:before {
	content: "\f127";
}

.mdi-alert-octagon:before {
	content: "\f128";
}

.mdi-alert-outline:before {
	content: "\f129";
}

.mdi-alpha:before {
	content: "\f12a";
}

.mdi-alphabetical:before {
	content: "\f12b";
}

.mdi-amazon:before {
	content: "\f12c";
}

.mdi-amazon-clouddrive:before {
	content: "\f12d";
}

.mdi-ambulance:before {
	content: "\f12e";
}

.mdi-anchor:before {
	content: "\f12f";
}

.mdi-android:before {
	content: "\f130";
}

.mdi-android-debug-bridge:before {
	content: "\f131";
}

.mdi-android-studio:before {
	content: "\f132";
}

.mdi-apple:before {
	content: "\f133";
}

.mdi-apple-finder:before {
	content: "\f134";
}

.mdi-apple-ios:before {
	content: "\f135";
}

.mdi-apple-mobileme:before {
	content: "\f136";
}

.mdi-apple-safari:before {
	content: "\f137";
}

.mdi-appnet:before {
	content: "\f138";
}

.mdi-apps:before {
	content: "\f139";
}

.mdi-archive:before {
	content: "\f13a";
}

.mdi-arrange-bring-forward:before {
	content: "\f13b";
}

.mdi-arrange-bring-to-front:before {
	content: "\f13c";
}

.mdi-arrange-send-backward:before {
	content: "\f13d";
}

.mdi-arrange-send-to-back:before {
	content: "\f13e";
}

.mdi-arrow-all:before {
	content: "\f13f";
}

.mdi-arrow-bottom-drop-circle:before {
	content: "\f140";
}

.mdi-arrow-bottom-left:before {
	content: "\f141";
}

.mdi-arrow-bottom-right:before {
	content: "\f142";
}

.mdi-arrow-collapse:before {
	content: "\f143";
}

.mdi-arrow-down:before {
	content: "\f144";
}

.mdi-arrow-down-bold:before {
	content: "\f145";
}

.mdi-arrow-down-bold-circle:before {
	content: "\f146";
}

.mdi-arrow-down-bold-circle-outline:before {
	content: "\f147";
}

.mdi-arrow-down-bold-hexagon-outline:before {
	content: "\f148";
}

.mdi-arrow-expand:before {
	content: "\f149";
}

.mdi-arrow-left:before {
	content: "\f14a";
}

.mdi-arrow-left-bold:before {
	content: "\f14b";
}

.mdi-arrow-left-bold-circle:before {
	content: "\f14c";
}

.mdi-arrow-left-bold-circle-outline:before {
	content: "\f14d";
}

.mdi-arrow-left-bold-hexagon-outline:before {
	content: "\f14e";
}

.mdi-arrow-right:before {
	content: "\f14f";
}

.mdi-arrow-right-bold:before {
	content: "\f150";
}

.mdi-arrow-right-bold-circle:before {
	content: "\f151";
}

.mdi-arrow-right-bold-circle-outline:before {
	content: "\f152";
}

.mdi-arrow-right-bold-hexagon-outline:before {
	content: "\f153";
}

.mdi-arrow-top-left:before {
	content: "\f154";
}

.mdi-arrow-top-right:before {
	content: "\f155";
}

.mdi-arrow-up:before {
	content: "\f156";
}

.mdi-arrow-up-bold:before {
	content: "\f157";
}

.mdi-arrow-up-bold-circle:before {
	content: "\f158";
}

.mdi-arrow-up-bold-circle-outline:before {
	content: "\f159";
}

.mdi-arrow-up-bold-hexagon-outline:before {
	content: "\f15a";
}

.mdi-assistant:before {
	content: "\f15b";
}

.mdi-at:before {
	content: "\f15c";
}

.mdi-attachment:before {
	content: "\f15d";
}

.mdi-audiobook:before {
	content: "\f15e";
}

.mdi-auto-fix:before {
	content: "\f15f";
}

.mdi-auto-upload:before {
	content: "\f160";
}

.mdi-autorenew:before {
	content: "\f161";
}

.mdi-av-timer:before {
	content: "\f162";
}

.mdi-baby:before {
	content: "\f163";
}

.mdi-backburger:before {
	content: "\f164";
}

.mdi-backspace:before {
	content: "\f165";
}

.mdi-backup-restore:before {
	content: "\f166";
}

.mdi-bank:before {
	content: "\f167";
}

.mdi-barcode:before {
	content: "\f168";
}

.mdi-barcode-scan:before {
	content: "\f169";
}

.mdi-barley:before {
	content: "\f16a";
}

.mdi-barrel:before {
	content: "\f16b";
}

.mdi-basecamp:before {
	content: "\f16c";
}

.mdi-basket:before {
	content: "\f16d";
}

.mdi-basket-fill:before {
	content: "\f16e";
}

.mdi-basket-unfill:before {
	content: "\f16f";
}

.mdi-battery:before {
	content: "\f170";
}

.mdi-battery-10:before {
	content: "\f171";
}

.mdi-battery-20:before {
	content: "\f172";
}

.mdi-battery-30:before {
	content: "\f173";
}

.mdi-battery-40:before {
	content: "\f174";
}

.mdi-battery-50:before {
	content: "\f175";
}

.mdi-battery-60:before {
	content: "\f176";
}

.mdi-battery-70:before {
	content: "\f177";
}

.mdi-battery-80:before {
	content: "\f178";
}

.mdi-battery-90:before {
	content: "\f179";
}

.mdi-battery-alert:before {
	content: "\f17a";
}

.mdi-battery-charging:before {
	content: "\f17b";
}

.mdi-battery-charging-100:before {
	content: "\f17c";
}

.mdi-battery-charging-20:before {
	content: "\f17d";
}

.mdi-battery-charging-30:before {
	content: "\f17e";
}

.mdi-battery-charging-40:before {
	content: "\f17f";
}

.mdi-battery-charging-60:before {
	content: "\f180";
}

.mdi-battery-charging-80:before {
	content: "\f181";
}

.mdi-battery-charging-90:before {
	content: "\f182";
}

.mdi-battery-minus:before {
	content: "\f183";
}

.mdi-battery-negative:before {
	content: "\f184";
}

.mdi-battery-outline:before {
	content: "\f185";
}

.mdi-battery-plus:before {
	content: "\f186";
}

.mdi-battery-positive:before {
	content: "\f187";
}

.mdi-battery-unknown:before {
	content: "\f188";
}

.mdi-beach:before {
	content: "\f189";
}

.mdi-beaker:before {
	content: "\f18a";
}

.mdi-beaker-empty:before {
	content: "\f18b";
}

.mdi-beaker-empty-outline:before {
	content: "\f18c";
}

.mdi-beaker-outline:before {
	content: "\f18d";
}

.mdi-beats:before {
	content: "\f18e";
}

.mdi-beer:before {
	content: "\f18f";
}

.mdi-behance:before {
	content: "\f190";
}

.mdi-bell:before {
	content: "\f191";
}

.mdi-bell-off:before {
	content: "\f192";
}

.mdi-bell-outline:before {
	content: "\f193";
}

.mdi-bell-plus:before {
	content: "\f194";
}

.mdi-bell-ring:before {
	content: "\f195";
}

.mdi-bell-ring-outline:before {
	content: "\f196";
}

.mdi-bell-sleep:before {
	content: "\f197";
}

.mdi-beta:before {
	content: "\f198";
}

.mdi-bike:before {
	content: "\f199";
}

.mdi-bing:before {
	content: "\f19a";
}

.mdi-binoculars:before {
	content: "\f19b";
}

.mdi-bio:before {
	content: "\f19c";
}

.mdi-biohazard:before {
	content: "\f19d";
}

.mdi-bitbucket:before {
	content: "\f19e";
}

.mdi-black-mesa:before {
	content: "\f19f";
}

.mdi-blackberry:before {
	content: "\f1a0";
}

.mdi-blender:before {
	content: "\f1a1";
}

.mdi-blinds:before {
	content: "\f1a2";
}

.mdi-block-helper:before {
	content: "\f1a3";
}

.mdi-blogger:before {
	content: "\f1a4";
}

.mdi-bluetooth:before {
	content: "\f1a5";
}

.mdi-bluetooth-audio:before {
	content: "\f1a6";
}

.mdi-bluetooth-connect:before {
	content: "\f1a7";
}

.mdi-bluetooth-off:before {
	content: "\f1a8";
}

.mdi-bluetooth-settings:before {
	content: "\f1a9";
}

.mdi-bluetooth-transfer:before {
	content: "\f1aa";
}

.mdi-blur:before {
	content: "\f1ab";
}

.mdi-blur-linear:before {
	content: "\f1ac";
}

.mdi-blur-off:before {
	content: "\f1ad";
}

.mdi-blur-radial:before {
	content: "\f1ae";
}

.mdi-bone:before {
	content: "\f1af";
}

.mdi-book:before {
	content: "\f1b0";
}

.mdi-book-multiple:before {
	content: "\f1b1";
}

.mdi-book-multiple-variant:before {
	content: "\f1b2";
}

.mdi-book-open:before {
	content: "\f1b3";
}

.mdi-book-open-variant:before {
	content: "\f1b4";
}

.mdi-book-variant:before {
	content: "\f1b5";
}

.mdi-bookmark:before {
	content: "\f1b6";
}

.mdi-bookmark-check:before {
	content: "\f1b7";
}

.mdi-bookmark-music:before {
	content: "\f1b8";
}

.mdi-bookmark-outline:before {
	content: "\f1b9";
}

.mdi-bookmark-outline-plus:before {
	content: "\f1ba";
}

.mdi-bookmark-plus:before {
	content: "\f1bb";
}

.mdi-bookmark-remove:before {
	content: "\f1bc";
}

.mdi-border-all:before {
	content: "\f1bd";
}

.mdi-border-bottom:before {
	content: "\f1be";
}

.mdi-border-color:before {
	content: "\f1bf";
}

.mdi-border-horizontal:before {
	content: "\f1c0";
}

.mdi-border-inside:before {
	content: "\f1c1";
}

.mdi-border-left:before {
	content: "\f1c2";
}

.mdi-border-none:before {
	content: "\f1c3";
}

.mdi-border-outside:before {
	content: "\f1c4";
}

.mdi-border-right:before {
	content: "\f1c5";
}

.mdi-border-style:before {
	content: "\f1c6";
}

.mdi-border-top:before {
	content: "\f1c7";
}

.mdi-border-vertical:before {
	content: "\f1c8";
}

.mdi-bowling:before {
	content: "\f1c9";
}

.mdi-box:before {
	content: "\f1ca";
}

.mdi-box-cutter:before {
	content: "\f1cb";
}

.mdi-briefcase:before {
	content: "\f1cc";
}

.mdi-briefcase-check:before {
	content: "\f1cd";
}

.mdi-briefcase-download:before {
	content: "\f1ce";
}

.mdi-briefcase-upload:before {
	content: "\f1cf";
}

.mdi-brightness-1:before {
	content: "\f1d0";
}

.mdi-brightness-2:before {
	content: "\f1d1";
}

.mdi-brightness-3:before {
	content: "\f1d2";
}

.mdi-brightness-4:before {
	content: "\f1d3";
}

.mdi-brightness-5:before {
	content: "\f1d4";
}

.mdi-brightness-6:before {
	content: "\f1d5";
}

.mdi-brightness-7:before {
	content: "\f1d6";
}

.mdi-brightness-auto:before {
	content: "\f1d7";
}

.mdi-broom:before {
	content: "\f1d8";
}

.mdi-brush:before {
	content: "\f1d9";
}

.mdi-bug:before {
	content: "\f1da";
}

.mdi-bulletin-board:before {
	content: "\f1db";
}

.mdi-bullhorn:before {
	content: "\f1dc";
}

.mdi-bus:before {
	content: "\f1dd";
}

.mdi-cached:before {
	content: "\f1de";
}

.mdi-cake:before {
	content: "\f1df";
}

.mdi-cake-layered:before {
	content: "\f1e0";
}

.mdi-cake-variant:before {
	content: "\f1e1";
}

.mdi-calculator:before {
	content: "\f1e2";
}

.mdi-calendar:before {
	content: "\f1e3";
}

.mdi-calendar-blank:before {
	content: "\f1e4";
}

.mdi-calendar-check:before {
	content: "\f1e5";
}

.mdi-calendar-clock:before {
	content: "\f1e6";
}

.mdi-calendar-multiple:before {
	content: "\f1e7";
}

.mdi-calendar-multiple-check:before {
	content: "\f1e8";
}

.mdi-calendar-plus:before {
	content: "\f1e9";
}

.mdi-calendar-remove:before {
	content: "\f1ea";
}

.mdi-calendar-text:before {
	content: "\f1eb";
}

.mdi-calendar-today:before {
	content: "\f1ec";
}

.mdi-call-made:before {
	content: "\f1ed";
}

.mdi-call-merge:before {
	content: "\f1ee";
}

.mdi-call-missed:before {
	content: "\f1ef";
}

.mdi-call-received:before {
	content: "\f1f0";
}

.mdi-call-split:before {
	content: "\f1f1";
}

.mdi-camcorder:before {
	content: "\f1f2";
}

.mdi-camcorder-box:before {
	content: "\f1f3";
}

.mdi-camcorder-box-off:before {
	content: "\f1f4";
}

.mdi-camcorder-off:before {
	content: "\f1f5";
}

.mdi-camera:before {
	content: "\f1f6";
}

.mdi-camera-enhance:before {
	content: "\f1f7";
}

.mdi-camera-front:before {
	content: "\f1f8";
}

.mdi-camera-front-variant:before {
	content: "\f1f9";
}

.mdi-camera-iris:before {
	content: "\f1fa";
}

.mdi-camera-party-mode:before {
	content: "\f1fb";
}

.mdi-camera-rear:before {
	content: "\f1fc";
}

.mdi-camera-rear-variant:before {
	content: "\f1fd";
}

.mdi-camera-switch:before {
	content: "\f1fe";
}

.mdi-camera-timer:before {
	content: "\f1ff";
}

.mdi-candycane:before {
	content: "\f200";
}

.mdi-car:before {
	content: "\f201";
}

.mdi-car-battery:before {
	content: "\f202";
}

.mdi-car-connected:before {
	content: "\f203";
}

.mdi-car-wash:before {
	content: "\f204";
}

.mdi-carrot:before {
	content: "\f205";
}

.mdi-cart:before {
	content: "\f206";
}

.mdi-cart-outline:before {
	content: "\f207";
}

.mdi-cart-plus:before {
	content: "\f208";
}

.mdi-case-sensitive-alt:before {
	content: "\f209";
}

.mdi-cash:before {
	content: "\f20a";
}

.mdi-cash-100:before {
	content: "\f20b";
}

.mdi-cash-multiple:before {
	content: "\f20c";
}

.mdi-cash-usd:before {
	content: "\f20d";
}

.mdi-cast:before {
	content: "\f20e";
}

.mdi-cast-connected:before {
	content: "\f20f";
}

.mdi-castle:before {
	content: "\f210";
}

.mdi-cat:before {
	content: "\f211";
}

.mdi-cellphone:before {
	content: "\f212";
}

.mdi-cellphone-android:before {
	content: "\f213";
}

.mdi-cellphone-basic:before {
	content: "\f214";
}

.mdi-cellphone-dock:before {
	content: "\f215";
}

.mdi-cellphone-iphone:before {
	content: "\f216";
}

.mdi-cellphone-link:before {
	content: "\f217";
}

.mdi-cellphone-link-off:before {
	content: "\f218";
}

.mdi-cellphone-settings:before {
	content: "\f219";
}

.mdi-certificate:before {
	content: "\f21a";
}

.mdi-chair-school:before {
	content: "\f21b";
}

.mdi-chart-arc:before {
	content: "\f21c";
}

.mdi-chart-areaspline:before {
	content: "\f21d";
}

.mdi-chart-bar:before {
	content: "\f21e";
}

.mdi-chart-histogram:before {
	content: "\f21f";
}

.mdi-chart-line:before {
	content: "\f220";
}

.mdi-chart-pie:before {
	content: "\f221";
}

.mdi-check:before {
	content: "\f222";
}

.mdi-check-all:before {
	content: "\f223";
}

.mdi-checkbox-blank:before {
	content: "\f224";
}

.mdi-checkbox-blank-circle:before {
	content: "\f225";
}

.mdi-checkbox-blank-circle-outline:before {
	content: "\f226";
}

.mdi-checkbox-blank-outline:before {
	content: "\f227";
}

.mdi-checkbox-marked:before {
	content: "\f228";
}

.mdi-checkbox-marked-circle:before {
	content: "\f229";
}

.mdi-checkbox-marked-circle-outline:before {
	content: "\f22a";
}

.mdi-checkbox-marked-outline:before {
	content: "\f22b";
}

.mdi-checkbox-multiple-blank:before {
	content: "\f22c";
}

.mdi-checkbox-multiple-blank-outline:before {
	content: "\f22d";
}

.mdi-checkbox-multiple-marked:before {
	content: "\f22e";
}

.mdi-checkbox-multiple-marked-outline:before {
	content: "\f22f";
}

.mdi-checkerboard:before {
	content: "\f230";
}

.mdi-chemical-weapon:before {
	content: "\f231";
}

.mdi-chevron-double-down:before {
	content: "\f232";
}

.mdi-chevron-double-left:before {
	content: "\f233";
}

.mdi-chevron-double-right:before {
	content: "\f234";
}

.mdi-chevron-double-up:before {
	content: "\f235";
}

.mdi-chevron-down:before {
	content: "\f236";
}

.mdi-chevron-left:before {
	content: "\f237";
}

.mdi-chevron-right:before {
	content: "\f238";
}

.mdi-chevron-up:before {
	content: "\f239";
}

.mdi-church:before {
	content: "\f23a";
}

.mdi-cisco-webex:before {
	content: "\f23b";
}

.mdi-city:before {
	content: "\f23c";
}

.mdi-clipboard:before {
	content: "\f23d";
}

.mdi-clipboard-account:before {
	content: "\f23e";
}

.mdi-clipboard-alert:before {
	content: "\f23f";
}

.mdi-clipboard-arrow-down:before {
	content: "\f240";
}

.mdi-clipboard-arrow-left:before {
	content: "\f241";
}

.mdi-clipboard-check:before {
	content: "\f242";
}

.mdi-clipboard-outline:before {
	content: "\f243";
}

.mdi-clipboard-text:before {
	content: "\f244";
}

.mdi-clippy:before {
	content: "\f245";
}

.mdi-clock:before {
	content: "\f246";
}

.mdi-clock-end:before {
	content: "\f247";
}

.mdi-clock-fast:before {
	content: "\f248";
}

.mdi-clock-in:before {
	content: "\f249";
}

.mdi-clock-out:before {
	content: "\f24a";
}

.mdi-clock-start:before {
	content: "\f24b";
}

.mdi-close:before {
	content: "\f24c";
}

.mdi-close-box:before {
	content: "\f24d";
}

.mdi-close-box-outline:before {
	content: "\f24e";
}

.mdi-close-circle:before {
	content: "\f24f";
}

.mdi-close-circle-outline:before {
	content: "\f250";
}

.mdi-close-network:before {
	content: "\f251";
}

.mdi-close-octagon:before {
	content: "\f252";
}

.mdi-close-octagon-outline:before {
	content: "\f253";
}

.mdi-closed-caption:before {
	content: "\f254";
}

.mdi-cloud:before {
	content: "\f255";
}

.mdi-cloud-check:before {
	content: "\f256";
}

.mdi-cloud-circle:before {
	content: "\f257";
}

.mdi-cloud-download:before {
	content: "\f258";
}

.mdi-cloud-outline:before {
	content: "\f259";
}

.mdi-cloud-outline-off:before {
	content: "\f25a";
}

.mdi-cloud-print:before {
	content: "\f25b";
}

.mdi-cloud-print-outline:before {
	content: "\f25c";
}

.mdi-cloud-upload:before {
	content: "\f25d";
}

.mdi-code-array:before {
	content: "\f25e";
}

.mdi-code-braces:before {
	content: "\f25f";
}

.mdi-code-brackets:before {
	content: "\f260";
}

.mdi-code-equal:before {
	content: "\f261";
}

.mdi-code-greater-than:before {
	content: "\f262";
}

.mdi-code-greater-than-or-equal:before {
	content: "\f263";
}

.mdi-code-less-than:before {
	content: "\f264";
}

.mdi-code-less-than-or-equal:before {
	content: "\f265";
}

.mdi-code-not-equal:before {
	content: "\f266";
}

.mdi-code-not-equal-variant:before {
	content: "\f267";
}

.mdi-code-parentheses:before {
	content: "\f268";
}

.mdi-code-string:before {
	content: "\f269";
}

.mdi-code-tags:before {
	content: "\f26a";
}

.mdi-codepen:before {
	content: "\f26b";
}

.mdi-coffee:before {
	content: "\f26c";
}

.mdi-coffee-to-go:before {
	content: "\f26d";
}

.mdi-coin:before {
	content: "\f26e";
}

.mdi-color-helper:before {
	content: "\f26f";
}

.mdi-comment:before {
	content: "\f270";
}

.mdi-comment-account:before {
	content: "\f271";
}

.mdi-comment-account-outline:before {
	content: "\f272";
}

.mdi-comment-alert:before {
	content: "\f273";
}

.mdi-comment-alert-outline:before {
	content: "\f274";
}

.mdi-comment-check:before {
	content: "\f275";
}

.mdi-comment-check-outline:before {
	content: "\f276";
}

.mdi-comment-multiple-outline:before {
	content: "\f277";
}

.mdi-comment-outline:before {
	content: "\f278";
}

.mdi-comment-plus-outline:before {
	content: "\f279";
}

.mdi-comment-processing:before {
	content: "\f27a";
}

.mdi-comment-processing-outline:before {
	content: "\f27b";
}

.mdi-comment-question-outline:before {
	content: "\f27c";
}

.mdi-comment-remove-outline:before {
	content: "\f27d";
}

.mdi-comment-text:before {
	content: "\f27e";
}

.mdi-comment-text-outline:before {
	content: "\f27f";
}

.mdi-compare:before {
	content: "\f280";
}

.mdi-compass:before {
	content: "\f281";
}

.mdi-compass-outline:before {
	content: "\f282";
}

.mdi-console:before {
	content: "\f283";
}

.mdi-contact-mail:before {
	content: "\f284";
}

.mdi-content-copy:before {
	content: "\f285";
}

.mdi-content-cut:before {
	content: "\f286";
}

.mdi-content-duplicate:before {
	content: "\f287";
}

.mdi-content-paste:before {
	content: "\f288";
}

.mdi-content-save:before {
	content: "\f289";
}

.mdi-content-save-all:before {
	content: "\f28a";
}

.mdi-contrast:before {
	content: "\f28b";
}

.mdi-contrast-box:before {
	content: "\f28c";
}

.mdi-contrast-circle:before {
	content: "\f28d";
}

.mdi-cookie:before {
	content: "\f28e";
}

.mdi-cow:before {
	content: "\f28f";
}

.mdi-credit-card:before {
	content: "\f290";
}

.mdi-credit-card-multiple:before {
	content: "\f291";
}

.mdi-credit-card-scan:before {
	content: "\f292";
}

.mdi-crop:before {
	content: "\f293";
}

.mdi-crop-free:before {
	content: "\f294";
}

.mdi-crop-landscape:before {
	content: "\f295";
}

.mdi-crop-portrait:before {
	content: "\f296";
}

.mdi-crop-square:before {
	content: "\f297";
}

.mdi-crosshairs:before {
	content: "\f298";
}

.mdi-crosshairs-gps:before {
	content: "\f299";
}

.mdi-crown:before {
	content: "\f29a";
}

.mdi-cube:before {
	content: "\f29b";
}

.mdi-cube-outline:before {
	content: "\f29c";
}

.mdi-cube-send:before {
	content: "\f29d";
}

.mdi-cube-unfolded:before {
	content: "\f29e";
}

.mdi-cup:before {
	content: "\f29f";
}

.mdi-cup-water:before {
	content: "\f2a0";
}

.mdi-currency-btc:before {
	content: "\f2a1";
}

.mdi-currency-eur:before {
	content: "\f2a2";
}

.mdi-currency-gbp:before {
	content: "\f2a3";
}

.mdi-currency-inr:before {
	content: "\f2a4";
}

.mdi-currency-ngn:before {
	content: "\f2a5";
}

.mdi-currency-rub:before {
	content: "\f2a6";
}

.mdi-currency-try:before {
	content: "\f2a7";
}

.mdi-currency-usd:before {
	content: "\f2a8";
}

.mdi-cursor-default:before {
	content: "\f2a9";
}

.mdi-cursor-default-outline:before {
	content: "\f2aa";
}

.mdi-cursor-move:before {
	content: "\f2ab";
}

.mdi-cursor-pointer:before {
	content: "\f2ac";
}

.mdi-database:before {
	content: "\f2ad";
}

.mdi-database-minus:before {
	content: "\f2ae";
}

.mdi-database-plus:before {
	content: "\f2af";
}

.mdi-debug-step-into:before {
	content: "\f2b0";
}

.mdi-debug-step-out:before {
	content: "\f2b1";
}

.mdi-debug-step-over:before {
	content: "\f2b2";
}

.mdi-decimal-decrease:before {
	content: "\f2b3";
}

.mdi-decimal-increase:before {
	content: "\f2b4";
}

.mdi-delete:before {
	content: "\f2b5";
}

.mdi-delete-variant:before {
	content: "\f2b6";
}

.mdi-delta:before {
	content: "\f2b7";
}

.mdi-deskphone:before {
	content: "\f2b8";
}

.mdi-desktop-mac:before {
	content: "\f2b9";
}

.mdi-desktop-tower:before {
	content: "\f2ba";
}

.mdi-details:before {
	content: "\f2bb";
}

.mdi-deviantart:before {
	content: "\f2bc";
}

.mdi-diamond:before {
	content: "\f2bd";
}

.mdi-dice:before {
	content: "\f2be";
}

.mdi-dice-1:before {
	content: "\f2bf";
}

.mdi-dice-2:before {
	content: "\f2c0";
}

.mdi-dice-3:before {
	content: "\f2c1";
}

.mdi-dice-4:before {
	content: "\f2c2";
}

.mdi-dice-5:before {
	content: "\f2c3";
}

.mdi-dice-6:before {
	content: "\f2c4";
}

.mdi-directions:before {
	content: "\f2c5";
}

.mdi-disk-alert:before {
	content: "\f2c6";
}

.mdi-disqus:before {
	content: "\f2c7";
}

.mdi-disqus-outline:before {
	content: "\f2c8";
}

.mdi-division:before {
	content: "\f2c9";
}

.mdi-division-box:before {
	content: "\f2ca";
}

.mdi-dns:before {
	content: "\f2cb";
}

.mdi-domain:before {
	content: "\f2cc";
}

.mdi-dots-horizontal:before {
	content: "\f2cd";
}

.mdi-dots-vertical:before {
	content: "\f2ce";
}

.mdi-download:before {
	content: "\f2cf";
}

.mdi-drag:before {
	content: "\f2d0";
}

.mdi-drag-horizontal:before {
	content: "\f2d1";
}

.mdi-drag-vertical:before {
	content: "\f2d2";
}

.mdi-drawing:before {
	content: "\f2d3";
}

.mdi-drawing-box:before {
	content: "\f2d4";
}

.mdi-dribbble:before {
	content: "\f2d5";
}

.mdi-dribbble-box:before {
	content: "\f2d6";
}

.mdi-drone:before {
	content: "\f2d7";
}

.mdi-dropbox:before {
	content: "\f2d8";
}

.mdi-drupal:before {
	content: "\f2d9";
}

.mdi-duck:before {
	content: "\f2da";
}

.mdi-dumbbell:before {
	content: "\f2db";
}

.mdi-earth:before {
	content: "\f2dc";
}

.mdi-earth-off:before {
	content: "\f2dd";
}

.mdi-edge:before {
	content: "\f2de";
}

.mdi-eject:before {
	content: "\f2df";
}

.mdi-elevation-decline:before {
	content: "\f2e0";
}

.mdi-elevation-rise:before {
	content: "\f2e1";
}

.mdi-elevator:before {
	content: "\f2e2";
}

.mdi-email:before {
	content: "\f2e3";
}

.mdi-email-open:before {
	content: "\f2e4";
}

.mdi-email-outline:before {
	content: "\f2e5";
}

.mdi-email-secure:before {
	content: "\f2e6";
}

.mdi-emoticon:before {
	content: "\f2e7";
}

.mdi-emoticon-cool:before {
	content: "\f2e8";
}

.mdi-emoticon-devil:before {
	content: "\f2e9";
}

.mdi-emoticon-happy:before {
	content: "\f2ea";
}

.mdi-emoticon-neutral:before {
	content: "\f2eb";
}

.mdi-emoticon-poop:before {
	content: "\f2ec";
}

.mdi-emoticon-sad:before {
	content: "\f2ed";
}

.mdi-emoticon-tongue:before {
	content: "\f2ee";
}

.mdi-engine:before {
	content: "\f2ef";
}

.mdi-engine-outline:before {
	content: "\f2f0";
}

.mdi-equal:before {
	content: "\f2f1";
}

.mdi-equal-box:before {
	content: "\f2f2";
}

.mdi-eraser:before {
	content: "\f2f3";
}

.mdi-escalator:before {
	content: "\f2f4";
}

.mdi-ethernet:before {
	content: "\f2f5";
}

.mdi-ethernet-cable:before {
	content: "\f2f6";
}

.mdi-ethernet-cable-off:before {
	content: "\f2f7";
}

.mdi-etsy:before {
	content: "\f2f8";
}

.mdi-evernote:before {
	content: "\f2f9";
}

.mdi-exclamation:before {
	content: "\f2fa";
}

.mdi-exit-to-app:before {
	content: "\f2fb";
}

.mdi-export:before {
	content: "\f2fc";
}

.mdi-eye:before {
	content: "\f2fd";
}

.mdi-eye-off:before {
	content: "\f2fe";
}

.mdi-eyedropper:before {
	content: "\f2ff";
}

.mdi-eyedropper-variant:before {
	content: "\f300";
}

.mdi-facebook:before {
	content: "\f301";
}

.mdi-facebook-box:before {
	content: "\f302";
}

.mdi-facebook-messenger:before {
	content: "\f303";
}

.mdi-factory:before {
	content: "\f304";
}

.mdi-fan:before {
	content: "\f305";
}

.mdi-fast-forward:before {
	content: "\f306";
}

.mdi-fax:before {
	content: "\f307";
}

.mdi-ferry:before {
	content: "\f308";
}

.mdi-file:before {
	content: "\f309";
}

.mdi-file-chart:before {
	content: "\f30a";
}

.mdi-file-check:before {
	content: "\f30b";
}

.mdi-file-cloud:before {
	content: "\f30c";
}

.mdi-file-delimited:before {
	content: "\f30d";
}

.mdi-file-document:before {
	content: "\f30e";
}

.mdi-file-document-box:before {
	content: "\f30f";
}

.mdi-file-excel:before {
	content: "\f310";
}

.mdi-file-excel-box:before {
	content: "\f311";
}

.mdi-file-export:before {
	content: "\f312";
}

.mdi-file-find:before {
	content: "\f313";
}

.mdi-file-image:before {
	content: "\f314";
}

.mdi-file-import:before {
	content: "\f315";
}

.mdi-file-lock:before {
	content: "\f316";
}

.mdi-file-multiple:before {
	content: "\f317";
}

.mdi-file-music:before {
	content: "\f318";
}

.mdi-file-outline:before {
	content: "\f319";
}

.mdi-file-pdf:before {
	content: "\f31a";
}

.mdi-file-pdf-box:before {
	content: "\f31b";
}

.mdi-file-powerpoint:before {
	content: "\f31c";
}

.mdi-file-powerpoint-box:before {
	content: "\f31d";
}

.mdi-file-presentation-box:before {
	content: "\f31e";
}

.mdi-file-send:before {
	content: "\f31f";
}

.mdi-file-video:before {
	content: "\f320";
}

.mdi-file-word:before {
	content: "\f321";
}

.mdi-file-word-box:before {
	content: "\f322";
}

.mdi-file-xml:before {
	content: "\f323";
}

.mdi-film:before {
	content: "\f324";
}

.mdi-filmstrip:before {
	content: "\f325";
}

.mdi-filmstrip-off:before {
	content: "\f326";
}

.mdi-filter:before {
	content: "\f327";
}

.mdi-filter-outline:before {
	content: "\f328";
}

.mdi-filter-remove:before {
	content: "\f329";
}

.mdi-filter-remove-outline:before {
	content: "\f32a";
}

.mdi-filter-variant:before {
	content: "\f32b";
}

.mdi-fingerprint:before {
	content: "\f32c";
}

.mdi-fire:before {
	content: "\f32d";
}

.mdi-firefox:before {
	content: "\f32e";
}

.mdi-fish:before {
	content: "\f32f";
}

.mdi-flag:before {
	content: "\f330";
}

.mdi-flag-checkered:before {
	content: "\f331";
}

.mdi-flag-outline:before {
	content: "\f332";
}

.mdi-flag-outline-variant:before {
	content: "\f333";
}

.mdi-flag-triangle:before {
	content: "\f334";
}

.mdi-flag-variant:before {
	content: "\f335";
}

.mdi-flash:before {
	content: "\f336";
}

.mdi-flash-auto:before {
	content: "\f337";
}

.mdi-flash-off:before {
	content: "\f338";
}

.mdi-flashlight:before {
	content: "\f339";
}

.mdi-flashlight-off:before {
	content: "\f33a";
}

.mdi-flattr:before {
	content: "\f33b";
}

.mdi-flip-to-back:before {
	content: "\f33c";
}

.mdi-flip-to-front:before {
	content: "\f33d";
}

.mdi-floppy:before {
	content: "\f33e";
}

.mdi-flower:before {
	content: "\f33f";
}

.mdi-folder:before {
	content: "\f340";
}

.mdi-folder-account:before {
	content: "\f341";
}

.mdi-folder-download:before {
	content: "\f342";
}

.mdi-folder-google-drive:before {
	content: "\f343";
}

.mdi-folder-image:before {
	content: "\f344";
}

.mdi-folder-lock:before {
	content: "\f345";
}

.mdi-folder-lock-open:before {
	content: "\f346";
}

.mdi-folder-move:before {
	content: "\f347";
}

.mdi-folder-multiple:before {
	content: "\f348";
}

.mdi-folder-multiple-image:before {
	content: "\f349";
}

.mdi-folder-multiple-outline:before {
	content: "\f34a";
}

.mdi-folder-outline:before {
	content: "\f34b";
}

.mdi-folder-plus:before {
	content: "\f34c";
}

.mdi-folder-remove:before {
	content: "\f34d";
}

.mdi-folder-upload:before {
	content: "\f34e";
}

.mdi-food:before {
	content: "\f34f";
}

.mdi-food-apple:before {
	content: "\f350";
}

.mdi-food-variant:before {
	content: "\f351";
}

.mdi-football:before {
	content: "\f352";
}

.mdi-football-australian:before {
	content: "\f353";
}

.mdi-football-helmet:before {
	content: "\f354";
}

.mdi-format-align-center:before {
	content: "\f355";
}

.mdi-format-align-justify:before {
	content: "\f356";
}

.mdi-format-align-left:before {
	content: "\f357";
}

.mdi-format-align-right:before {
	content: "\f358";
}

.mdi-format-bold:before {
	content: "\f359";
}

.mdi-format-clear:before {
	content: "\f35a";
}

.mdi-format-color-fill:before {
	content: "\f35b";
}

.mdi-format-float-center:before {
	content: "\f35c";
}

.mdi-format-float-left:before {
	content: "\f35d";
}

.mdi-format-float-none:before {
	content: "\f35e";
}

.mdi-format-float-right:before {
	content: "\f35f";
}

.mdi-format-header-1:before {
	content: "\f360";
}

.mdi-format-header-2:before {
	content: "\f361";
}

.mdi-format-header-3:before {
	content: "\f362";
}

.mdi-format-header-4:before {
	content: "\f363";
}

.mdi-format-header-5:before {
	content: "\f364";
}

.mdi-format-header-6:before {
	content: "\f365";
}

.mdi-format-header-decrease:before {
	content: "\f366";
}

.mdi-format-header-equal:before {
	content: "\f367";
}

.mdi-format-header-increase:before {
	content: "\f368";
}

.mdi-format-header-pound:before {
	content: "\f369";
}

.mdi-format-indent-decrease:before {
	content: "\f36a";
}

.mdi-format-indent-increase:before {
	content: "\f36b";
}

.mdi-format-italic:before {
	content: "\f36c";
}

.mdi-format-line-spacing:before {
	content: "\f36d";
}

.mdi-format-list-bulleted:before {
	content: "\f36e";
}

.mdi-format-list-bulleted-type:before {
	content: "\f36f";
}

.mdi-format-list-numbers:before {
	content: "\f370";
}

.mdi-format-paint:before {
	content: "\f371";
}

.mdi-format-paragraph:before {
	content: "\f372";
}

.mdi-format-quote:before {
	content: "\f373";
}

.mdi-format-size:before {
	content: "\f374";
}

.mdi-format-strikethrough:before {
	content: "\f375";
}

.mdi-format-strikethrough-variant:before {
	content: "\f376";
}

.mdi-format-subscript:before {
	content: "\f377";
}

.mdi-format-superscript:before {
	content: "\f378";
}

.mdi-format-text:before {
	content: "\f379";
}

.mdi-format-textdirection-l-to-r:before {
	content: "\f37a";
}

.mdi-format-textdirection-r-to-l:before {
	content: "\f37b";
}

.mdi-format-underline:before {
	content: "\f37c";
}

.mdi-format-wrap-inline:before {
	content: "\f37d";
}

.mdi-format-wrap-square:before {
	content: "\f37e";
}

.mdi-format-wrap-tight:before {
	content: "\f37f";
}

.mdi-format-wrap-top-bottom:before {
	content: "\f380";
}

.mdi-forum:before {
	content: "\f381";
}

.mdi-forward:before {
	content: "\f382";
}

.mdi-foursquare:before {
	content: "\f383";
}

.mdi-fridge:before {
	content: "\f384";
}

.mdi-fridge-filled:before {
	content: "\f385";
}

.mdi-fridge-filled-bottom:before {
	content: "\f386";
}

.mdi-fridge-filled-top:before {
	content: "\f387";
}

.mdi-fullscreen:before {
	content: "\f388";
}

.mdi-fullscreen-exit:before {
	content: "\f389";
}

.mdi-function:before {
	content: "\f38a";
}

.mdi-gamepad:before {
	content: "\f38b";
}

.mdi-gamepad-variant:before {
	content: "\f38c";
}

.mdi-gas-station:before {
	content: "\f38d";
}

.mdi-gate:before {
	content: "\f38e";
}

.mdi-gauge:before {
	content: "\f38f";
}

.mdi-gavel:before {
	content: "\f390";
}

.mdi-gender-female:before {
	content: "\f391";
}

.mdi-gender-male:before {
	content: "\f392";
}

.mdi-gender-male-female:before {
	content: "\f393";
}

.mdi-gender-transgender:before {
	content: "\f394";
}

.mdi-ghost:before {
	content: "\f395";
}

.mdi-gift:before {
	content: "\f396";
}

.mdi-git:before {
	content: "\f397";
}

.mdi-github-box:before {
	content: "\f398";
}

.mdi-github-circle:before {
	content: "\f399";
}

.mdi-glass-flute:before {
	content: "\f39a";
}

.mdi-glass-mug:before {
	content: "\f39b";
}

.mdi-glass-stange:before {
	content: "\f39c";
}

.mdi-glass-tulip:before {
	content: "\f39d";
}

.mdi-glasses:before {
	content: "\f39e";
}

.mdi-gmail:before {
	content: "\f39f";
}

.mdi-google:before {
	content: "\f3a0";
}

.mdi-google-cardboard:before {
	content: "\f3a1";
}

.mdi-google-chrome:before {
	content: "\f3a2";
}

.mdi-google-circles:before {
	content: "\f3a3";
}

.mdi-google-circles-communities:before {
	content: "\f3a4";
}

.mdi-google-circles-extended:before {
	content: "\f3a5";
}

.mdi-google-circles-group:before {
	content: "\f3a6";
}

.mdi-google-controller:before {
	content: "\f3a7";
}

.mdi-google-controller-off:before {
	content: "\f3a8";
}

.mdi-google-drive:before {
	content: "\f3a9";
}

.mdi-google-earth:before {
	content: "\f3aa";
}

.mdi-google-glass:before {
	content: "\f3ab";
}

.mdi-google-nearby:before {
	content: "\f3ac";
}

.mdi-google-pages:before {
	content: "\f3ad";
}

.mdi-google-physical-web:before {
	content: "\f3ae";
}

.mdi-google-play:before {
	content: "\f3af";
}

.mdi-google-plus:before {
	content: "\f3b0";
}

.mdi-google-plus-box:before {
	content: "\f3b1";
}

.mdi-google-translate:before {
	content: "\f3b2";
}

.mdi-google-wallet:before {
	content: "\f3b3";
}

.mdi-grid:before {
	content: "\f3b4";
}

.mdi-grid-off:before {
	content: "\f3b5";
}

.mdi-group:before {
	content: "\f3b6";
}

.mdi-guitar:before {
	content: "\f3b7";
}

.mdi-guitar-pick:before {
	content: "\f3b8";
}

.mdi-guitar-pick-outline:before {
	content: "\f3b9";
}

.mdi-hand-pointing-right:before {
	content: "\f3ba";
}

.mdi-hanger:before {
	content: "\f3bb";
}

.mdi-hangouts:before {
	content: "\f3bc";
}

.mdi-harddisk:before {
	content: "\f3bd";
}

.mdi-headphones:before {
	content: "\f3be";
}

.mdi-headphones-box:before {
	content: "\f3bf";
}

.mdi-headphones-settings:before {
	content: "\f3c0";
}

.mdi-headset:before {
	content: "\f3c1";
}

.mdi-headset-dock:before {
	content: "\f3c2";
}

.mdi-headset-off:before {
	content: "\f3c3";
}

.mdi-heart:before {
	content: "\f3c4";
}

.mdi-heart-box:before {
	content: "\f3c5";
}

.mdi-heart-box-outline:before {
	content: "\f3c6";
}

.mdi-heart-broken:before {
	content: "\f3c7";
}

.mdi-heart-outline:before {
	content: "\f3c8";
}

.mdi-help:before {
	content: "\f3c9";
}

.mdi-help-circle:before {
	content: "\f3ca";
}

.mdi-hexagon:before {
	content: "\f3cb";
}

.mdi-hexagon-outline:before {
	content: "\f3cc";
}

.mdi-history:before {
	content: "\f3cd";
}

.mdi-hololens:before {
	content: "\f3ce";
}

.mdi-home:before {
	content: "\f3cf";
}

.mdi-home-modern:before {
	content: "\f3d0";
}

.mdi-home-variant:before {
	content: "\f3d1";
}

.mdi-hops:before {
	content: "\f3d2";
}

.mdi-hospital:before {
	content: "\f3d3";
}

.mdi-hospital-building:before {
	content: "\f3d4";
}

.mdi-hospital-marker:before {
	content: "\f3d5";
}

.mdi-hotel:before {
	content: "\f3d6";
}

.mdi-houzz:before {
	content: "\f3d7";
}

.mdi-houzz-box:before {
	content: "\f3d8";
}

.mdi-human:before {
	content: "\f3d9";
}

.mdi-human-child:before {
	content: "\f3da";
}

.mdi-human-male-female:before {
	content: "\f3db";
}

.mdi-image:before {
	content: "\f3dc";
}

.mdi-image-album:before {
	content: "\f3dd";
}

.mdi-image-area:before {
	content: "\f3de";
}

.mdi-image-area-close:before {
	content: "\f3df";
}

.mdi-image-broken:before {
	content: "\f3e0";
}

.mdi-image-broken-variant:before {
	content: "\f3e1";
}

.mdi-image-filter:before {
	content: "\f3e2";
}

.mdi-image-filter-black-white:before {
	content: "\f3e3";
}

.mdi-image-filter-center-focus:before {
	content: "\f3e4";
}

.mdi-image-filter-center-focus-weak:before {
	content: "\f3e5";
}

.mdi-image-filter-drama:before {
	content: "\f3e6";
}

.mdi-image-filter-frames:before {
	content: "\f3e7";
}

.mdi-image-filter-hdr:before {
	content: "\f3e8";
}

.mdi-image-filter-none:before {
	content: "\f3e9";
}

.mdi-image-filter-tilt-shift:before {
	content: "\f3ea";
}

.mdi-image-filter-vintage:before {
	content: "\f3eb";
}

.mdi-image-multiple:before {
	content: "\f3ec";
}

.mdi-import:before {
	content: "\f3ed";
}

.mdi-inbox:before {
	content: "\f3ee";
}

.mdi-information:before {
	content: "\f3ef";
}

.mdi-information-outline:before {
	content: "\f3f0";
}

.mdi-instagram:before {
	content: "\f3f1";
}

.mdi-instapaper:before {
	content: "\f3f2";
}

.mdi-internet-explorer:before {
	content: "\f3f3";
}

.mdi-invert-colors:before {
	content: "\f3f4";
}

.mdi-jeepney:before {
	content: "\f3f5";
}

.mdi-jira:before {
	content: "\f3f6";
}

.mdi-jsfiddle:before {
	content: "\f3f7";
}

.mdi-keg:before {
	content: "\f3f8";
}

.mdi-key:before {
	content: "\f3f9";
}

.mdi-key-change:before {
	content: "\f3fa";
}

.mdi-key-minus:before {
	content: "\f3fb";
}

.mdi-key-plus:before {
	content: "\f3fc";
}

.mdi-key-remove:before {
	content: "\f3fd";
}

.mdi-key-variant:before {
	content: "\f3fe";
}

.mdi-keyboard:before {
	content: "\f3ff";
}

.mdi-keyboard-backspace:before {
	content: "\f400";
}

.mdi-keyboard-caps:before {
	content: "\f401";
}

.mdi-keyboard-close:before {
	content: "\f402";
}

.mdi-keyboard-off:before {
	content: "\f403";
}

.mdi-keyboard-return:before {
	content: "\f404";
}

.mdi-keyboard-tab:before {
	content: "\f405";
}

.mdi-keyboard-variant:before {
	content: "\f406";
}

.mdi-label:before {
	content: "\f407";
}

.mdi-label-outline:before {
	content: "\f408";
}

.mdi-lan:before {
	content: "\f409";
}

.mdi-lan-connect:before {
	content: "\f40a";
}

.mdi-lan-disconnect:before {
	content: "\f40b";
}

.mdi-lan-pending:before {
	content: "\f40c";
}

.mdi-language-csharp:before {
	content: "\f40d";
}

.mdi-language-css3:before {
	content: "\f40e";
}

.mdi-language-html5:before {
	content: "\f40f";
}

.mdi-language-javascript:before {
	content: "\f410";
}

.mdi-language-php:before {
	content: "\f411";
}

.mdi-language-python:before {
	content: "\f412";
}

.mdi-language-python-text:before {
	content: "\f413";
}

.mdi-laptop:before {
	content: "\f414";
}

.mdi-laptop-chromebook:before {
	content: "\f415";
}

.mdi-laptop-mac:before {
	content: "\f416";
}

.mdi-laptop-windows:before {
	content: "\f417";
}

.mdi-lastfm:before {
	content: "\f418";
}

.mdi-launch:before {
	content: "\f419";
}

.mdi-layers:before {
	content: "\f41a";
}

.mdi-layers-off:before {
	content: "\f41b";
}

.mdi-leaf:before {
	content: "\f41c";
}

.mdi-led-off:before {
	content: "\f41d";
}

.mdi-led-on:before {
	content: "\f41e";
}

.mdi-led-outline:before {
	content: "\f41f";
}

.mdi-led-variant-off:before {
	content: "\f420";
}

.mdi-led-variant-on:before {
	content: "\f421";
}

.mdi-led-variant-outline:before {
	content: "\f422";
}

.mdi-library:before {
	content: "\f423";
}

.mdi-library-books:before {
	content: "\f424";
}

.mdi-library-music:before {
	content: "\f425";
}

.mdi-library-plus:before {
	content: "\f426";
}

.mdi-lightbulb:before {
	content: "\f427";
}

.mdi-lightbulb-outline:before {
	content: "\f428";
}

.mdi-link:before {
	content: "\f429";
}

.mdi-link-off:before {
	content: "\f42a";
}

.mdi-link-variant:before {
	content: "\f42b";
}

.mdi-link-variant-off:before {
	content: "\f42c";
}

.mdi-linkedin:before {
	content: "\f42d";
}

.mdi-linkedin-box:before {
	content: "\f42e";
}

.mdi-linux:before {
	content: "\f42f";
}

.mdi-lock:before {
	content: "\f430";
}

.mdi-lock-open:before {
	content: "\f431";
}

.mdi-lock-open-outline:before {
	content: "\f432";
}

.mdi-lock-outline:before {
	content: "\f433";
}

.mdi-login:before {
	content: "\f434";
}

.mdi-logout:before {
	content: "\f435";
}

.mdi-looks:before {
	content: "\f436";
}

.mdi-loupe:before {
	content: "\f437";
}

.mdi-lumx:before {
	content: "\f438";
}

.mdi-magnet:before {
	content: "\f439";
}

.mdi-magnet-on:before {
	content: "\f43a";
}

.mdi-magnify:before {
	content: "\f43b";
}

.mdi-magnify-minus:before {
	content: "\f43c";
}

.mdi-magnify-plus:before {
	content: "\f43d";
}

.mdi-mail-ru:before {
	content: "\f43e";
}

.mdi-map:before {
	content: "\f43f";
}

.mdi-map-marker:before {
	content: "\f440";
}

.mdi-map-marker-circle:before {
	content: "\f441";
}

.mdi-map-marker-multiple:before {
	content: "\f442";
}

.mdi-map-marker-off:before {
	content: "\f443";
}

.mdi-map-marker-radius:before {
	content: "\f444";
}

.mdi-margin:before {
	content: "\f445";
}

.mdi-markdown:before {
	content: "\f446";
}

.mdi-marker-check:before {
	content: "\f447";
}

.mdi-martini:before {
	content: "\f448";
}

.mdi-material-ui:before {
	content: "\f449";
}

.mdi-math-compass:before {
	content: "\f44a";
}

.mdi-maxcdn:before {
	content: "\f44b";
}

.mdi-medium:before {
	content: "\f44c";
}

.mdi-memory:before {
	content: "\f44d";
}

.mdi-menu:before {
	content: "\f44e";
}

.mdi-menu-down:before {
	content: "\f44f";
}

.mdi-menu-left:before {
	content: "\f450";
}

.mdi-menu-right:before {
	content: "\f451";
}

.mdi-menu-up:before {
	content: "\f452";
}

.mdi-message:before {
	content: "\f453";
}

.mdi-message-alert:before {
	content: "\f454";
}

.mdi-message-draw:before {
	content: "\f455";
}

.mdi-message-image:before {
	content: "\f456";
}

.mdi-message-outline:before {
	content: "\f457";
}

.mdi-message-processing:before {
	content: "\f458";
}

.mdi-message-reply:before {
	content: "\f459";
}

.mdi-message-reply-text:before {
	content: "\f45a";
}

.mdi-message-text:before {
	content: "\f45b";
}

.mdi-message-text-outline:before {
	content: "\f45c";
}

.mdi-message-video:before {
	content: "\f45d";
}

.mdi-microphone:before {
	content: "\f45e";
}

.mdi-microphone-off:before {
	content: "\f45f";
}

.mdi-microphone-outline:before {
	content: "\f460";
}

.mdi-microphone-settings:before {
	content: "\f461";
}

.mdi-microphone-variant:before {
	content: "\f462";
}

.mdi-microphone-variant-off:before {
	content: "\f463";
}

.mdi-microsoft:before {
	content: "\f464";
}

.mdi-minus:before {
	content: "\f465";
}

.mdi-minus-box:before {
	content: "\f466";
}

.mdi-minus-circle:before {
	content: "\f467";
}

.mdi-minus-circle-outline:before {
	content: "\f468";
}

.mdi-minus-network:before {
	content: "\f469";
}

.mdi-monitor:before {
	content: "\f46a";
}

.mdi-monitor-multiple:before {
	content: "\f46b";
}

.mdi-more:before {
	content: "\f46c";
}

.mdi-motorbike:before {
	content: "\f46d";
}

.mdi-mouse:before {
	content: "\f46e";
}

.mdi-mouse-off:before {
	content: "\f46f";
}

.mdi-mouse-variant:before {
	content: "\f470";
}

.mdi-mouse-variant-off:before {
	content: "\f471";
}

.mdi-movie:before {
	content: "\f472";
}

.mdi-multiplication:before {
	content: "\f473";
}

.mdi-multiplication-box:before {
	content: "\f474";
}

.mdi-music-box:before {
	content: "\f475";
}

.mdi-music-box-outline:before {
	content: "\f476";
}

.mdi-music-circle:before {
	content: "\f477";
}

.mdi-music-note:before {
	content: "\f478";
}

.mdi-music-note-eighth:before {
	content: "\f479";
}

.mdi-music-note-half:before {
	content: "\f47a";
}

.mdi-music-note-off:before {
	content: "\f47b";
}

.mdi-music-note-quarter:before {
	content: "\f47c";
}

.mdi-music-note-sixteenth:before {
	content: "\f47d";
}

.mdi-music-note-whole:before {
	content: "\f47e";
}

.mdi-nature:before {
	content: "\f47f";
}

.mdi-nature-people:before {
	content: "\f480";
}

.mdi-navigation:before {
	content: "\f481";
}

.mdi-needle:before {
	content: "\f482";
}

.mdi-nest-protect:before {
	content: "\f483";
}

.mdi-nest-thermostat:before {
	content: "\f484";
}

.mdi-newspaper:before {
	content: "\f485";
}

.mdi-nfc:before {
	content: "\f486";
}

.mdi-nfc-tap:before {
	content: "\f487";
}

.mdi-nfc-variant:before {
	content: "\f488";
}

.mdi-nodejs:before {
	content: "\f489";
}

.mdi-note:before {
	content: "\f48a";
}

.mdi-note-outline:before {
	content: "\f48b";
}

.mdi-note-plus:before {
	content: "\f48c";
}

.mdi-note-plus-outline:before {
	content: "\f48d";
}

.mdi-note-text:before {
	content: "\f48e";
}

.mdi-notification-clear-all:before {
	content: "\f48f";
}

.mdi-numeric:before {
	content: "\f490";
}

.mdi-numeric-0-box:before {
	content: "\f491";
}

.mdi-numeric-0-box-multiple-outline:before {
	content: "\f492";
}

.mdi-numeric-0-box-outline:before {
	content: "\f493";
}

.mdi-numeric-1-box:before {
	content: "\f494";
}

.mdi-numeric-1-box-multiple-outline:before {
	content: "\f495";
}

.mdi-numeric-1-box-outline:before {
	content: "\f496";
}

.mdi-numeric-2-box:before {
	content: "\f497";
}

.mdi-numeric-2-box-multiple-outline:before {
	content: "\f498";
}

.mdi-numeric-2-box-outline:before {
	content: "\f499";
}

.mdi-numeric-3-box:before {
	content: "\f49a";
}

.mdi-numeric-3-box-multiple-outline:before {
	content: "\f49b";
}

.mdi-numeric-3-box-outline:before {
	content: "\f49c";
}

.mdi-numeric-4-box:before {
	content: "\f49d";
}

.mdi-numeric-4-box-multiple-outline:before {
	content: "\f49e";
}

.mdi-numeric-4-box-outline:before {
	content: "\f49f";
}

.mdi-numeric-5-box:before {
	content: "\f4a0";
}

.mdi-numeric-5-box-multiple-outline:before {
	content: "\f4a1";
}

.mdi-numeric-5-box-outline:before {
	content: "\f4a2";
}

.mdi-numeric-6-box:before {
	content: "\f4a3";
}

.mdi-numeric-6-box-multiple-outline:before {
	content: "\f4a4";
}

.mdi-numeric-6-box-outline:before {
	content: "\f4a5";
}

.mdi-numeric-7-box:before {
	content: "\f4a6";
}

.mdi-numeric-7-box-multiple-outline:before {
	content: "\f4a7";
}

.mdi-numeric-7-box-outline:before {
	content: "\f4a8";
}

.mdi-numeric-8-box:before {
	content: "\f4a9";
}

.mdi-numeric-8-box-multiple-outline:before {
	content: "\f4aa";
}

.mdi-numeric-8-box-outline:before {
	content: "\f4ab";
}

.mdi-numeric-9-box:before {
	content: "\f4ac";
}

.mdi-numeric-9-box-multiple-outline:before {
	content: "\f4ad";
}

.mdi-numeric-9-box-outline:before {
	content: "\f4ae";
}

.mdi-numeric-9-plus-box:before {
	content: "\f4af";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
	content: "\f4b0";
}

.mdi-numeric-9-plus-box-outline:before {
	content: "\f4b1";
}

.mdi-nutrition:before {
	content: "\f4b2";
}

.mdi-octagon:before {
	content: "\f4b3";
}

.mdi-octagon-outline:before {
	content: "\f4b4";
}

.mdi-odnoklassniki:before {
	content: "\f4b5";
}

.mdi-office:before {
	content: "\f4b6";
}

.mdi-oil:before {
	content: "\f4b7";
}

.mdi-oil-temperature:before {
	content: "\f4b8";
}

.mdi-omega:before {
	content: "\f4b9";
}

.mdi-onedrive:before {
	content: "\f4ba";
}

.mdi-open-in-app:before {
	content: "\f4bb";
}

.mdi-open-in-new:before {
	content: "\f4bc";
}

.mdi-opera:before {
	content: "\f4bd";
}

.mdi-ornament:before {
	content: "\f4be";
}

.mdi-ornament-variant:before {
	content: "\f4bf";
}

.mdi-outbox:before {
	content: "\f4c0";
}

.mdi-owl:before {
	content: "\f4c1";
}

.mdi-package:before {
	content: "\f4c2";
}

.mdi-package-down:before {
	content: "\f4c3";
}

.mdi-package-up:before {
	content: "\f4c4";
}

.mdi-package-variant:before {
	content: "\f4c5";
}

.mdi-package-variant-closed:before {
	content: "\f4c6";
}

.mdi-palette:before {
	content: "\f4c7";
}

.mdi-palette-advanced:before {
	content: "\f4c8";
}

.mdi-panda:before {
	content: "\f4c9";
}

.mdi-pandora:before {
	content: "\f4ca";
}

.mdi-panorama:before {
	content: "\f4cb";
}

.mdi-panorama-fisheye:before {
	content: "\f4cc";
}

.mdi-panorama-horizontal:before {
	content: "\f4cd";
}

.mdi-panorama-vertical:before {
	content: "\f4ce";
}

.mdi-panorama-wide-angle:before {
	content: "\f4cf";
}

.mdi-paper-cut-vertical:before {
	content: "\f4d0";
}

.mdi-paperclip:before {
	content: "\f4d1";
}

.mdi-parking:before {
	content: "\f4d2";
}

.mdi-pause:before {
	content: "\f4d3";
}

.mdi-pause-circle:before {
	content: "\f4d4";
}

.mdi-pause-circle-outline:before {
	content: "\f4d5";
}

.mdi-pause-octagon:before {
	content: "\f4d6";
}

.mdi-pause-octagon-outline:before {
	content: "\f4d7";
}

.mdi-paw:before {
	content: "\f4d8";
}

.mdi-pen:before {
	content: "\f4d9";
}

.mdi-pencil:before {
	content: "\f4da";
}

.mdi-pencil-box:before {
	content: "\f4db";
}

.mdi-pencil-box-outline:before {
	content: "\f4dc";
}

.mdi-pencil-lock:before {
	content: "\f4dd";
}

.mdi-pencil-off:before {
	content: "\f4de";
}

.mdi-percent:before {
	content: "\f4df";
}

.mdi-pharmacy:before {
	content: "\f4e0";
}

.mdi-phone:before {
	content: "\f4e1";
}

.mdi-phone-bluetooth:before {
	content: "\f4e2";
}

.mdi-phone-forward:before {
	content: "\f4e3";
}

.mdi-phone-hangup:before {
	content: "\f4e4";
}

.mdi-phone-in-talk:before {
	content: "\f4e5";
}

.mdi-phone-incoming:before {
	content: "\f4e6";
}

.mdi-phone-locked:before {
	content: "\f4e7";
}

.mdi-phone-log:before {
	content: "\f4e8";
}

.mdi-phone-missed:before {
	content: "\f4e9";
}

.mdi-phone-outgoing:before {
	content: "\f4ea";
}

.mdi-phone-paused:before {
	content: "\f4eb";
}

.mdi-phone-settings:before {
	content: "\f4ec";
}

.mdi-phone-voip:before {
	content: "\f4ed";
}

.mdi-pi:before {
	content: "\f4ee";
}

.mdi-pi-box:before {
	content: "\f4ef";
}

.mdi-pig:before {
	content: "\f4f0";
}

.mdi-pill:before {
	content: "\f4f1";
}

.mdi-pin:before {
	content: "\f4f2";
}

.mdi-pin-off:before {
	content: "\f4f3";
}

.mdi-pine-tree:before {
	content: "\f4f4";
}

.mdi-pine-tree-box:before {
	content: "\f4f5";
}

.mdi-pinterest:before {
	content: "\f4f6";
}

.mdi-pinterest-box:before {
	content: "\f4f7";
}

.mdi-pizza:before {
	content: "\f4f8";
}

.mdi-play:before {
	content: "\f4f9";
}

.mdi-play-box-outline:before {
	content: "\f4fa";
}

.mdi-play-circle:before {
	content: "\f4fb";
}

.mdi-play-circle-outline:before {
	content: "\f4fc";
}

.mdi-play-pause:before {
	content: "\f4fd";
}

.mdi-play-protected-content:before {
	content: "\f4fe";
}

.mdi-playlist-minus:before {
	content: "\f4ff";
}

.mdi-playlist-play:before {
	content: "\f500";
}

.mdi-playlist-plus:before {
	content: "\f501";
}

.mdi-playlist-remove:before {
	content: "\f502";
}

.mdi-playstation:before {
	content: "\f503";
}

.mdi-plus:before {
	content: "\f504";
}

.mdi-plus-box:before {
	content: "\f505";
}

.mdi-plus-circle:before {
	content: "\f506";
}

.mdi-plus-circle-multiple-outline:before {
	content: "\f507";
}

.mdi-plus-circle-outline:before {
	content: "\f508";
}

.mdi-plus-network:before {
	content: "\f509";
}

.mdi-plus-one:before {
	content: "\f50a";
}

.mdi-pocket:before {
	content: "\f50b";
}

.mdi-pokeball:before {
	content: "\f50c";
}

.mdi-polaroid:before {
	content: "\f50d";
}

.mdi-poll:before {
	content: "\f50e";
}

.mdi-poll-box:before {
	content: "\f50f";
}

.mdi-polymer:before {
	content: "\f510";
}

.mdi-popcorn:before {
	content: "\f511";
}

.mdi-pound:before {
	content: "\f512";
}

.mdi-pound-box:before {
	content: "\f513";
}

.mdi-power:before {
	content: "\f514";
}

.mdi-power-settings:before {
	content: "\f515";
}

.mdi-power-socket:before {
	content: "\f516";
}

.mdi-presentation:before {
	content: "\f517";
}

.mdi-presentation-play:before {
	content: "\f518";
}

.mdi-printer:before {
	content: "\f519";
}

.mdi-printer-3d:before {
	content: "\f51a";
}

.mdi-printer-alert:before {
	content: "\f51b";
}

.mdi-professional-hexagon:before {
	content: "\f51c";
}

.mdi-projector:before {
	content: "\f51d";
}

.mdi-projector-screen:before {
	content: "\f51e";
}

.mdi-pulse:before {
	content: "\f51f";
}

.mdi-puzzle:before {
	content: "\f520";
}

.mdi-qrcode:before {
	content: "\f521";
}

.mdi-qrcode-scan:before {
	content: "\f522";
}

.mdi-quadcopter:before {
	content: "\f523";
}

.mdi-quality-high:before {
	content: "\f524";
}

.mdi-quicktime:before {
	content: "\f525";
}

.mdi-radar:before {
	content: "\f526";
}

.mdi-radiator:before {
	content: "\f527";
}

.mdi-radio:before {
	content: "\f528";
}

.mdi-radio-handheld:before {
	content: "\f529";
}

.mdi-radio-tower:before {
	content: "\f52a";
}

.mdi-radioactive:before {
	content: "\f52b";
}

.mdi-radiobox-blank:before {
	content: "\f52c";
}

.mdi-radiobox-marked:before {
	content: "\f52d";
}

.mdi-raspberrypi:before {
	content: "\f52e";
}

.mdi-ray-end:before {
	content: "\f52f";
}

.mdi-ray-end-arrow:before {
	content: "\f530";
}

.mdi-ray-start:before {
	content: "\f531";
}

.mdi-ray-start-arrow:before {
	content: "\f532";
}

.mdi-ray-start-end:before {
	content: "\f533";
}

.mdi-ray-vertex:before {
	content: "\f534";
}

.mdi-rdio:before {
	content: "\f535";
}

.mdi-read:before {
	content: "\f536";
}

.mdi-readability:before {
	content: "\f537";
}

.mdi-receipt:before {
	content: "\f538";
}

.mdi-record:before {
	content: "\f539";
}

.mdi-record-rec:before {
	content: "\f53a";
}

.mdi-recycle:before {
	content: "\f53b";
}

.mdi-reddit:before {
	content: "\f53c";
}

.mdi-redo:before {
	content: "\f53d";
}

.mdi-redo-variant:before {
	content: "\f53e";
}

.mdi-refresh:before {
	content: "\f53f";
}

.mdi-regex:before {
	content: "\f540";
}

.mdi-relative-scale:before {
	content: "\f541";
}

.mdi-reload:before {
	content: "\f542";
}

.mdi-remote:before {
	content: "\f543";
}

.mdi-rename-box:before {
	content: "\f544";
}

.mdi-repeat:before {
	content: "\f545";
}

.mdi-repeat-off:before {
	content: "\f546";
}

.mdi-repeat-once:before {
	content: "\f547";
}

.mdi-replay:before {
	content: "\f548";
}

.mdi-reply:before {
	content: "\f549";
}

.mdi-reply-all:before {
	content: "\f54a";
}

.mdi-reproduction:before {
	content: "\f54b";
}

.mdi-resize-bottom-right:before {
	content: "\f54c";
}

.mdi-responsive:before {
	content: "\f54d";
}

.mdi-rewind:before {
	content: "\f54e";
}

.mdi-ribbon:before {
	content: "\f54f";
}

.mdi-road:before {
	content: "\f550";
}

.mdi-road-variant:before {
	content: "\f551";
}

.mdi-rocket:before {
	content: "\f552";
}

.mdi-rotate-3d:before {
	content: "\f553";
}

.mdi-rotate-left:before {
	content: "\f554";
}

.mdi-rotate-left-variant:before {
	content: "\f555";
}

.mdi-rotate-right:before {
	content: "\f556";
}

.mdi-rotate-right-variant:before {
	content: "\f557";
}

.mdi-router-wireless:before {
	content: "\f558";
}

.mdi-routes:before {
	content: "\f559";
}

.mdi-rss:before {
	content: "\f55a";
}

.mdi-rss-box:before {
	content: "\f55b";
}

.mdi-ruler:before {
	content: "\f55c";
}

.mdi-run:before {
	content: "\f55d";
}

.mdi-sale:before {
	content: "\f55e";
}

.mdi-satellite:before {
	content: "\f55f";
}

.mdi-satellite-variant:before {
	content: "\f560";
}

.mdi-scale:before {
	content: "\f561";
}

.mdi-scale-bathroom:before {
	content: "\f562";
}

.mdi-school:before {
	content: "\f563";
}

.mdi-screen-rotation:before {
	content: "\f564";
}

.mdi-screen-rotation-lock:before {
	content: "\f565";
}

.mdi-screwdriver:before {
	content: "\f566";
}

.mdi-script:before {
	content: "\f567";
}

.mdi-sd:before {
	content: "\f568";
}

.mdi-seal:before {
	content: "\f569";
}

.mdi-seat-flat:before {
	content: "\f56a";
}

.mdi-seat-flat-angled:before {
	content: "\f56b";
}

.mdi-seat-individual-suite:before {
	content: "\f56c";
}

.mdi-seat-legroom-extra:before {
	content: "\f56d";
}

.mdi-seat-legroom-normal:before {
	content: "\f56e";
}

.mdi-seat-legroom-reduced:before {
	content: "\f56f";
}

.mdi-seat-recline-extra:before {
	content: "\f570";
}

.mdi-seat-recline-normal:before {
	content: "\f571";
}

.mdi-security:before {
	content: "\f572";
}

.mdi-security-network:before {
	content: "\f573";
}

.mdi-select:before {
	content: "\f574";
}

.mdi-select-all:before {
	content: "\f575";
}

.mdi-select-inverse:before {
	content: "\f576";
}

.mdi-select-off:before {
	content: "\f577";
}

.mdi-selection:before {
	content: "\f578";
}

.mdi-send:before {
	content: "\f579";
}

.mdi-server:before {
	content: "\f57a";
}

.mdi-server-minus:before {
	content: "\f57b";
}

.mdi-server-network:before {
	content: "\f57c";
}

.mdi-server-network-off:before {
	content: "\f57d";
}

.mdi-server-off:before {
	content: "\f57e";
}

.mdi-server-plus:before {
	content: "\f57f";
}

.mdi-server-remove:before {
	content: "\f580";
}

.mdi-server-security:before {
	content: "\f581";
}

.mdi-settings:before {
	content: "\f582";
}

.mdi-settings-box:before {
	content: "\f583";
}

.mdi-shape-plus:before {
	content: "\f584";
}

.mdi-share:before {
	content: "\f585";
}

.mdi-share-variant:before {
	content: "\f586";
}

.mdi-shield:before {
	content: "\f587";
}

.mdi-shield-outline:before {
	content: "\f588";
}

.mdi-shopping:before {
	content: "\f589";
}

.mdi-shopping-music:before {
	content: "\f58a";
}

.mdi-shredder:before {
	content: "\f58b";
}

.mdi-shuffle:before {
	content: "\f58c";
}

.mdi-shuffle-disabled:before {
	content: "\f58d";
}

.mdi-shuffle-variant:before {
	content: "\f58e";
}

.mdi-sigma:before {
	content: "\f58f";
}

.mdi-sign-caution:before {
	content: "\f590";
}

.mdi-signal:before {
	content: "\f591";
}

.mdi-silverware:before {
	content: "\f592";
}

.mdi-silverware-fork:before {
	content: "\f593";
}

.mdi-silverware-spoon:before {
	content: "\f594";
}

.mdi-silverware-variant:before {
	content: "\f595";
}

.mdi-sim:before {
	content: "\f596";
}

.mdi-sim-alert:before {
	content: "\f597";
}

.mdi-sim-off:before {
	content: "\f598";
}

.mdi-sitemap:before {
	content: "\f599";
}

.mdi-skip-backward:before {
	content: "\f59a";
}

.mdi-skip-forward:before {
	content: "\f59b";
}

.mdi-skip-next:before {
	content: "\f59c";
}

.mdi-skip-previous:before {
	content: "\f59d";
}

.mdi-skype:before {
	content: "\f59e";
}

.mdi-skype-business:before {
	content: "\f59f";
}

.mdi-slack:before {
	content: "\f5a0";
}

.mdi-sleep:before {
	content: "\f5a1";
}

.mdi-sleep-off:before {
	content: "\f5a2";
}

.mdi-smoking:before {
	content: "\f5a3";
}

.mdi-smoking-off:before {
	content: "\f5a4";
}

.mdi-snapchat:before {
	content: "\f5a5";
}

.mdi-snowman:before {
	content: "\f5a6";
}

.mdi-sofa:before {
	content: "\f5a7";
}

.mdi-sort:before {
	content: "\f5a8";
}

.mdi-sort-alphabetical:before {
	content: "\f5a9";
}

.mdi-sort-ascending:before {
	content: "\f5aa";
}

.mdi-sort-descending:before {
	content: "\f5ab";
}

.mdi-sort-numeric:before {
	content: "\f5ac";
}

.mdi-sort-variant:before {
	content: "\f5ad";
}

.mdi-soundcloud:before {
	content: "\f5ae";
}

.mdi-source-fork:before {
	content: "\f5af";
}

.mdi-source-pull:before {
	content: "\f5b0";
}

.mdi-speaker:before {
	content: "\f5b1";
}

.mdi-speaker-off:before {
	content: "\f5b2";
}

.mdi-speedometer:before {
	content: "\f5b3";
}

.mdi-spellcheck:before {
	content: "\f5b4";
}

.mdi-spotify:before {
	content: "\f5b5";
}

.mdi-spotlight:before {
	content: "\f5b6";
}

.mdi-spotlight-beam:before {
	content: "\f5b7";
}

.mdi-square-inc:before {
	content: "\f5b8";
}

.mdi-square-inc-cash:before {
	content: "\f5b9";
}

.mdi-stackoverflow:before {
	content: "\f5ba";
}

.mdi-stairs:before {
	content: "\f5bb";
}

.mdi-star:before {
	content: "\f5bc";
}

.mdi-star-circle:before {
	content: "\f5bd";
}

.mdi-star-half:before {
	content: "\f5be";
}

.mdi-star-off:before {
	content: "\f5bf";
}

.mdi-star-outline:before {
	content: "\f5c0";
}

.mdi-steam:before {
	content: "\f5c1";
}

.mdi-steering:before {
	content: "\f5c2";
}

.mdi-step-backward:before {
	content: "\f5c3";
}

.mdi-step-backward-2:before {
	content: "\f5c4";
}

.mdi-step-forward:before {
	content: "\f5c5";
}

.mdi-step-forward-2:before {
	content: "\f5c6";
}

.mdi-stethoscope:before {
	content: "\f5c7";
}

.mdi-stocking:before {
	content: "\f5c8";
}

.mdi-stop:before {
	content: "\f5c9";
}

.mdi-store:before {
	content: "\f5ca";
}

.mdi-store-24-hour:before {
	content: "\f5cb";
}

.mdi-stove:before {
	content: "\f5cc";
}

.mdi-subway:before {
	content: "\f5cd";
}

.mdi-sunglasses:before {
	content: "\f5ce";
}

.mdi-swap-horizontal:before {
	content: "\f5cf";
}

.mdi-swap-vertical:before {
	content: "\f5d0";
}

.mdi-swim:before {
	content: "\f5d1";
}

.mdi-switch:before {
	content: "\f5d2";
}

.mdi-sword:before {
	content: "\f5d3";
}

.mdi-sync:before {
	content: "\f5d4";
}

.mdi-sync-alert:before {
	content: "\f5d5";
}

.mdi-sync-off:before {
	content: "\f5d6";
}

.mdi-tab:before {
	content: "\f5d7";
}

.mdi-tab-unselected:before {
	content: "\f5d8";
}

.mdi-table:before {
	content: "\f5d9";
}

.mdi-table-column-plus-after:before {
	content: "\f5da";
}

.mdi-table-column-plus-before:before {
	content: "\f5db";
}

.mdi-table-column-remove:before {
	content: "\f5dc";
}

.mdi-table-column-width:before {
	content: "\f5dd";
}

.mdi-table-edit:before {
	content: "\f5de";
}

.mdi-table-large:before {
	content: "\f5df";
}

.mdi-table-row-height:before {
	content: "\f5e0";
}

.mdi-table-row-plus-after:before {
	content: "\f5e1";
}

.mdi-table-row-plus-before:before {
	content: "\f5e2";
}

.mdi-table-row-remove:before {
	content: "\f5e3";
}

.mdi-tablet:before {
	content: "\f5e4";
}

.mdi-tablet-android:before {
	content: "\f5e5";
}

.mdi-tablet-ipad:before {
	content: "\f5e6";
}

.mdi-tag:before {
	content: "\f5e7";
}

.mdi-tag-faces:before {
	content: "\f5e8";
}

.mdi-tag-multiple:before {
	content: "\f5e9";
}

.mdi-tag-outline:before {
	content: "\f5ea";
}

.mdi-tag-text-outline:before {
	content: "\f5eb";
}

.mdi-target:before {
	content: "\f5ec";
}

.mdi-taxi:before {
	content: "\f5ed";
}

.mdi-teamviewer:before {
	content: "\f5ee";
}

.mdi-telegram:before {
	content: "\f5ef";
}

.mdi-television:before {
	content: "\f5f0";
}

.mdi-television-guide:before {
	content: "\f5f1";
}

.mdi-temperature-celsius:before {
	content: "\f5f2";
}

.mdi-temperature-fahrenheit:before {
	content: "\f5f3";
}

.mdi-temperature-kelvin:before {
	content: "\f5f4";
}

.mdi-tennis:before {
	content: "\f5f5";
}

.mdi-tent:before {
	content: "\f5f6";
}

.mdi-terrain:before {
	content: "\f5f7";
}

.mdi-text-to-speech:before {
	content: "\f5f8";
}

.mdi-text-to-speech-off:before {
	content: "\f5f9";
}

.mdi-texture:before {
	content: "\f5fa";
}

.mdi-theater:before {
	content: "\f5fb";
}

.mdi-theme-light-dark:before {
	content: "\f5fc";
}

.mdi-thermometer:before {
	content: "\f5fd";
}

.mdi-thermometer-lines:before {
	content: "\f5fe";
}

.mdi-thumb-down:before {
	content: "\f5ff";
}

.mdi-thumb-down-outline:before {
	content: "\f600";
}

.mdi-thumb-up:before {
	content: "\f601";
}

.mdi-thumb-up-outline:before {
	content: "\f602";
}

.mdi-thumbs-up-down:before {
	content: "\f603";
}

.mdi-ticket:before {
	content: "\f604";
}

.mdi-ticket-account:before {
	content: "\f605";
}

.mdi-ticket-confirmation:before {
	content: "\f606";
}

.mdi-tie:before {
	content: "\f607";
}

.mdi-timelapse:before {
	content: "\f608";
}

.mdi-timer:before {
	content: "\f609";
}

.mdi-timer-10:before {
	content: "\f60a";
}

.mdi-timer-3:before {
	content: "\f60b";
}

.mdi-timer-off:before {
	content: "\f60c";
}

.mdi-timer-sand:before {
	content: "\f60d";
}

.mdi-timetable:before {
	content: "\f60e";
}

.mdi-toggle-switch:before {
	content: "\f60f";
}

.mdi-toggle-switch-off:before {
	content: "\f610";
}

.mdi-tooltip:before {
	content: "\f611";
}

.mdi-tooltip-edit:before {
	content: "\f612";
}

.mdi-tooltip-image:before {
	content: "\f613";
}

.mdi-tooltip-outline:before {
	content: "\f614";
}

.mdi-tooltip-outline-plus:before {
	content: "\f615";
}

.mdi-tooltip-text:before {
	content: "\f616";
}

.mdi-tor:before {
	content: "\f617";
}

.mdi-traffic-light:before {
	content: "\f618";
}

.mdi-train:before {
	content: "\f619";
}

.mdi-tram:before {
	content: "\f61a";
}

.mdi-transcribe:before {
	content: "\f61b";
}

.mdi-transcribe-close:before {
	content: "\f61c";
}

.mdi-transfer:before {
	content: "\f61d";
}

.mdi-tree:before {
	content: "\f61e";
}

.mdi-trello:before {
	content: "\f61f";
}

.mdi-trending-down:before {
	content: "\f620";
}

.mdi-trending-neutral:before {
	content: "\f621";
}

.mdi-trending-up:before {
	content: "\f622";
}

.mdi-triangle:before {
	content: "\f623";
}

.mdi-triangle-outline:before {
	content: "\f624";
}

.mdi-trophy:before {
	content: "\f625";
}

.mdi-trophy-award:before {
	content: "\f626";
}

.mdi-trophy-outline:before {
	content: "\f627";
}

.mdi-trophy-variant:before {
	content: "\f628";
}

.mdi-trophy-variant-outline:before {
	content: "\f629";
}

.mdi-truck:before {
	content: "\f62a";
}

.mdi-truck-delivery:before {
	content: "\f62b";
}

.mdi-tshirt-crew:before {
	content: "\f62c";
}

.mdi-tshirt-v:before {
	content: "\f62d";
}

.mdi-tumblr:before {
	content: "\f62e";
}

.mdi-tumblr-reblog:before {
	content: "\f62f";
}

.mdi-twitch:before {
	content: "\f630";
}

.mdi-twitter:before {
	content: "\f631";
}

.mdi-twitter-box:before {
	content: "\f632";
}

.mdi-twitter-circle:before {
	content: "\f633";
}

.mdi-twitter-retweet:before {
	content: "\f634";
}

.mdi-ubuntu:before {
	content: "\f635";
}

.mdi-umbraco:before {
	content: "\f636";
}

.mdi-umbrella:before {
	content: "\f637";
}

.mdi-umbrella-outline:before {
	content: "\f638";
}

.mdi-undo:before {
	content: "\f639";
}

.mdi-undo-variant:before {
	content: "\f63a";
}

.mdi-unfold-less:before {
	content: "\f63b";
}

.mdi-unfold-more:before {
	content: "\f63c";
}

.mdi-ungroup:before {
	content: "\f63d";
}

.mdi-untappd:before {
	content: "\f63e";
}

.mdi-upload:before {
	content: "\f63f";
}

.mdi-usb:before {
	content: "\f640";
}

.mdi-vector-arrange-above:before {
	content: "\f641";
}

.mdi-vector-arrange-below:before {
	content: "\f642";
}

.mdi-vector-circle:before {
	content: "\f643";
}

.mdi-vector-circle-variant:before {
	content: "\f644";
}

.mdi-vector-combine:before {
	content: "\f645";
}

.mdi-vector-curve:before {
	content: "\f646";
}

.mdi-vector-difference:before {
	content: "\f647";
}

.mdi-vector-difference-ab:before {
	content: "\f648";
}

.mdi-vector-difference-ba:before {
	content: "\f649";
}

.mdi-vector-intersection:before {
	content: "\f64a";
}

.mdi-vector-line:before {
	content: "\f64b";
}

.mdi-vector-point:before {
	content: "\f64c";
}

.mdi-vector-polygon:before {
	content: "\f64d";
}

.mdi-vector-polyline:before {
	content: "\f64e";
}

.mdi-vector-selection:before {
	content: "\f64f";
}

.mdi-vector-square:before {
	content: "\f650";
}

.mdi-vector-triangle:before {
	content: "\f651";
}

.mdi-vector-union:before {
	content: "\f652";
}

.mdi-verified:before {
	content: "\f653";
}

.mdi-vibrate:before {
	content: "\f654";
}

.mdi-video:before {
	content: "\f655";
}

.mdi-video-off:before {
	content: "\f656";
}

.mdi-video-switch:before {
	content: "\f657";
}

.mdi-view-agenda:before {
	content: "\f658";
}

.mdi-view-array:before {
	content: "\f659";
}

.mdi-view-carousel:before {
	content: "\f65a";
}

.mdi-view-column:before {
	content: "\f65b";
}

.mdi-view-dashboard:before {
	content: "\f65c";
}

.mdi-view-day:before {
	content: "\f65d";
}

.mdi-view-grid:before {
	content: "\f65e";
}

.mdi-view-headline:before {
	content: "\f65f";
}

.mdi-view-list:before {
	content: "\f660";
}

.mdi-view-module:before {
	content: "\f661";
}

.mdi-view-quilt:before {
	content: "\f662";
}

.mdi-view-stream:before {
	content: "\f663";
}

.mdi-view-week:before {
	content: "\f664";
}

.mdi-vimeo:before {
	content: "\f665";
}

.mdi-vine:before {
	content: "\f666";
}

.mdi-vk:before {
	content: "\f667";
}

.mdi-vk-box:before {
	content: "\f668";
}

.mdi-vk-circle:before {
	content: "\f669";
}

.mdi-voicemail:before {
	content: "\f66a";
}

.mdi-volume-high:before {
	content: "\f66b";
}

.mdi-volume-low:before {
	content: "\f66c";
}

.mdi-volume-medium:before {
	content: "\f66d";
}

.mdi-volume-off:before {
	content: "\f66e";
}

.mdi-vpn:before {
	content: "\f66f";
}

.mdi-walk:before {
	content: "\f670";
}

.mdi-wallet:before {
	content: "\f671";
}

.mdi-wallet-giftcard:before {
	content: "\f672";
}

.mdi-wallet-membership:before {
	content: "\f673";
}

.mdi-wallet-travel:before {
	content: "\f674";
}

.mdi-wan:before {
	content: "\f675";
}

.mdi-watch:before {
	content: "\f676";
}

.mdi-watch-export:before {
	content: "\f677";
}

.mdi-watch-import:before {
	content: "\f678";
}

.mdi-water:before {
	content: "\f679";
}

.mdi-water-off:before {
	content: "\f67a";
}

.mdi-water-percent:before {
	content: "\f67b";
}

.mdi-water-pump:before {
	content: "\f67c";
}

.mdi-weather-cloudy:before {
	content: "\f67d";
}

.mdi-weather-fog:before {
	content: "\f67e";
}

.mdi-weather-hail:before {
	content: "\f67f";
}

.mdi-weather-lightning:before {
	content: "\f680";
}

.mdi-weather-night:before {
	content: "\f681";
}

.mdi-weather-partlycloudy:before {
	content: "\f682";
}

.mdi-weather-pouring:before {
	content: "\f683";
}

.mdi-weather-rainy:before {
	content: "\f684";
}

.mdi-weather-snowy:before {
	content: "\f685";
}

.mdi-weather-sunny:before {
	content: "\f686";
}

.mdi-weather-sunset:before {
	content: "\f687";
}

.mdi-weather-sunset-down:before {
	content: "\f688";
}

.mdi-weather-sunset-up:before {
	content: "\f689";
}

.mdi-weather-windy:before {
	content: "\f68a";
}

.mdi-weather-windy-variant:before {
	content: "\f68b";
}

.mdi-web:before {
	content: "\f68c";
}

.mdi-webcam:before {
	content: "\f68d";
}

.mdi-weight:before {
	content: "\f68e";
}

.mdi-weight-kilogram:before {
	content: "\f68f";
}

.mdi-whatsapp:before {
	content: "\f690";
}

.mdi-wheelchair-accessibility:before {
	content: "\f691";
}

.mdi-white-balance-auto:before {
	content: "\f692";
}

.mdi-white-balance-incandescent:before {
	content: "\f693";
}

.mdi-white-balance-irradescent:before {
	content: "\f694";
}

.mdi-white-balance-sunny:before {
	content: "\f695";
}

.mdi-wifi:before {
	content: "\f696";
}

.mdi-wifi-off:before {
	content: "\f697";
}

.mdi-wii:before {
	content: "\f698";
}

.mdi-wikipedia:before {
	content: "\f699";
}

.mdi-window-close:before {
	content: "\f69a";
}

.mdi-window-closed:before {
	content: "\f69b";
}

.mdi-window-maximize:before {
	content: "\f69c";
}

.mdi-window-minimize:before {
	content: "\f69d";
}

.mdi-window-open:before {
	content: "\f69e";
}

.mdi-window-restore:before {
	content: "\f69f";
}

.mdi-windows:before {
	content: "\f6a0";
}

.mdi-wordpress:before {
	content: "\f6a1";
}

.mdi-worker:before {
	content: "\f6a2";
}

.mdi-wrap:before {
	content: "\f6a3";
}

.mdi-wrench:before {
	content: "\f6a4";
}

.mdi-wunderlist:before {
	content: "\f6a5";
}

.mdi-xbox:before {
	content: "\f6a6";
}

.mdi-xbox-controller:before {
	content: "\f6a7";
}

.mdi-xbox-controller-off:before {
	content: "\f6a8";
}

.mdi-xda:before {
	content: "\f6a9";
}

.mdi-xing:before {
	content: "\f6aa";
}

.mdi-xing-box:before {
	content: "\f6ab";
}

.mdi-xing-circle:before {
	content: "\f6ac";
}

.mdi-xml:before {
	content: "\f6ad";
}

.mdi-yeast:before {
	content: "\f6ae";
}

.mdi-yelp:before {
	content: "\f6af";
}

.mdi-youtube-play:before {
	content: "\f6b0";
}

.mdi-zip-box:before {
	content: "\f6b1";
}

.mdi-18px {
	font-size: 18px;
}

.mdi-24px {
	font-size: 24px;
}

.mdi-36px {
	font-size: 36px;
}

.mdi-48px {
	font-size: 48px;
}

.mdi-dark {
	color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive {
	color: rgba(0, 0, 0, 0.26);
}

.mdi-light {
	color: white;
}

.mdi-light.mdi-inactive {
	color: rgba(255, 255, 255, 0.3);
}

/**
 * Title:  Bigmug Line Flaticon Pack
 * Author: Catalin Fertu
 * Source: http://www.flaticon.com/packs/bigmug-line
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */
@font-face {
	font-family: "fl-bigmug-line";
	src: url("../fonts/fl-bigmug-line.eot");
	src: url("../fonts/fl-bigmug-line.eot#iefix") format("embedded-opentype"), url("../fonts/fl-bigmug-line.woff") format("woff"), url("../fonts/fl-bigmug-line.ttf") format("truetype"), url("../fonts/fl-bigmug-line.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-bigmug-line-ico,
[class^="fl-bigmug-line-"]:before, [class*=" fl-bigmug-line-"]:before,
[class^="fl-bigmug-line-"]:after, [class*=" fl-bigmug-line-"]:after {
	font-family: 'fl-bigmug-line';
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-bigmug-line-add137:before {
	content: "\e000";
}

.fl-bigmug-line-add139:before {
	content: "\e001";
}

.fl-bigmug-line-add149:before {
	content: "\e002";
}

.fl-bigmug-line-airplane86:before {
	content: "\e003";
}

.fl-bigmug-line-alarm31:before {
	content: "\e004";
}

.fl-bigmug-line-arrow592:before {
	content: "\e005";
}

.fl-bigmug-line-attach8:before {
	content: "\e006";
}

.fl-bigmug-line-attachment15:before {
	content: "\e007";
}

.fl-bigmug-line-audio46:before {
	content: "\e008";
}

.fl-bigmug-line-back44:before {
	content: "\e009";
}

.fl-bigmug-line-back46:before {
	content: "\e00a";
}

.fl-bigmug-line-big104:before {
	content: "\e00b";
}

.fl-bigmug-line-book188:before {
	content: "\e00c";
}

.fl-bigmug-line-bookmark28:before {
	content: "\e00d";
}

.fl-bigmug-line-bottle34:before {
	content: "\e00e";
}

.fl-bigmug-line-button5:before {
	content: "\e00f";
}

.fl-bigmug-line-buttons5:before {
	content: "\e010";
}

.fl-bigmug-line-cellphone55:before {
	content: "\e011";
}

.fl-bigmug-line-cellular9:before {
	content: "\e012";
}

.fl-bigmug-line-center10:before {
	content: "\e013";
}

.fl-bigmug-line-chat51:before {
	content: "\e014";
}

.fl-bigmug-line-chat55:before {
	content: "\e015";
}

.fl-bigmug-line-checkmark14:before {
	content: "\e016";
}

.fl-bigmug-line-checkmark15:before {
	content: "\e017";
}

.fl-bigmug-line-checkmark16:before {
	content: "\e018";
}

.fl-bigmug-line-circular220:before {
	content: "\e019";
}

.fl-bigmug-line-circular224:before {
	content: "\e01a";
}

.fl-bigmug-line-circular228:before {
	content: "\e01b";
}

.fl-bigmug-line-circular229:before {
	content: "\e01c";
}

.fl-bigmug-line-clipboard68:before {
	content: "\e01d";
}

.fl-bigmug-line-close42:before {
	content: "\e01e";
}

.fl-bigmug-line-cloud255:before {
	content: "\e01f";
}

.fl-bigmug-line-cloud260:before {
	content: "\e020";
}

.fl-bigmug-line-cocktail26:before {
	content: "\e021";
}

.fl-bigmug-line-code30:before {
	content: "\e022";
}

.fl-bigmug-line-collapse5:before {
	content: "\e023";
}

.fl-bigmug-line-comment45:before {
	content: "\e024";
}

.fl-bigmug-line-compass80:before {
	content: "\e025";
}

.fl-bigmug-line-contract5:before {
	content: "\e026";
}

.fl-bigmug-line-copy23:before {
	content: "\e027";
}

.fl-bigmug-line-crescent23:before {
	content: "\e028";
}

.fl-bigmug-line-cropping1:before {
	content: "\e029";
}

.fl-bigmug-line-cross81:before {
	content: "\e02a";
}

.fl-bigmug-line-cross83:before {
	content: "\e02b";
}

.fl-bigmug-line-cube29:before {
	content: "\e02c";
}

.fl-bigmug-line-double97:before {
	content: "\e02d";
}

.fl-bigmug-line-double98:before {
	content: "\e02e";
}

.fl-bigmug-line-double99:before {
	content: "\e02f";
}

.fl-bigmug-line-down55:before {
	content: "\e030";
}

.fl-bigmug-line-down56:before {
	content: "\e031";
}

.fl-bigmug-line-down58:before {
	content: "\e032";
}

.fl-bigmug-line-down59:before {
	content: "\e033";
}

.fl-bigmug-line-down64:before {
	content: "\e034";
}

.fl-bigmug-line-download136:before {
	content: "\e035";
}

.fl-bigmug-line-download142:before {
	content: "\e036";
}

.fl-bigmug-line-download146:before {
	content: "\e037";
}

.fl-bigmug-line-download147:before {
	content: "\e038";
}

.fl-bigmug-line-download148:before {
	content: "\e039";
}

.fl-bigmug-line-electrical17:before {
	content: "\e03a";
}

.fl-bigmug-line-electronic57:before {
	content: "\e03b";
}

.fl-bigmug-line-email64:before {
	content: "\e03c";
}

.fl-bigmug-line-email67:before {
	content: "\e03d";
}

.fl-bigmug-line-equalization3:before {
	content: "\e03e";
}

.fl-bigmug-line-equalizer26:before {
	content: "\e03f";
}

.fl-bigmug-line-event6:before {
	content: "\e040";
}

.fl-bigmug-line-expand25:before {
	content: "\e041";
}

.fl-bigmug-line-expanding2:before {
	content: "\e042";
}

.fl-bigmug-line-fast33:before {
	content: "\e043";
}

.fl-bigmug-line-favourites5:before {
	content: "\e044";
}

.fl-bigmug-line-file68:before {
	content: "\e045";
}

.fl-bigmug-line-file69:before {
	content: "\e046";
}

.fl-bigmug-line-film57:before {
	content: "\e047";
}

.fl-bigmug-line-flag53:before {
	content: "\e048";
}

.fl-bigmug-line-fog10:before {
	content: "\e049";
}

.fl-bigmug-line-foggy3:before {
	content: "\e04a";
}

.fl-bigmug-line-folder173:before {
	content: "\e04b";
}

.fl-bigmug-line-fork34:before {
	content: "\e04c";
}

.fl-bigmug-line-four87:before {
	content: "\e04d";
}

.fl-bigmug-line-full40:before {
	content: "\e04e";
}

.fl-bigmug-line-games32:before {
	content: "\e04f";
}

.fl-bigmug-line-gear30:before {
	content: "\e050";
}

.fl-bigmug-line-giftbox54:before {
	content: "\e051";
}

.fl-bigmug-line-graphical8:before {
	content: "\e052";
}

.fl-bigmug-line-headphones32:before {
	content: "\e053";
}

.fl-bigmug-line-hot67:before {
	content: "\e054";
}

.fl-bigmug-line-images21:before {
	content: "\e055";
}

.fl-bigmug-line-ink12:before {
	content: "\e056";
}

.fl-bigmug-line-label25:before {
	content: "\e057";
}

.fl-bigmug-line-left144:before {
	content: "\e058";
}

.fl-bigmug-line-left145:before {
	content: "\e059";
}

.fl-bigmug-line-left146:before {
	content: "\e05a";
}

.fl-bigmug-line-left148:before {
	content: "\e05b";
}

.fl-bigmug-line-left152:before {
	content: "\e05c";
}

.fl-bigmug-line-left153:before {
	content: "\e05d";
}

.fl-bigmug-line-left158:before {
	content: "\e05e";
}

.fl-bigmug-line-left159:before {
	content: "\e05f";
}

.fl-bigmug-line-like51:before {
	content: "\e060";
}

.fl-bigmug-line-link52:before {
	content: "\e061";
}

.fl-bigmug-line-list63:before {
	content: "\e062";
}

.fl-bigmug-line-list65:before {
	content: "\e063";
}

.fl-bigmug-line-lock64:before {
	content: "\e064";
}

.fl-bigmug-line-login12:before {
	content: "\e065";
}

.fl-bigmug-line-login9:before {
	content: "\e066";
}

.fl-bigmug-line-map87:before {
	content: "\e067";
}

.fl-bigmug-line-megaphone11:before {
	content: "\e068";
}

.fl-bigmug-line-men25:before {
	content: "\e069";
}

.fl-bigmug-line-menu40:before {
	content: "\e06a";
}

.fl-bigmug-line-menu41:before {
	content: "\e06b";
}

.fl-bigmug-line-microphone76:before {
	content: "\e06c";
}

.fl-bigmug-line-microphone77:before {
	content: "\e06d";
}

.fl-bigmug-line-minus79:before {
	content: "\e06e";
}

.fl-bigmug-line-minus80:before {
	content: "\e06f";
}

.fl-bigmug-line-minus83:before {
	content: "\e070";
}

.fl-bigmug-line-minus86:before {
	content: "\e071";
}

.fl-bigmug-line-monitor74:before {
	content: "\e072";
}

.fl-bigmug-line-music218:before {
	content: "\e073";
}

.fl-bigmug-line-music219:before {
	content: "\e074";
}

.fl-bigmug-line-music221:before {
	content: "\e075";
}

.fl-bigmug-line-musical100:before {
	content: "\e076";
}

.fl-bigmug-line-musical98:before {
	content: "\e077";
}

.fl-bigmug-line-mute34:before {
	content: "\e078";
}

.fl-bigmug-line-new83:before {
	content: "\e079";
}

.fl-bigmug-line-nine16:before {
	content: "\e07a";
}

.fl-bigmug-line-note35:before {
	content: "\e07b";
}

.fl-bigmug-line-notebook41:before {
	content: "\e07c";
}

.fl-bigmug-line-notification4:before {
	content: "\e07d";
}

.fl-bigmug-line-notification5:before {
	content: "\e07e";
}

.fl-bigmug-line-opened25:before {
	content: "\e07f";
}

.fl-bigmug-line-oval34:before {
	content: "\e080";
}

.fl-bigmug-line-paintbrush9:before {
	content: "\e081";
}

.fl-bigmug-line-paper122:before {
	content: "\e082";
}

.fl-bigmug-line-pause37:before {
	content: "\e083";
}

.fl-bigmug-line-pencil85:before {
	content: "\e084";
}

.fl-bigmug-line-phone351:before {
	content: "\e085";
}

.fl-bigmug-line-photo181:before {
	content: "\e086";
}

.fl-bigmug-line-pin42:before {
	content: "\e087";
}

.fl-bigmug-line-planetary2:before {
	content: "\e088";
}

.fl-bigmug-line-play83:before {
	content: "\e089";
}

.fl-bigmug-line-portfolio23:before {
	content: "\e08a";
}

.fl-bigmug-line-print34:before {
	content: "\e08b";
}

.fl-bigmug-line-radio46:before {
	content: "\e08c";
}

.fl-bigmug-line-rain30:before {
	content: "\e08d";
}

.fl-bigmug-line-rectangular78:before {
	content: "\e08e";
}

.fl-bigmug-line-recycling10:before {
	content: "\e08f";
}

.fl-bigmug-line-rewind37:before {
	content: "\e090";
}

.fl-bigmug-line-right139:before {
	content: "\e091";
}

.fl-bigmug-line-right141:before {
	content: "\e092";
}

.fl-bigmug-line-right142:before {
	content: "\e093";
}

.fl-bigmug-line-right144:before {
	content: "\e094";
}

.fl-bigmug-line-right148:before {
	content: "\e095";
}

.fl-bigmug-line-right153:before {
	content: "\e096";
}

.fl-bigmug-line-right154:before {
	content: "\e097";
}

.fl-bigmug-line-right156:before {
	content: "\e098";
}

.fl-bigmug-line-rounded51:before {
	content: "\e099";
}

.fl-bigmug-line-sand14:before {
	content: "\e09a";
}

.fl-bigmug-line-save15:before {
	content: "\e09b";
}

.fl-bigmug-line-search74:before {
	content: "\e09c";
}

.fl-bigmug-line-search78:before {
	content: "\e09d";
}

.fl-bigmug-line-share27:before {
	content: "\e09e";
}

.fl-bigmug-line-shopping198:before {
	content: "\e09f";
}

.fl-bigmug-line-shopping199:before {
	content: "\e0a0";
}

.fl-bigmug-line-shopping202:before {
	content: "\e0a1";
}

.fl-bigmug-line-shopping204:before {
	content: "\e0a2";
}

.fl-bigmug-line-shuffle17:before {
	content: "\e0a3";
}

.fl-bigmug-line-sort47:before {
	content: "\e0a4";
}

.fl-bigmug-line-sort48:before {
	content: "\e0a5";
}

.fl-bigmug-line-speaker75:before {
	content: "\e0a6";
}

.fl-bigmug-line-speaker80:before {
	content: "\e0a7";
}

.fl-bigmug-line-speaker81:before {
	content: "\e0a8";
}

.fl-bigmug-line-speaker86:before {
	content: "\e0a9";
}

.fl-bigmug-line-speaker87:before {
	content: "\e0aa";
}

.fl-bigmug-line-speech96:before {
	content: "\e0ab";
}

.fl-bigmug-line-square152:before {
	content: "\e0ac";
}

.fl-bigmug-line-square156:before {
	content: "\e0ad";
}

.fl-bigmug-line-square160:before {
	content: "\e0ae";
}

.fl-bigmug-line-store10:before {
	content: "\e0af";
}

.fl-bigmug-line-sun81:before {
	content: "\e0b0";
}

.fl-bigmug-line-sunrise3:before {
	content: "\e0b1";
}

.fl-bigmug-line-switch23:before {
	content: "\e0b2";
}

.fl-bigmug-line-switch24:before {
	content: "\e0b3";
}

.fl-bigmug-line-tag47:before {
	content: "\e0b4";
}

.fl-bigmug-line-television20:before {
	content: "\e0b5";
}

.fl-bigmug-line-text108:before {
	content: "\e0b6";
}

.fl-bigmug-line-text109:before {
	content: "\e0b7";
}

.fl-bigmug-line-three142:before {
	content: "\e0b8";
}

.fl-bigmug-line-timer35:before {
	content: "\e0b9";
}

.fl-bigmug-line-tool16:before {
	content: "\e0ba";
}

.fl-bigmug-line-triangle33:before {
	content: "\e0bb";
}

.fl-bigmug-line-trophy55:before {
	content: "\e0bc";
}

.fl-bigmug-line-two311:before {
	content: "\e0bd";
}

.fl-bigmug-line-two316:before {
	content: "\e0be";
}

.fl-bigmug-line-two317:before {
	content: "\e0bf";
}

.fl-bigmug-line-two319:before {
	content: "\e0c0";
}

.fl-bigmug-line-two323:before {
	content: "\e0c1";
}

.fl-bigmug-line-unlocked27:before {
	content: "\e0c2";
}

.fl-bigmug-line-up100:before {
	content: "\e0c3";
}

.fl-bigmug-line-up102:before {
	content: "\e0c4";
}

.fl-bigmug-line-up103:before {
	content: "\e0c5";
}

.fl-bigmug-line-up104:before {
	content: "\e0c6";
}

.fl-bigmug-line-up107:before {
	content: "\e0c7";
}

.fl-bigmug-line-up111:before {
	content: "\e0c8";
}

.fl-bigmug-line-up112:before {
	content: "\e0c9";
}

.fl-bigmug-line-up114:before {
	content: "\e0ca";
}

.fl-bigmug-line-up98:before {
	content: "\e0cb";
}

.fl-bigmug-line-up99:before {
	content: "\e0cc";
}

.fl-bigmug-line-upload91:before {
	content: "\e0cd";
}

.fl-bigmug-line-upload92:before {
	content: "\e0ce";
}

.fl-bigmug-line-upper8:before {
	content: "\e0cf";
}

.fl-bigmug-line-user143:before {
	content: "\e0d0";
}

.fl-bigmug-line-user144:before {
	content: "\e0d1";
}

.fl-bigmug-line-video163:before {
	content: "\e0d2";
}

.fl-bigmug-line-wallet26:before {
	content: "\e0d3";
}

.fl-bigmug-line-weather21:before {
	content: "\e0d4";
}

.fl-bigmug-line-weekly14:before {
	content: "\e0d5";
}

.fl-bigmug-line-weekly15:before {
	content: "\e0d6";
}

.fl-bigmug-line-wind24:before {
	content: "\e0d7";
}

.fl-bigmug-line-window50:before {
	content: "\e0d8";
}

.fl-bigmug-line-winds4:before {
	content: "\e0d9";
}

.fl-bigmug-line-wrench66:before {
	content: "\e0da";
}

.fl-bigmug-line-zoom60:before {
	content: "\e0db";
}

/**
 * Title: Budicons free Flaticon Pack
 * Author: Budi Tanrim
 * Source: http://www.flaticon.com/packs/budicons-free
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */
@font-face {
	font-family: "fl-budicons-free";
	src: url("../fonts/fl-budicons-free.eot");
	src: url("../fonts/fl-budicons-free.eot#iefix") format("embedded-opentype"), url("../fonts/fl-budicons-free.woff") format("woff"), url("../fonts/fl-budicons-free.ttf") format("truetype"), url("../fonts/fl-budicons-free.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-budicons-free-ico,
[class^="fl-budicons-free-"]:before, [class*=" fl-budicons-free-"]:before,
[class^="fl-budicons-free-"]:after, [class*=" fl-budicons-free-"]:after {
	font-family: 'fl-budicons-free';
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-budicons-free-add156:before {
	content: "\e000";
}

.fl-budicons-free-analogic3:before {
	content: "\e001";
}

.fl-budicons-free-bag32:before {
	content: "\e002";
}

.fl-budicons-free-bookmark31:before {
	content: "\e003";
}

.fl-budicons-free-chat58:before {
	content: "\e004";
}

.fl-budicons-free-circle95:before {
	content: "\e005";
}

.fl-budicons-free-closet:before {
	content: "\e006";
}

.fl-budicons-free-cloud262:before {
	content: "\e007";
}

.fl-budicons-free-cold11:before {
	content: "\e008";
}

.fl-budicons-free-comment46:before {
	content: "\e009";
}

.fl-budicons-free-cross84:before {
	content: "\e00a";
}

.fl-budicons-free-delete70:before {
	content: "\e00b";
}

.fl-budicons-free-design22:before {
	content: "\e00c";
}

.fl-budicons-free-down67:before {
	content: "\e00d";
}

.fl-budicons-free-download149:before {
	content: "\e00e";
}

.fl-budicons-free-electrical18:before {
	content: "\e00f";
}

.fl-budicons-free-email75:before {
	content: "\e010";
}

.fl-budicons-free-file72:before {
	content: "\e011";
}

.fl-budicons-free-folder174:before {
	content: "\e012";
}

.fl-budicons-free-folder175:before {
	content: "\e013";
}

.fl-budicons-free-heart284:before {
	content: "\e014";
}

.fl-budicons-free-label26:before {
	content: "\e015";
}

.fl-budicons-free-left161:before {
	content: "\e016";
}

.fl-budicons-free-lifeline14:before {
	content: "\e017";
}

.fl-budicons-free-location37:before {
	content: "\e018";
}

.fl-budicons-free-logout12:before {
	content: "\e019";
}

.fl-budicons-free-music223:before {
	content: "\e01a";
}

.fl-budicons-free-musical102:before {
	content: "\e01b";
}

.fl-budicons-free-notebook47:before {
	content: "\e01c";
}

.fl-budicons-free-paper124:before {
	content: "\e01d";
}

.fl-budicons-free-person308:before {
	content: "\e01e";
}

.fl-budicons-free-photo186:before {
	content: "\e01f";
}

.fl-budicons-free-photo187:before {
	content: "\e020";
}

.fl-budicons-free-placeholder22:before {
	content: "\e021";
}

.fl-budicons-free-portfolio27:before {
	content: "\e022";
}

.fl-budicons-free-quit2:before {
	content: "\e023";
}

.fl-budicons-free-rain31:before {
	content: "\e024";
}

.fl-budicons-free-right163:before {
	content: "\e025";
}

.fl-budicons-free-shape6:before {
	content: "\e026";
}

.fl-budicons-free-share29:before {
	content: "\e027";
}

.fl-budicons-free-speech102:before {
	content: "\e028";
}

.fl-budicons-free-tag49:before {
	content: "\e029";
}

.fl-budicons-free-two327:before {
	content: "\e02a";
}

.fl-budicons-free-up119:before {
	content: "\e02b";
}

.fl-budicons-free-upload96:before {
	content: "\e02c";
}

.fl-budicons-free-verify5:before {
	content: "\e02d";
}

.fl-budicons-free-verify6:before {
	content: "\e02e";
}

.fl-budicons-free-wheel29:before {
	content: "\e02f";
}

/**
 * Title: Budicons launch Flaticon Pack
 * Author: Budi Tanrim
 * Source: http://www.flaticon.com/packs/budicons-launch
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */
@font-face {
	font-family: "fl-budicons-launch";
	src: url("../fonts/fl-budicons-launch.eot");
	src: url("../fonts/fl-budicons-launch.eot#iefix") format("embedded-opentype"), url("../fonts/fl-budicons-launch.woff") format("woff"), url("../fonts/fl-budicons-launch.ttf") format("truetype"), url("../fonts/fl-budicons-launch.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-budicons-launch-ico,
[class^="fl-budicons-launch-"]:before, [class*=" fl-budicons-launch-"]:before,
[class^="fl-budicons-launch-"]:after, [class*=" fl-budicons-launch-"]:after {
	font-family: 'fl-budicons-launch';
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-budicons-launch-arrows92:before {
	content: "\e000";
}

.fl-budicons-launch-center12:before {
	content: "\e001";
}

.fl-budicons-launch-chat57:before {
	content: "\e002";
}

.fl-budicons-launch-checkmark17:before {
	content: "\e003";
}

.fl-budicons-launch-croos:before {
	content: "\e004";
}

.fl-budicons-launch-cropping2:before {
	content: "\e005";
}

.fl-budicons-launch-drink47:before {
	content: "\e006";
}

.fl-budicons-launch-email74:before {
	content: "\e007";
}

.fl-budicons-launch-equalizer29:before {
	content: "\e008";
}

.fl-budicons-launch-file71:before {
	content: "\e009";
}

.fl-budicons-launch-folder177:before {
	content: "\e00a";
}

.fl-budicons-launch-images22:before {
	content: "\e00b";
}

.fl-budicons-launch-images23:before {
	content: "\e00c";
}

.fl-budicons-launch-left162:before {
	content: "\e00d";
}

.fl-budicons-launch-left163:before {
	content: "\e00e";
}

.fl-budicons-launch-map90:before {
	content: "\e00f";
}

.fl-budicons-launch-menu47:before {
	content: "\e010";
}

.fl-budicons-launch-minus87:before {
	content: "\e011";
}

.fl-budicons-launch-more15:before {
	content: "\e012";
}

.fl-budicons-launch-padlock42:before {
	content: "\e013";
}

.fl-budicons-launch-paper123:before {
	content: "\e014";
}

.fl-budicons-launch-photo185:before {
	content: "\e015";
}

.fl-budicons-launch-placeholder21:before {
	content: "\e016";
}

.fl-budicons-launch-plus72:before {
	content: "\e017";
}

.fl-budicons-launch-printer80:before {
	content: "\e018";
}

.fl-budicons-launch-recycling12:before {
	content: "\e019";
}

.fl-budicons-launch-right162:before {
	content: "\e01a";
}

.fl-budicons-launch-right164:before {
	content: "\e01b";
}

.fl-budicons-launch-search81:before {
	content: "\e01c";
}

.fl-budicons-launch-speedometer31:before {
	content: "\e01d";
}

.fl-budicons-launch-squares24:before {
	content: "\e01e";
}

.fl-budicons-launch-sun82:before {
	content: "\e01f";
}

.fl-budicons-launch-user146:before {
	content: "\e020";
}

.fl-budicons-launch-visualization5:before {
	content: "\e021";
}

.fl-budicons-launch-web34:before {
	content: "\e022";
}

/**
 * Title: Justicons Flaticon Pack
 * Author: Rami McMin
 * Source: http://www.flaticon.com/packs/justicons
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */
@font-face {
	font-family: "fl-justicons";
	src: url("../fonts/fl-justicons.eot");
	src: url("../fonts/fl-justicons.eot#iefix") format("embedded-opentype"), url("../fonts/fl-justicons.woff") format("woff"), url("../fonts/fl-justicons.ttf") format("truetype"), url("../fonts/fl-justicons.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-justicons-ico,
[class^="fl-justicons-"]:before, [class*=" fl-justicons-"]:before,
[class^="fl-justicons-"]:after, [class*=" fl-justicons-"]:after {
	font-family: fl-justicons;
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-justicons-acceptance:before {
	content: "\e000";
}

.fl-justicons-add133:before {
	content: "\e001";
}

.fl-justicons-airplane82:before {
	content: "\e002";
}

.fl-justicons-anchor31:before {
	content: "\e003";
}

.fl-justicons-attachment13:before {
	content: "\e004";
}

.fl-justicons-attention5:before {
	content: "\e005";
}

.fl-justicons-back36:before {
	content: "\e006";
}

.fl-justicons-barbecue6:before {
	content: "\e007";
}

.fl-justicons-bars48:before {
	content: "\e008";
}

.fl-justicons-basketball38:before {
	content: "\e009";
}

.fl-justicons-bell37:before {
	content: "\e00a";
}

.fl-justicons-blank25:before {
	content: "\e00b";
}

.fl-justicons-bookmark26:before {
	content: "\e00c";
}

.fl-justicons-brush22:before {
	content: "\e00d";
}

.fl-justicons-chef24:before {
	content: "\e00e";
}

.fl-justicons-chronometer17:before {
	content: "\e00f";
}

.fl-justicons-circular210:before {
	content: "\e010";
}

.fl-justicons-circular212:before {
	content: "\e011";
}

.fl-justicons-clipboard66:before {
	content: "\e012";
}

.fl-justicons-clockwise2:before {
	content: "\e013";
}

.fl-justicons-close40:before {
	content: "\e014";
}

.fl-justicons-cloud246:before {
	content: "\e015";
}

.fl-justicons-cloud247:before {
	content: "\e016";
}

.fl-justicons-cloud248:before {
	content: "\e017";
}

.fl-justicons-cloud249:before {
	content: "\e018";
}

.fl-justicons-cloudy17:before {
	content: "\e019";
}

.fl-justicons-cloudy19:before {
	content: "\e01a";
}

.fl-justicons-cloudy20:before {
	content: "\e01b";
}

.fl-justicons-cloudy21:before {
	content: "\e01c";
}

.fl-justicons-cloudy22:before {
	content: "\e01d";
}

.fl-justicons-cold10:before {
	content: "\e01e";
}

.fl-justicons-commercial26:before {
	content: "\e01f";
}

.fl-justicons-compass79:before {
	content: "\e020";
}

.fl-justicons-cooking20:before {
	content: "\e021";
}

.fl-justicons-cupcake13:before {
	content: "\e022";
}

.fl-justicons-danger8:before {
	content: "\e023";
}

.fl-justicons-database43:before {
	content: "\e024";
}

.fl-justicons-diaphragm9:before {
	content: "\e025";
}

.fl-justicons-down53:before {
	content: "\e026";
}

.fl-justicons-download131:before {
	content: "\e027";
}

.fl-justicons-edit41:before {
	content: "\e028";
}

.fl-justicons-electrical14:before {
	content: "\e029";
}

.fl-justicons-empty31:before {
	content: "\e02a";
}

.fl-justicons-empty32:before {
	content: "\e02b";
}

.fl-justicons-equalization2:before {
	content: "\e02c";
}

.fl-justicons-favorites2:before {
	content: "\e02d";
}

.fl-justicons-file60:before {
	content: "\e02e";
}

.fl-justicons-first40:before {
	content: "\e02f";
}

.fl-justicons-flash22:before {
	content: "\e030";
}

.fl-justicons-flower109:before {
	content: "\e031";
}

.fl-justicons-focus7:before {
	content: "\e032";
}

.fl-justicons-fog8:before {
	content: "\e033";
}

.fl-justicons-folded32:before {
	content: "\e034";
}

.fl-justicons-folder167:before {
	content: "\e035";
}

.fl-justicons-game51:before {
	content: "\e036";
}

.fl-justicons-global24:before {
	content: "\e037";
}

.fl-justicons-hazelnut1:before {
	content: "\e038";
}

.fl-justicons-headphones30:before {
	content: "\e039";
}

.fl-justicons-heart279:before {
	content: "\e03a";
}

.fl-justicons-helm1:before {
	content: "\e03b";
}

.fl-justicons-home133:before {
	content: "\e03c";
}

.fl-justicons-information51:before {
	content: "\e03d";
}

.fl-justicons-internet33:before {
	content: "\e03e";
}

.fl-justicons-leaf57:before {
	content: "\e03f";
}

.fl-justicons-link49:before {
	content: "\e040";
}

.fl-justicons-liquid11:before {
	content: "\e041";
}

.fl-justicons-lock63:before {
	content: "\e042";
}

.fl-justicons-magnetic1:before {
	content: "\e043";
}

.fl-justicons-magnifier32:before {
	content: "\e044";
}

.fl-justicons-magnifier33:before {
	content: "\e045";
}

.fl-justicons-maps7:before {
	content: "\e046";
}

.fl-justicons-medal54:before {
	content: "\e047";
}

.fl-justicons-megaphone10:before {
	content: "\e048";
}

.fl-justicons-minus75:before {
	content: "\e049";
}

.fl-justicons-moon144:before {
	content: "\e04a";
}

.fl-justicons-musical96:before {
	content: "\e04b";
}

.fl-justicons-new78:before {
	content: "\e04c";
}

.fl-justicons-note32:before {
	content: "\e04d";
}

.fl-justicons-note33:before {
	content: "\e04e";
}

.fl-justicons-parabolic11:before {
	content: "\e04f";
}

.fl-justicons-peace5:before {
	content: "\e050";
}

.fl-justicons-pen57:before {
	content: "\e051";
}

.fl-justicons-photo171:before {
	content: "\e052";
}

.fl-justicons-piano21:before {
	content: "\e053";
}

.fl-justicons-picture27:before {
	content: "\e054";
}

.fl-justicons-pill7:before {
	content: "\e055";
}

.fl-justicons-pin39:before {
	content: "\e056";
}

.fl-justicons-pine8:before {
	content: "\e057";
}

.fl-justicons-portfolio21:before {
	content: "\e058";
}

.fl-justicons-power96:before {
	content: "\e059";
}

.fl-justicons-precious1:before {
	content: "\e05a";
}

.fl-justicons-print31:before {
	content: "\e05b";
}

.fl-justicons-prohibition17:before {
	content: "\e05c";
}

.fl-justicons-rain27:before {
	content: "\e05d";
}

.fl-justicons-rainy11:before {
	content: "\e05e";
}

.fl-justicons-rainy12:before {
	content: "\e05f";
}

.fl-justicons-right132:before {
	content: "\e060";
}

.fl-justicons-right133:before {
	content: "\e061";
}

.fl-justicons-right134:before {
	content: "\e062";
}

.fl-justicons-rocket64:before {
	content: "\e063";
}

.fl-justicons-saturn12:before {
	content: "\e064";
}

.fl-justicons-shield82:before {
	content: "\e065";
}

.fl-justicons-shopping189:before {
	content: "\e066";
}

.fl-justicons-skull26:before {
	content: "\e067";
}

.fl-justicons-spam7:before {
	content: "\e068";
}

.fl-justicons-speaker73:before {
	content: "\e069";
}

.fl-justicons-speech92:before {
	content: "\e06a";
}

.fl-justicons-sports36:before {
	content: "\e06b";
}

.fl-justicons-sun79:before {
	content: "\e06c";
}

.fl-justicons-sunrise2:before {
	content: "\e06d";
}

.fl-justicons-sunset2:before {
	content: "\e06e";
}

.fl-justicons-switches:before {
	content: "\e06f";
}

.fl-justicons-tag44:before {
	content: "\e070";
}

.fl-justicons-telephone95:before {
	content: "\e071";
}

.fl-justicons-temperature11:before {
	content: "\e072";
}

.fl-justicons-thermometer40:before {
	content: "\e073";
}

.fl-justicons-thermometer41:before {
	content: "\e074";
}

.fl-justicons-three140:before {
	content: "\e075";
}

.fl-justicons-tool15:before {
	content: "\e076";
}

.fl-justicons-tray15:before {
	content: "\e077";
}

.fl-justicons-two298:before {
	content: "\e078";
}

.fl-justicons-umbrella40:before {
	content: "\e079";
}

.fl-justicons-university13:before {
	content: "\e07a";
}

.fl-justicons-upload81:before {
	content: "\e07b";
}

.fl-justicons-upload82:before {
	content: "\e07c";
}

.fl-justicons-upload83:before {
	content: "\e07d";
}

.fl-justicons-upper4:before {
	content: "\e07e";
}

.fl-justicons-user140:before {
	content: "\e07f";
}

.fl-justicons-video158:before {
	content: "\e080";
}

.fl-justicons-visible6:before {
	content: "\e081";
}

.fl-justicons-voice19:before {
	content: "\e082";
}

.fl-justicons-wheel24:before {
	content: "\e083";
}

.fl-justicons-wheel25:before {
	content: "\e084";
}

.fl-justicons-white83:before {
	content: "\e085";
}

.fl-justicons-wifi74:before {
	content: "\e086";
}

.fl-justicons-wind23:before {
	content: "\e087";
}

.fl-justicons-winds2:before {
	content: "\e088";
}

.fl-justicons-winter23:before {
	content: "\e089";
}

.fl-justicons-wrench64:before {
	content: "\e08a";
}

.fl-justicons-zig5:before {
	content: "\e08b";
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.eot);
	/* For IE6-8 */
	src: local("Material Icons"), local("MaterialIcons-Regular"), url(../fonts/MaterialIcons-Regular.woff2) format("woff2"), url(../fonts/MaterialIcons-Regular.woff) format("woff"), url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons-ico,
[class*="material-icons-"]:before {
	font-family: 'Material Icons';
	font-weight: 400;
	font-style: normal;
	font-size: inherit;
}

.material-icons-3d_rotation:before {
	content: "\e84d";
}

.material-icons-access_alarm:before {
	content: "\e190";
}

.material-icons-access_alarms:before {
	content: "\e191";
}

.material-icons-access_time:before {
	content: "\e192";
}

.material-icons-accessibility:before {
	content: "\e84e";
}

.material-icons-account_balance:before {
	content: "\e84f";
}

.material-icons-account_balance_wallet:before {
	content: "\e850";
}

.material-icons-account_box:before {
	content: "\e851";
}

.material-icons-account_circle:before {
	content: "\e853";
}

.material-icons-adb:before {
	content: "\e60e";
}

.material-icons-add:before {
	content: "\e145";
}

.material-icons-add_alarm:before {
	content: "\e193";
}

.material-icons-add_alert:before {
	content: "\e003";
}

.material-icons-add_box:before {
	content: "\e146";
}

.material-icons-add_circle:before {
	content: "\e147";
}

.material-icons-add_circle_outline:before {
	content: "\e148";
}

.material-icons-add_shopping_cart:before {
	content: "\e854";
}

.material-icons-add_to_photos:before {
	content: "\e39d";
}

.material-icons-adjust:before {
	content: "\e39e";
}

.material-icons-airline_seat_flat:before {
	content: "\e630";
}

.material-icons-airline_seat_flat_angled:before {
	content: "\e631";
}

.material-icons-airline_seat_individual_suite:before {
	content: "\e632";
}

.material-icons-airline_seat_legroom_extra:before {
	content: "\e633";
}

.material-icons-airline_seat_legroom_normal:before {
	content: "\e634";
}

.material-icons-airline_seat_legroom_reduced:before {
	content: "\e635";
}

.material-icons-airline_seat_recline_extra:before {
	content: "\e636";
}

.material-icons-airline_seat_recline_normal:before {
	content: "\e637";
}

.material-icons-airplanemode_active:before {
	content: "\e195";
}

.material-icons-airplanemode_inactive:before {
	content: "\e194";
}

.material-icons-airplay:before {
	content: "\e055";
}

.material-icons-alarm:before {
	content: "\e855";
}

.material-icons-alarm_add:before {
	content: "\e856";
}

.material-icons-alarm_off:before {
	content: "\e857";
}

.material-icons-alarm_on:before {
	content: "\e858";
}

.material-icons-album:before {
	content: "\e019";
}

.material-icons-android:before {
	content: "\e859";
}

.material-icons-announcement:before {
	content: "\e85a";
}

.material-icons-apps:before {
	content: "\e5c3";
}

.material-icons-archive:before {
	content: "\e149";
}

.material-icons-arrow_back:before {
	content: "\e5c4";
}

.material-icons-arrow_drop_down:before {
	content: "\e5c5";
}

.material-icons-arrow_drop_down_circle:before {
	content: "\e5c6";
}

.material-icons-arrow_drop_up:before {
	content: "\e5c7";
}

.material-icons-arrow_forward:before {
	content: "\e5c8";
}

.material-icons-aspect_ratio:before {
	content: "\e85b";
}

.material-icons-assessment:before {
	content: "\e85c";
}

.material-icons-assignment:before {
	content: "\e85d";
}

.material-icons-assignment_ind:before {
	content: "\e85e";
}

.material-icons-assignment_late:before {
	content: "\e85f";
}

.material-icons-assignment_return:before {
	content: "\e860";
}

.material-icons-assignment_returned:before {
	content: "\e861";
}

.material-icons-assignment_turned_in:before {
	content: "\e862";
}

.material-icons-assistant:before {
	content: "\e39f";
}

.material-icons-assistant_photo:before {
	content: "\e3a0";
}

.material-icons-attach_file:before {
	content: "\e226";
}

.material-icons-attach_money:before {
	content: "\e227";
}

.material-icons-attachment:before {
	content: "\e2bc";
}

.material-icons-audiotrack:before {
	content: "\e3a1";
}

.material-icons-autorenew:before {
	content: "\e863";
}

.material-icons-av_timer:before {
	content: "\e01b";
}

.material-icons-backspace:before {
	content: "\e14a";
}

.material-icons-backup:before {
	content: "\e864";
}

.material-icons-battery_alert:before {
	content: "\e19c";
}

.material-icons-battery_charging_full:before {
	content: "\e1a3";
}

.material-icons-battery_full:before {
	content: "\e1a4";
}

.material-icons-battery_std:before {
	content: "\e1a5";
}

.material-icons-battery_unknown:before {
	content: "\e1a6";
}

.material-icons-beenhere:before {
	content: "\e52d";
}

.material-icons-block:before {
	content: "\e14b";
}

.material-icons-bluetooth:before {
	content: "\e1a7";
}

.material-icons-bluetooth_audio:before {
	content: "\e60f";
}

.material-icons-bluetooth_connected:before {
	content: "\e1a8";
}

.material-icons-bluetooth_disabled:before {
	content: "\e1a9";
}

.material-icons-bluetooth_searching:before {
	content: "\e1aa";
}

.material-icons-blur_circular:before {
	content: "\e3a2";
}

.material-icons-blur_linear:before {
	content: "\e3a3";
}

.material-icons-blur_off:before {
	content: "\e3a4";
}

.material-icons-blur_on:before {
	content: "\e3a5";
}

.material-icons-book:before {
	content: "\e865";
}

.material-icons-bookmark:before {
	content: "\e866";
}

.material-icons-bookmark_border:before {
	content: "\e867";
}

.material-icons-border_all:before {
	content: "\e228";
}

.material-icons-border_bottom:before {
	content: "\e229";
}

.material-icons-border_clear:before {
	content: "\e22a";
}

.material-icons-border_color:before {
	content: "\e22b";
}

.material-icons-border_horizontal:before {
	content: "\e22c";
}

.material-icons-border_inner:before {
	content: "\e22d";
}

.material-icons-border_left:before {
	content: "\e22e";
}

.material-icons-border_outer:before {
	content: "\e22f";
}

.material-icons-border_right:before {
	content: "\e230";
}

.material-icons-border_style:before {
	content: "\e231";
}

.material-icons-border_top:before {
	content: "\e232";
}

.material-icons-border_vertical:before {
	content: "\e233";
}

.material-icons-brightness_1:before {
	content: "\e3a6";
}

.material-icons-brightness_2:before {
	content: "\e3a7";
}

.material-icons-brightness_3:before {
	content: "\e3a8";
}

.material-icons-brightness_4:before {
	content: "\e3a9";
}

.material-icons-brightness_5:before {
	content: "\e3aa";
}

.material-icons-brightness_6:before {
	content: "\e3ab";
}

.material-icons-brightness_7:before {
	content: "\e3ac";
}

.material-icons-brightness_auto:before {
	content: "\e1ab";
}

.material-icons-brightness_high:before {
	content: "\e1ac";
}

.material-icons-brightness_low:before {
	content: "\e1ad";
}

.material-icons-brightness_medium:before {
	content: "\e1ae";
}

.material-icons-broken_image:before {
	content: "\e3ad";
}

.material-icons-brush:before {
	content: "\e3ae";
}

.material-icons-bug_report:before {
	content: "\e868";
}

.material-icons-build:before {
	content: "\e869";
}

.material-icons-business:before {
	content: "\e0af";
}

.material-icons-cached:before {
	content: "\e86a";
}

.material-icons-cake:before {
	content: "\e7e9";
}

.material-icons-call:before {
	content: "\e0b0";
}

.material-icons-call_end:before {
	content: "\e0b1";
}

.material-icons-call_made:before {
	content: "\e0b2";
}

.material-icons-call_merge:before {
	content: "\e0b3";
}

.material-icons-call_missed:before {
	content: "\e0b4";
}

.material-icons-call_received:before {
	content: "\e0b5";
}

.material-icons-call_split:before {
	content: "\e0b6";
}

.material-icons-camera:before {
	content: "\e3af";
}

.material-icons-camera_alt:before {
	content: "\e3b0";
}

.material-icons-camera_enhance:before {
	content: "\e8fc";
}

.material-icons-camera_front:before {
	content: "\e3b1";
}

.material-icons-camera_rear:before {
	content: "\e3b2";
}

.material-icons-camera_roll:before {
	content: "\e3b3";
}

.material-icons-cancel:before {
	content: "\e5c9";
}

.material-icons-card_giftcard:before {
	content: "\e8f6";
}

.material-icons-card_membership:before {
	content: "\e8f7";
}

.material-icons-card_travel:before {
	content: "\e8f8";
}

.material-icons-cast:before {
	content: "\e307";
}

.material-icons-cast_connected:before {
	content: "\e308";
}

.material-icons-center_focus_strong:before {
	content: "\e3b4";
}

.material-icons-center_focus_weak:before {
	content: "\e3b5";
}

.material-icons-change_history:before {
	content: "\e86b";
}

.material-icons-chat:before {
	content: "\e0b7";
}

.material-icons-chat_bubble:before {
	content: "\e0ca";
}

.material-icons-chat_bubble_outline:before {
	content: "\e0cb";
}

.material-icons-check:before {
	content: "\e5ca";
}

.material-icons-check_box:before {
	content: "\e834";
}

.material-icons-check_box_outline_blank:before {
	content: "\e835";
}

.material-icons-check_circle:before {
	content: "\e86c";
}

.material-icons-chevron_left:before {
	content: "\e5cb";
}

.material-icons-chevron_right:before {
	content: "\e5cc";
}

.material-icons-chrome_reader_mode:before {
	content: "\e86d";
}

.material-icons-class:before {
	content: "\e86e";
}

.material-icons-clear:before {
	content: "\e14c";
}

.material-icons-clear_all:before {
	content: "\e0b8";
}

.material-icons-close:before {
	content: "\e5cd";
}

.material-icons-closed_caption:before {
	content: "\e01c";
}

.material-icons-cloud:before {
	content: "\e2bd";
}

.material-icons-cloud_circle:before {
	content: "\e2be";
}

.material-icons-cloud_done:before {
	content: "\e2bf";
}

.material-icons-cloud_download:before {
	content: "\e2c0";
}

.material-icons-cloud_off:before {
	content: "\e2c1";
}

.material-icons-cloud_queue:before {
	content: "\e2c2";
}

.material-icons-cloud_upload:before {
	content: "\e2c3";
}

.material-icons-code:before {
	content: "\e86f";
}

.material-icons-collections:before {
	content: "\e3b6";
}

.material-icons-collections_bookmark:before {
	content: "\e431";
}

.material-icons-color_lens:before {
	content: "\e3b7";
}

.material-icons-colorize:before {
	content: "\e3b8";
}

.material-icons-comment:before {
	content: "\e0b9";
}

.material-icons-compare:before {
	content: "\e3b9";
}

.material-icons-computer:before {
	content: "\e30a";
}

.material-icons-confirmation_number:before {
	content: "\e638";
}

.material-icons-contact_phone:before {
	content: "\e0cf";
}

.material-icons-contacts:before {
	content: "\e0ba";
}

.material-icons-content_copy:before {
	content: "\e14d";
}

.material-icons-content_cut:before {
	content: "\e14e";
}

.material-icons-content_paste:before {
	content: "\e14f";
}

.material-icons-control_point:before {
	content: "\e3ba";
}

.material-icons-control_point_duplicate:before {
	content: "\e3bb";
}

.material-icons-create:before {
	content: "\e150";
}

.material-icons-credit_card:before {
	content: "\e870";
}

.material-icons-crop:before {
	content: "\e3be";
}

.material-icons-crop_16_9:before {
	content: "\e3bc";
}

.material-icons-crop_3_2:before {
	content: "\e3bd";
}

.material-icons-crop_5_4:before {
	content: "\e3bf";
}

.material-icons-crop_7_5:before {
	content: "\e3c0";
}

.material-icons-crop_din:before {
	content: "\e3c1";
}

.material-icons-crop_free:before {
	content: "\e3c2";
}

.material-icons-crop_landscape:before {
	content: "\e3c3";
}

.material-icons-crop_original:before {
	content: "\e3c4";
}

.material-icons-crop_portrait:before {
	content: "\e3c5";
}

.material-icons-crop_square:before {
	content: "\e3c6";
}

.material-icons-dashboard:before {
	content: "\e871";
}

.material-icons-data_usage:before {
	content: "\e1af";
}

.material-icons-dehaze:before {
	content: "\e3c7";
}

.material-icons-delete:before {
	content: "\e872";
}

.material-icons-description:before {
	content: "\e873";
}

.material-icons-desktop_mac:before {
	content: "\e30b";
}

.material-icons-desktop_windows:before {
	content: "\e30c";
}

.material-icons-details:before {
	content: "\e3c8";
}

.material-icons-developer_board:before {
	content: "\e30d";
}

.material-icons-developer_mode:before {
	content: "\e1b0";
}

.material-icons-device_hub:before {
	content: "\e335";
}

.material-icons-devices:before {
	content: "\e1b1";
}

.material-icons-dialer_sip:before {
	content: "\e0bb";
}

.material-icons-dialpad:before {
	content: "\e0bc";
}

.material-icons-directions:before {
	content: "\e52e";
}

.material-icons-directions_bike:before {
	content: "\e52f";
}

.material-icons-directions_boat:before {
	content: "\e532";
}

.material-icons-directions_bus:before {
	content: "\e530";
}

.material-icons-directions_car:before {
	content: "\e531";
}

.material-icons-directions_railway:before {
	content: "\e534";
}

.material-icons-directions_run:before {
	content: "\e566";
}

.material-icons-directions_subway:before {
	content: "\e533";
}

.material-icons-directions_transit:before {
	content: "\e535";
}

.material-icons-directions_walk:before {
	content: "\e536";
}

.material-icons-disc_full:before {
	content: "\e610";
}

.material-icons-dns:before {
	content: "\e875";
}

.material-icons-do_not_disturb:before {
	content: "\e612";
}

.material-icons-do_not_disturb_alt:before {
	content: "\e611";
}

.material-icons-dock:before {
	content: "\e30e";
}

.material-icons-domain:before {
	content: "\e7ee";
}

.material-icons-done:before {
	content: "\e876";
}

.material-icons-done_all:before {
	content: "\e877";
}

.material-icons-drafts:before {
	content: "\e151";
}

.material-icons-drive_eta:before {
	content: "\e613";
}

.material-icons-dvr:before {
	content: "\e1b2";
}

.material-icons-edit:before {
	content: "\e3c9";
}

.material-icons-eject:before {
	content: "\e8fb";
}

.material-icons-email:before {
	content: "\e0be";
}

.material-icons-equalizer:before {
	content: "\e01d";
}

.material-icons-error:before {
	content: "\e000";
}

.material-icons-error_outline:before {
	content: "\e001";
}

.material-icons-event:before {
	content: "\e878";
}

.material-icons-event_available:before {
	content: "\e614";
}

.material-icons-event_busy:before {
	content: "\e615";
}

.material-icons-event_note:before {
	content: "\e616";
}

.material-icons-event_seat:before {
	content: "\e903";
}

.material-icons-exit_to_app:before {
	content: "\e879";
}

.material-icons-expand_less:before {
	content: "\e5ce";
}

.material-icons-expand_more:before {
	content: "\e5cf";
}

.material-icons-explicit:before {
	content: "\e01e";
}

.material-icons-explore:before {
	content: "\e87a";
}

.material-icons-exposure:before {
	content: "\e3ca";
}

.material-icons-exposure_neg_1:before {
	content: "\e3cb";
}

.material-icons-exposure_neg_2:before {
	content: "\e3cc";
}

.material-icons-exposure_plus_1:before {
	content: "\e3cd";
}

.material-icons-exposure_plus_2:before {
	content: "\e3ce";
}

.material-icons-exposure_zero:before {
	content: "\e3cf";
}

.material-icons-extension:before {
	content: "\e87b";
}

.material-icons-face:before {
	content: "\e87c";
}

.material-icons-fast_forward:before {
	content: "\e01f";
}

.material-icons-fast_rewind:before {
	content: "\e020";
}

.material-icons-favorite:before {
	content: "\e87d";
}

.material-icons-favorite_border:before {
	content: "\e87e";
}

.material-icons-feedback:before {
	content: "\e87f";
}

.material-icons-file_download:before {
	content: "\e2c4";
}

.material-icons-file_upload:before {
	content: "\e2c6";
}

.material-icons-filter:before {
	content: "\e3d3";
}

.material-icons-filter_1:before {
	content: "\e3d0";
}

.material-icons-filter_2:before {
	content: "\e3d1";
}

.material-icons-filter_3:before {
	content: "\e3d2";
}

.material-icons-filter_4:before {
	content: "\e3d4";
}

.material-icons-filter_5:before {
	content: "\e3d5";
}

.material-icons-filter_6:before {
	content: "\e3d6";
}

.material-icons-filter_7:before {
	content: "\e3d7";
}

.material-icons-filter_8:before {
	content: "\e3d8";
}

.material-icons-filter_9:before {
	content: "\e3d9";
}

.material-icons-filter_9_plus:before {
	content: "\e3da";
}

.material-icons-filter_b_and_w:before {
	content: "\e3db";
}

.material-icons-filter_center_focus:before {
	content: "\e3dc";
}

.material-icons-filter_drama:before {
	content: "\e3dd";
}

.material-icons-filter_frames:before {
	content: "\e3de";
}

.material-icons-filter_hdr:before {
	content: "\e3df";
}

.material-icons-filter_list:before {
	content: "\e152";
}

.material-icons-filter_none:before {
	content: "\e3e0";
}

.material-icons-filter_tilt_shift:before {
	content: "\e3e2";
}

.material-icons-filter_vintage:before {
	content: "\e3e3";
}

.material-icons-find_in_page:before {
	content: "\e880";
}

.material-icons-find_replace:before {
	content: "\e881";
}

.material-icons-flag:before {
	content: "\e153";
}

.material-icons-flare:before {
	content: "\e3e4";
}

.material-icons-flash_auto:before {
	content: "\e3e5";
}

.material-icons-flash_off:before {
	content: "\e3e6";
}

.material-icons-flash_on:before {
	content: "\e3e7";
}

.material-icons-flight:before {
	content: "\e539";
}

.material-icons-flight_land:before {
	content: "\e904";
}

.material-icons-flight_takeoff:before {
	content: "\e905";
}

.material-icons-flip:before {
	content: "\e3e8";
}

.material-icons-flip_to_back:before {
	content: "\e882";
}

.material-icons-flip_to_front:before {
	content: "\e883";
}

.material-icons-folder:before {
	content: "\e2c7";
}

.material-icons-folder_open:before {
	content: "\e2c8";
}

.material-icons-folder_shared:before {
	content: "\e2c9";
}

.material-icons-folder_special:before {
	content: "\e617";
}

.material-icons-font_download:before {
	content: "\e167";
}

.material-icons-format_align_center:before {
	content: "\e234";
}

.material-icons-format_align_justify:before {
	content: "\e235";
}

.material-icons-format_align_left:before {
	content: "\e236";
}

.material-icons-format_align_right:before {
	content: "\e237";
}

.material-icons-format_bold:before {
	content: "\e238";
}

.material-icons-format_clear:before {
	content: "\e239";
}

.material-icons-format_color_fill:before {
	content: "\e23a";
}

.material-icons-format_color_reset:before {
	content: "\e23b";
}

.material-icons-format_color_text:before {
	content: "\e23c";
}

.material-icons-format_indent_decrease:before {
	content: "\e23d";
}

.material-icons-format_indent_increase:before {
	content: "\e23e";
}

.material-icons-format_italic:before {
	content: "\e23f";
}

.material-icons-format_line_spacing:before {
	content: "\e240";
}

.material-icons-format_list_bulleted:before {
	content: "\e241";
}

.material-icons-format_list_numbered:before {
	content: "\e242";
}

.material-icons-format_paint:before {
	content: "\e243";
}

.material-icons-format_quote:before {
	content: "\e244";
}

.material-icons-format_size:before {
	content: "\e245";
}

.material-icons-format_strikethrough:before {
	content: "\e246";
}

.material-icons-format_textdirection_l_to_r:before {
	content: "\e247";
}

.material-icons-format_textdirection_r_to_l:before {
	content: "\e248";
}

.material-icons-format_underlined:before {
	content: "\e249";
}

.material-icons-forum:before {
	content: "\e0bf";
}

.material-icons-forward:before {
	content: "\e154";
}

.material-icons-forward_10:before {
	content: "\e056";
}

.material-icons-forward_30:before {
	content: "\e057";
}

.material-icons-forward_5:before {
	content: "\e058";
}

.material-icons-fullscreen:before {
	content: "\e5d0";
}

.material-icons-fullscreen_exit:before {
	content: "\e5d1";
}

.material-icons-functions:before {
	content: "\e24a";
}

.material-icons-gamepad:before {
	content: "\e30f";
}

.material-icons-games:before {
	content: "\e021";
}

.material-icons-gesture:before {
	content: "\e155";
}

.material-icons-get_app:before {
	content: "\e884";
}

.material-icons-gif:before {
	content: "\e908";
}

.material-icons-gps_fixed:before {
	content: "\e1b3";
}

.material-icons-gps_not_fixed:before {
	content: "\e1b4";
}

.material-icons-gps_off:before {
	content: "\e1b5";
}

.material-icons-grade:before {
	content: "\e885";
}

.material-icons-gradient:before {
	content: "\e3e9";
}

.material-icons-grain:before {
	content: "\e3ea";
}

.material-icons-graphic_eq:before {
	content: "\e1b8";
}

.material-icons-grid_off:before {
	content: "\e3eb";
}

.material-icons-grid_on:before {
	content: "\e3ec";
}

.material-icons-group:before {
	content: "\e7ef";
}

.material-icons-group_add:before {
	content: "\e7f0";
}

.material-icons-group_work:before {
	content: "\e886";
}

.material-icons-hd:before {
	content: "\e052";
}

.material-icons-hdr_off:before {
	content: "\e3ed";
}

.material-icons-hdr_on:before {
	content: "\e3ee";
}

.material-icons-hdr_strong:before {
	content: "\e3f1";
}

.material-icons-hdr_weak:before {
	content: "\e3f2";
}

.material-icons-headset:before {
	content: "\e310";
}

.material-icons-headset_mic:before {
	content: "\e311";
}

.material-icons-healing:before {
	content: "\e3f3";
}

.material-icons-hearing:before {
	content: "\e023";
}

.material-icons-help:before {
	content: "\e887";
}

.material-icons-help_outline:before {
	content: "\e8fd";
}

.material-icons-high_quality:before {
	content: "\e024";
}

.material-icons-highlight_off:before {
	content: "\e888";
}

.material-icons-history:before {
	content: "\e889";
}

.material-icons-home:before {
	content: "\e88a";
}

.material-icons-hotel:before {
	content: "\e53a";
}

.material-icons-hourglass_empty:before {
	content: "\e88b";
}

.material-icons-hourglass_full:before {
	content: "\e88c";
}

.material-icons-http:before {
	content: "\e902";
}

.material-icons-https:before {
	content: "\e88d";
}

.material-icons-image:before {
	content: "\e3f4";
}

.material-icons-image_aspect_ratio:before {
	content: "\e3f5";
}

.material-icons-import_export:before {
	content: "\e0c3";
}

.material-icons-inbox:before {
	content: "\e156";
}

.material-icons-indeterminate_check_box:before {
	content: "\e909";
}

.material-icons-info:before {
	content: "\e88e";
}

.material-icons-info_outline:before {
	content: "\e88f";
}

.material-icons-input:before {
	content: "\e890";
}

.material-icons-insert_chart:before {
	content: "\e24b";
}

.material-icons-insert_comment:before {
	content: "\e24c";
}

.material-icons-insert_drive_file:before {
	content: "\e24d";
}

.material-icons-insert_emoticon:before {
	content: "\e24e";
}

.material-icons-insert_invitation:before {
	content: "\e24f";
}

.material-icons-insert_link:before {
	content: "\e250";
}

.material-icons-insert_photo:before {
	content: "\e251";
}

.material-icons-invert_colors:before {
	content: "\e891";
}

.material-icons-invert_colors_off:before {
	content: "\e0c4";
}

.material-icons-iso:before {
	content: "\e3f6";
}

.material-icons-keyboard:before {
	content: "\e312";
}

.material-icons-keyboard_arrow_down:before {
	content: "\e313";
}

.material-icons-keyboard_arrow_left:before {
	content: "\e314";
}

.material-icons-keyboard_arrow_right:before {
	content: "\e315";
}

.material-icons-keyboard_arrow_up:before {
	content: "\e316";
}

.material-icons-keyboard_backspace:before {
	content: "\e317";
}

.material-icons-keyboard_capslock:before {
	content: "\e318";
}

.material-icons-keyboard_hide:before {
	content: "\e31a";
}

.material-icons-keyboard_return:before {
	content: "\e31b";
}

.material-icons-keyboard_tab:before {
	content: "\e31c";
}

.material-icons-keyboard_voice:before {
	content: "\e31d";
}

.material-icons-label:before {
	content: "\e892";
}

.material-icons-label_outline:before {
	content: "\e893";
}

.material-icons-landscape:before {
	content: "\e3f7";
}

.material-icons-language:before {
	content: "\e894";
}

.material-icons-laptop:before {
	content: "\e31e";
}

.material-icons-laptop_chromebook:before {
	content: "\e31f";
}

.material-icons-laptop_mac:before {
	content: "\e320";
}

.material-icons-laptop_windows:before {
	content: "\e321";
}

.material-icons-launch:before {
	content: "\e895";
}

.material-icons-layers:before {
	content: "\e53b";
}

.material-icons-layers_clear:before {
	content: "\e53c";
}

.material-icons-leak_add:before {
	content: "\e3f8";
}

.material-icons-leak_remove:before {
	content: "\e3f9";
}

.material-icons-lens:before {
	content: "\e3fa";
}

.material-icons-library_add:before {
	content: "\e02e";
}

.material-icons-library_books:before {
	content: "\e02f";
}

.material-icons-library_music:before {
	content: "\e030";
}

.material-icons-link:before {
	content: "\e157";
}

.material-icons-list:before {
	content: "\e896";
}

.material-icons-live_help:before {
	content: "\e0c6";
}

.material-icons-live_tv:before {
	content: "\e639";
}

.material-icons-local_activity:before {
	content: "\e53f";
}

.material-icons-local_airport:before {
	content: "\e53d";
}

.material-icons-local_atm:before {
	content: "\e53e";
}

.material-icons-local_bar:before {
	content: "\e540";
}

.material-icons-local_cafe:before {
	content: "\e541";
}

.material-icons-local_car_wash:before {
	content: "\e542";
}

.material-icons-local_convenience_store:before {
	content: "\e543";
}

.material-icons-local_dining:before {
	content: "\e556";
}

.material-icons-local_drink:before {
	content: "\e544";
}

.material-icons-local_florist:before {
	content: "\e545";
}

.material-icons-local_gas_station:before {
	content: "\e546";
}

.material-icons-local_grocery_store:before {
	content: "\e547";
}

.material-icons-local_hospital:before {
	content: "\e548";
}

.material-icons-local_hotel:before {
	content: "\e549";
}

.material-icons-local_laundry_service:before {
	content: "\e54a";
}

.material-icons-local_library:before {
	content: "\e54b";
}

.material-icons-local_mall:before {
	content: "\e54c";
}

.material-icons-local_movies:before {
	content: "\e54d";
}

.material-icons-local_offer:before {
	content: "\e54e";
}

.material-icons-local_parking:before {
	content: "\e54f";
}

.material-icons-local_pharmacy:before {
	content: "\e550";
}

.material-icons-local_phone:before {
	content: "\e551";
}

.material-icons-local_pizza:before {
	content: "\e552";
}

.material-icons-local_play:before {
	content: "\e553";
}

.material-icons-local_post_office:before {
	content: "\e554";
}

.material-icons-local_printshop:before {
	content: "\e555";
}

.material-icons-local_see:before {
	content: "\e557";
}

.material-icons-local_shipping:before {
	content: "\e558";
}

.material-icons-local_taxi:before {
	content: "\e559";
}

.material-icons-location_city:before {
	content: "\e7f1";
}

.material-icons-location_disabled:before {
	content: "\e1b6";
}

.material-icons-location_off:before {
	content: "\e0c7";
}

.material-icons-location_on:before {
	content: "\e0c8";
}

.material-icons-location_searching:before {
	content: "\e1b7";
}

.material-icons-lock:before {
	content: "\e897";
}

.material-icons-lock_open:before {
	content: "\e898";
}

.material-icons-lock_outline:before {
	content: "\e899";
}

.material-icons-looks:before {
	content: "\e3fc";
}

.material-icons-looks_3:before {
	content: "\e3fb";
}

.material-icons-looks_4:before {
	content: "\e3fd";
}

.material-icons-looks_5:before {
	content: "\e3fe";
}

.material-icons-looks_6:before {
	content: "\e3ff";
}

.material-icons-looks_one:before {
	content: "\e400";
}

.material-icons-looks_two:before {
	content: "\e401";
}

.material-icons-loop:before {
	content: "\e028";
}

.material-icons-loupe:before {
	content: "\e402";
}

.material-icons-loyalty:before {
	content: "\e89a";
}

.material-icons-mail:before {
	content: "\e158";
}

.material-icons-map:before {
	content: "\e55b";
}

.material-icons-markunread:before {
	content: "\e159";
}

.material-icons-markunread_mailbox:before {
	content: "\e89b";
}

.material-icons-memory:before {
	content: "\e322";
}

.material-icons-menu:before {
	content: "\e5d2";
}

.material-icons-merge_type:before {
	content: "\e252";
}

.material-icons-message:before {
	content: "\e0c9";
}

.material-icons-mic:before {
	content: "\e029";
}

.material-icons-mic_none:before {
	content: "\e02a";
}

.material-icons-mic_off:before {
	content: "\e02b";
}

.material-icons-mms:before {
	content: "\e618";
}

.material-icons-mode_comment:before {
	content: "\e253";
}

.material-icons-mode_edit:before {
	content: "\e254";
}

.material-icons-money_off:before {
	content: "\e25c";
}

.material-icons-monochrome_photos:before {
	content: "\e403";
}

.material-icons-mood:before {
	content: "\e7f2";
}

.material-icons-mood_bad:before {
	content: "\e7f3";
}

.material-icons-more:before {
	content: "\e619";
}

.material-icons-more_horiz:before {
	content: "\e5d3";
}

.material-icons-more_vert:before {
	content: "\e5d4";
}

.material-icons-mouse:before {
	content: "\e323";
}

.material-icons-movie:before {
	content: "\e02c";
}

.material-icons-movie_creation:before {
	content: "\e404";
}

.material-icons-music_note:before {
	content: "\e405";
}

.material-icons-my_location:before {
	content: "\e55c";
}

.material-icons-nature:before {
	content: "\e406";
}

.material-icons-nature_people:before {
	content: "\e407";
}

.material-icons-navigate_before:before {
	content: "\e408";
}

.material-icons-navigate_next:before {
	content: "\e409";
}

.material-icons-navigation:before {
	content: "\e55d";
}

.material-icons-network_cell:before {
	content: "\e1b9";
}

.material-icons-network_locked:before {
	content: "\e61a";
}

.material-icons-network_wifi:before {
	content: "\e1ba";
}

.material-icons-new_releases:before {
	content: "\e031";
}

.material-icons-nfc:before {
	content: "\e1bb";
}

.material-icons-no_sim:before {
	content: "\e0cc";
}

.material-icons-not_interested:before {
	content: "\e033";
}

.material-icons-note_add:before {
	content: "\e89c";
}

.material-icons-notifications:before {
	content: "\e7f4";
}

.material-icons-notifications_active:before {
	content: "\e7f7";
}

.material-icons-notifications_none:before {
	content: "\e7f5";
}

.material-icons-notifications_off:before {
	content: "\e7f6";
}

.material-icons-notifications_paused:before {
	content: "\e7f8";
}

.material-icons-offline_pin:before {
	content: "\e90a";
}

.material-icons-ondemand_video:before {
	content: "\e63a";
}

.material-icons-open_in_browser:before {
	content: "\e89d";
}

.material-icons-open_in_new:before {
	content: "\e89e";
}

.material-icons-open_with:before {
	content: "\e89f";
}

.material-icons-pages:before {
	content: "\e7f9";
}

.material-icons-pageview:before {
	content: "\e8a0";
}

.material-icons-palette:before {
	content: "\e40a";
}

.material-icons-panorama:before {
	content: "\e40b";
}

.material-icons-panorama_fish_eye:before {
	content: "\e40c";
}

.material-icons-panorama_horizontal:before {
	content: "\e40d";
}

.material-icons-panorama_vertical:before {
	content: "\e40e";
}

.material-icons-panorama_wide_angle:before {
	content: "\e40f";
}

.material-icons-party_mode:before {
	content: "\e7fa";
}

.material-icons-pause:before {
	content: "\e034";
}

.material-icons-pause_circle_filled:before {
	content: "\e035";
}

.material-icons-pause_circle_outline:before {
	content: "\e036";
}

.material-icons-payment:before {
	content: "\e8a1";
}

.material-icons-people:before {
	content: "\e7fb";
}

.material-icons-people_outline:before {
	content: "\e7fc";
}

.material-icons-perm_camera_mic:before {
	content: "\e8a2";
}

.material-icons-perm_contact_calendar:before {
	content: "\e8a3";
}

.material-icons-perm_data_setting:before {
	content: "\e8a4";
}

.material-icons-perm_device_information:before {
	content: "\e8a5";
}

.material-icons-perm_identity:before {
	content: "\e8a6";
}

.material-icons-perm_media:before {
	content: "\e8a7";
}

.material-icons-perm_phone_msg:before {
	content: "\e8a8";
}

.material-icons-perm_scan_wifi:before {
	content: "\e8a9";
}

.material-icons-person:before {
	content: "\e7fd";
}

.material-icons-person_add:before {
	content: "\e7fe";
}

.material-icons-person_outline:before {
	content: "\e7ff";
}

.material-icons-person_pin:before {
	content: "\e55a";
}

.material-icons-personal_video:before {
	content: "\e63b";
}

.material-icons-phone:before {
	content: "\e0cd";
}

.material-icons-phone_android:before {
	content: "\e324";
}

.material-icons-phone_bluetooth_speaker:before {
	content: "\e61b";
}

.material-icons-phone_forwarded:before {
	content: "\e61c";
}

.material-icons-phone_in_talk:before {
	content: "\e61d";
}

.material-icons-phone_iphone:before {
	content: "\e325";
}

.material-icons-phone_locked:before {
	content: "\e61e";
}

.material-icons-phone_missed:before {
	content: "\e61f";
}

.material-icons-phone_paused:before {
	content: "\e620";
}

.material-icons-phonelink:before {
	content: "\e326";
}

.material-icons-phonelink_erase:before {
	content: "\e0db";
}

.material-icons-phonelink_lock:before {
	content: "\e0dc";
}

.material-icons-phonelink_off:before {
	content: "\e327";
}

.material-icons-phonelink_ring:before {
	content: "\e0dd";
}

.material-icons-phonelink_setup:before {
	content: "\e0de";
}

.material-icons-photo:before {
	content: "\e410";
}

.material-icons-photo_album:before {
	content: "\e411";
}

.material-icons-photo_camera:before {
	content: "\e412";
}

.material-icons-photo_library:before {
	content: "\e413";
}

.material-icons-photo_size_select_actual:before {
	content: "\e432";
}

.material-icons-photo_size_select_large:before {
	content: "\e433";
}

.material-icons-photo_size_select_small:before {
	content: "\e434";
}

.material-icons-picture_as_pdf:before {
	content: "\e415";
}

.material-icons-picture_in_picture:before {
	content: "\e8aa";
}

.material-icons-pin_drop:before {
	content: "\e55e";
}

.material-icons-place:before {
	content: "\e55f";
}

.material-icons-play_arrow:before {
	content: "\e037";
}

.material-icons-play_circle_filled:before {
	content: "\e038";
}

.material-icons-play_circle_outline:before {
	content: "\e039";
}

.material-icons-play_for_work:before {
	content: "\e906";
}

.material-icons-playlist_add:before {
	content: "\e03b";
}

.material-icons-plus_one:before {
	content: "\e800";
}

.material-icons-poll:before {
	content: "\e801";
}

.material-icons-polymer:before {
	content: "\e8ab";
}

.material-icons-portable_wifi_off:before {
	content: "\e0ce";
}

.material-icons-portrait:before {
	content: "\e416";
}

.material-icons-power:before {
	content: "\e63c";
}

.material-icons-power_input:before {
	content: "\e336";
}

.material-icons-power_settings_new:before {
	content: "\e8ac";
}

.material-icons-present_to_all:before {
	content: "\e0df";
}

.material-icons-print:before {
	content: "\e8ad";
}

.material-icons-public:before {
	content: "\e80b";
}

.material-icons-publish:before {
	content: "\e255";
}

.material-icons-query_builder:before {
	content: "\e8ae";
}

.material-icons-question_answer:before {
	content: "\e8af";
}

.material-icons-queue:before {
	content: "\e03c";
}

.material-icons-queue_music:before {
	content: "\e03d";
}

.material-icons-radio:before {
	content: "\e03e";
}

.material-icons-radio_button_checked:before {
	content: "\e837";
}

.material-icons-radio_button_unchecked:before {
	content: "\e836";
}

.material-icons-rate_review:before {
	content: "\e560";
}

.material-icons-receipt:before {
	content: "\e8b0";
}

.material-icons-recent_actors:before {
	content: "\e03f";
}

.material-icons-redeem:before {
	content: "\e8b1";
}

.material-icons-redo:before {
	content: "\e15a";
}

.material-icons-refresh:before {
	content: "\e5d5";
}

.material-icons-remove:before {
	content: "\e15b";
}

.material-icons-remove_circle:before {
	content: "\e15c";
}

.material-icons-remove_circle_outline:before {
	content: "\e15d";
}

.material-icons-remove_red_eye:before {
	content: "\e417";
}

.material-icons-reorder:before {
	content: "\e8fe";
}

.material-icons-repeat:before {
	content: "\e040";
}

.material-icons-repeat_one:before {
	content: "\e041";
}

.material-icons-replay:before {
	content: "\e042";
}

.material-icons-replay_10:before {
	content: "\e059";
}

.material-icons-replay_30:before {
	content: "\e05a";
}

.material-icons-replay_5:before {
	content: "\e05b";
}

.material-icons-reply:before {
	content: "\e15e";
}

.material-icons-reply_all:before {
	content: "\e15f";
}

.material-icons-report:before {
	content: "\e160";
}

.material-icons-report_problem:before {
	content: "\e8b2";
}

.material-icons-restaurant_menu:before {
	content: "\e561";
}

.material-icons-restore:before {
	content: "\e8b3";
}

.material-icons-ring_volume:before {
	content: "\e0d1";
}

.material-icons-room:before {
	content: "\e8b4";
}

.material-icons-rotate_90_degrees_ccw:before {
	content: "\e418";
}

.material-icons-rotate_left:before {
	content: "\e419";
}

.material-icons-rotate_right:before {
	content: "\e41a";
}

.material-icons-router:before {
	content: "\e328";
}

.material-icons-satellite:before {
	content: "\e562";
}

.material-icons-save:before {
	content: "\e161";
}

.material-icons-scanner:before {
	content: "\e329";
}

.material-icons-schedule:before {
	content: "\e8b5";
}

.material-icons-school:before {
	content: "\e80c";
}

.material-icons-screen_lock_landscape:before {
	content: "\e1be";
}

.material-icons-screen_lock_portrait:before {
	content: "\e1bf";
}

.material-icons-screen_lock_rotation:before {
	content: "\e1c0";
}

.material-icons-screen_rotation:before {
	content: "\e1c1";
}

.material-icons-sd_card:before {
	content: "\e623";
}

.material-icons-sd_storage:before {
	content: "\e1c2";
}

.material-icons-search:before {
	content: "\e8b6";
}

.material-icons-security:before {
	content: "\e32a";
}

.material-icons-select_all:before {
	content: "\e162";
}

.material-icons-send:before {
	content: "\e163";
}

.material-icons-settings:before {
	content: "\e8b8";
}

.material-icons-settings_applications:before {
	content: "\e8b9";
}

.material-icons-settings_backup_restore:before {
	content: "\e8ba";
}

.material-icons-settings_bluetooth:before {
	content: "\e8bb";
}

.material-icons-settings_brightness:before {
	content: "\e8bd";
}

.material-icons-settings_cell:before {
	content: "\e8bc";
}

.material-icons-settings_ethernet:before {
	content: "\e8be";
}

.material-icons-settings_input_antenna:before {
	content: "\e8bf";
}

.material-icons-settings_input_component:before {
	content: "\e8c0";
}

.material-icons-settings_input_composite:before {
	content: "\e8c1";
}

.material-icons-settings_input_hdmi:before {
	content: "\e8c2";
}

.material-icons-settings_input_svideo:before {
	content: "\e8c3";
}

.material-icons-settings_overscan:before {
	content: "\e8c4";
}

.material-icons-settings_phone:before {
	content: "\e8c5";
}

.material-icons-settings_power:before {
	content: "\e8c6";
}

.material-icons-settings_remote:before {
	content: "\e8c7";
}

.material-icons-settings_system_daydream:before {
	content: "\e1c3";
}

.material-icons-settings_voice:before {
	content: "\e8c8";
}

.material-icons-share:before {
	content: "\e80d";
}

.material-icons-shop:before {
	content: "\e8c9";
}

.material-icons-shop_two:before {
	content: "\e8ca";
}

.material-icons-shopping_basket:before {
	content: "\e8cb";
}

.material-icons-shopping_cart:before {
	content: "\e8cc";
}

.material-icons-shuffle:before {
	content: "\e043";
}

.material-icons-signal_cellular_4_bar:before {
	content: "\e1c8";
}

.material-icons-signal_cellular_connected_no_internet_4_bar:before {
	content: "\e1cd";
}

.material-icons-signal_cellular_no_sim:before {
	content: "\e1ce";
}

.material-icons-signal_cellular_null:before {
	content: "\e1cf";
}

.material-icons-signal_cellular_off:before {
	content: "\e1d0";
}

.material-icons-signal_wifi_4_bar:before {
	content: "\e1d8";
}

.material-icons-signal_wifi_4_bar_lock:before {
	content: "\e1d9";
}

.material-icons-signal_wifi_off:before {
	content: "\e1da";
}

.material-icons-sim_card:before {
	content: "\e32b";
}

.material-icons-sim_card_alert:before {
	content: "\e624";
}

.material-icons-skip_next:before {
	content: "\e044";
}

.material-icons-skip_previous:before {
	content: "\e045";
}

.material-icons-slideshow:before {
	content: "\e41b";
}

.material-icons-smartphone:before {
	content: "\e32c";
}

.material-icons-sms:before {
	content: "\e625";
}

.material-icons-sms_failed:before {
	content: "\e626";
}

.material-icons-snooze:before {
	content: "\e046";
}

.material-icons-sort:before {
	content: "\e164";
}

.material-icons-sort_by_alpha:before {
	content: "\e053";
}

.material-icons-space_bar:before {
	content: "\e256";
}

.material-icons-speaker:before {
	content: "\e32d";
}

.material-icons-speaker_group:before {
	content: "\e32e";
}

.material-icons-speaker_notes:before {
	content: "\e8cd";
}

.material-icons-speaker_phone:before {
	content: "\e0d2";
}

.material-icons-spellcheck:before {
	content: "\e8ce";
}

.material-icons-star:before {
	content: "\e838";
}

.material-icons-star_border:before {
	content: "\e83a";
}

.material-icons-star_half:before {
	content: "\e839";
}

.material-icons-stars:before {
	content: "\e8d0";
}

.material-icons-stay_current_landscape:before {
	content: "\e0d3";
}

.material-icons-stay_current_portrait:before {
	content: "\e0d4";
}

.material-icons-stay_primary_landscape:before {
	content: "\e0d5";
}

.material-icons-stay_primary_portrait:before {
	content: "\e0d6";
}

.material-icons-stop:before {
	content: "\e047";
}

.material-icons-storage:before {
	content: "\e1db";
}

.material-icons-store:before {
	content: "\e8d1";
}

.material-icons-store_mall_directory:before {
	content: "\e563";
}

.material-icons-straighten:before {
	content: "\e41c";
}

.material-icons-strikethrough_s:before {
	content: "\e257";
}

.material-icons-style:before {
	content: "\e41d";
}

.material-icons-subject:before {
	content: "\e8d2";
}

.material-icons-subtitles:before {
	content: "\e048";
}

.material-icons-supervisor_account:before {
	content: "\e8d3";
}

.material-icons-surround_sound:before {
	content: "\e049";
}

.material-icons-swap_calls:before {
	content: "\e0d7";
}

.material-icons-swap_horiz:before {
	content: "\e8d4";
}

.material-icons-swap_vert:before {
	content: "\e8d5";
}

.material-icons-swap_vertical_circle:before {
	content: "\e8d6";
}

.material-icons-switch_camera:before {
	content: "\e41e";
}

.material-icons-switch_video:before {
	content: "\e41f";
}

.material-icons-sync:before {
	content: "\e627";
}

.material-icons-sync_disabled:before {
	content: "\e628";
}

.material-icons-sync_problem:before {
	content: "\e629";
}

.material-icons-system_update:before {
	content: "\e62a";
}

.material-icons-system_update_alt:before {
	content: "\e8d7";
}

.material-icons-tab:before {
	content: "\e8d8";
}

.material-icons-tab_unselected:before {
	content: "\e8d9";
}

.material-icons-tablet:before {
	content: "\e32f";
}

.material-icons-tablet_android:before {
	content: "\e330";
}

.material-icons-tablet_mac:before {
	content: "\e331";
}

.material-icons-tag_faces:before {
	content: "\e420";
}

.material-icons-tap_and_play:before {
	content: "\e62b";
}

.material-icons-terrain:before {
	content: "\e564";
}

.material-icons-text_format:before {
	content: "\e165";
}

.material-icons-textsms:before {
	content: "\e0d8";
}

.material-icons-texture:before {
	content: "\e421";
}

.material-icons-theaters:before {
	content: "\e8da";
}

.material-icons-thumb_down:before {
	content: "\e8db";
}

.material-icons-thumb_up:before {
	content: "\e8dc";
}

.material-icons-thumbs_up_down:before {
	content: "\e8dd";
}

.material-icons-time_to_leave:before {
	content: "\e62c";
}

.material-icons-timelapse:before {
	content: "\e422";
}

.material-icons-timer:before {
	content: "\e425";
}

.material-icons-timer_10:before {
	content: "\e423";
}

.material-icons-timer_3:before {
	content: "\e424";
}

.material-icons-timer_off:before {
	content: "\e426";
}

.material-icons-toc:before {
	content: "\e8de";
}

.material-icons-today:before {
	content: "\e8df";
}

.material-icons-toll:before {
	content: "\e8e0";
}

.material-icons-tonality:before {
	content: "\e427";
}

.material-icons-toys:before {
	content: "\e332";
}

.material-icons-track_changes:before {
	content: "\e8e1";
}

.material-icons-traffic:before {
	content: "\e565";
}

.material-icons-transform:before {
	content: "\e428";
}

.material-icons-translate:before {
	content: "\e8e2";
}

.material-icons-trending_down:before {
	content: "\e8e3";
}

.material-icons-trending_flat:before {
	content: "\e8e4";
}

.material-icons-trending_up:before {
	content: "\e8e5";
}

.material-icons-tune:before {
	content: "\e429";
}

.material-icons-turned_in:before {
	content: "\e8e6";
}

.material-icons-turned_in_not:before {
	content: "\e8e7";
}

.material-icons-tv:before {
	content: "\e333";
}

.material-icons-undo:before {
	content: "\e166";
}

.material-icons-unfold_less:before {
	content: "\e5d6";
}

.material-icons-unfold_more:before {
	content: "\e5d7";
}

.material-icons-usb:before {
	content: "\e1e0";
}

.material-icons-verified_user:before {
	content: "\e8e8";
}

.material-icons-vertical_align_bottom:before {
	content: "\e258";
}

.material-icons-vertical_align_center:before {
	content: "\e259";
}

.material-icons-vertical_align_top:before {
	content: "\e25a";
}

.material-icons-vibration:before {
	content: "\e62d";
}

.material-icons-video_library:before {
	content: "\e04a";
}

.material-icons-videocam:before {
	content: "\e04b";
}

.material-icons-videocam_off:before {
	content: "\e04c";
}

.material-icons-view_agenda:before {
	content: "\e8e9";
}

.material-icons-view_array:before {
	content: "\e8ea";
}

.material-icons-view_carousel:before {
	content: "\e8eb";
}

.material-icons-view_column:before {
	content: "\e8ec";
}

.material-icons-view_comfy:before {
	content: "\e42a";
}

.material-icons-view_compact:before {
	content: "\e42b";
}

.material-icons-view_day:before {
	content: "\e8ed";
}

.material-icons-view_headline:before {
	content: "\e8ee";
}

.material-icons-view_list:before {
	content: "\e8ef";
}

.material-icons-view_module:before {
	content: "\e8f0";
}

.material-icons-view_quilt:before {
	content: "\e8f1";
}

.material-icons-view_stream:before {
	content: "\e8f2";
}

.material-icons-view_week:before {
	content: "\e8f3";
}

.material-icons-vignette:before {
	content: "\e435";
}

.material-icons-visibility:before {
	content: "\e8f4";
}

.material-icons-visibility_off:before {
	content: "\e8f5";
}

.material-icons-voice_chat:before {
	content: "\e62e";
}

.material-icons-voicemail:before {
	content: "\e0d9";
}

.material-icons-volume_down:before {
	content: "\e04d";
}

.material-icons-volume_mute:before {
	content: "\e04e";
}

.material-icons-volume_off:before {
	content: "\e04f";
}

.material-icons-volume_up:before {
	content: "\e050";
}

.material-icons-vpn_key:before {
	content: "\e0da";
}

.material-icons-vpn_lock:before {
	content: "\e62f";
}

.material-icons-wallpaper:before {
	content: "\e1bc";
}

.material-icons-warning:before {
	content: "\e002";
}

.material-icons-watch:before {
	content: "\e334";
}

.material-icons-wb_auto:before {
	content: "\e42c";
}

.material-icons-wb_cloudy:before {
	content: "\e42d";
}

.material-icons-wb_incandescent:before {
	content: "\e42e";
}

.material-icons-wb_iridescent:before {
	content: "\e436";
}

.material-icons-wb_sunny:before {
	content: "\e430";
}

.material-icons-wc:before {
	content: "\e63d";
}

.material-icons-web:before {
	content: "\e051";
}

.material-icons-whatshot:before {
	content: "\e80e";
}

.material-icons-widgets:before {
	content: "\e1bd";
}

.material-icons-wifi:before {
	content: "\e63e";
}

.material-icons-wifi_lock:before {
	content: "\e1e1";
}

.material-icons-wifi_tethering:before {
	content: "\e1e2";
}

.material-icons-work:before {
	content: "\e8f9";
}

.material-icons-wrap_text:before {
	content: "\e25b";
}

.material-icons-youtube_searched_for:before {
	content: "\e8fa";
}

.material-icons-zoom_in:before {
	content: "\e8ff";
}

.material-icons-zoom_out:before {
	content: "\e900";
}
